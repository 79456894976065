import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { FiCalendar } from "react-icons/fi";
import { dashboardsService } from "services";

const AbsenceHeader = ({ employee_id, year }) => {
	const [counters, setCounters] = useState("");

	useEffect(() => {
		async function getCounters() {
			const currentYear = (new Date()).getFullYear();
			const response = await dashboardsService.getEmployeeVacationSummary(employee_id, year || currentYear);

			setCounters(response);
		}
		getCounters();
	}, [employee_id, year]);

	const { vacation_entitlement, vacation_taken_already_done, vacation_taken_not_done, vacation_pending } = counters;

	// Vacation entitlement - Vacaciones generadas
	const vacaciones_totales =
		counters.ok && vacation_entitlement
			? vacation_entitlement.toLocaleString(undefined, {
				maximumFractionDigits: 2,
			})
			: 0;

	// Vacation taken & done - Vacactiones disfrutadas
	const vacaciones_realizadas =
		counters.ok && vacation_taken_already_done
			? vacation_taken_already_done.toLocaleString(undefined, {
				maximumFractionDigits: 2,
			})
			: 0;

	// Vacation taken & NOT done - Vacaciones planificadas
	const vacaciones_planificadas =
		counters.ok && vacation_taken_not_done
			? vacation_taken_not_done.toLocaleString(undefined, {
				maximumFractionDigits: 2,
			})
			: 0;

	// Vacation pending - Vacaciones disponibles
	const vacaciones_pendientes =
		counters.ok && vacation_pending
			? vacation_pending.toLocaleString(undefined, {
				maximumFractionDigits: 2,
			})
			: 0;

	return (
		<Row className="header-widgets">
			<Col sm={12} md={6} lg={3} className="w">
				<article className="widget-content">
					<span className="widget-title title-yellow">
						Vacaciones generadas
					</span>
					<div className="widget-main">
						<span className="widget-counter">{vacaciones_totales}</span>
						<span className="icon-calendar-wrapper">
							<FiCalendar className="icon-calendar" />
						</span>
					</div>
				</article>
			</Col>
			<Col sm={12} md={6} lg={3}>
				<article className="widget-content">
					<span className="widget-title title-blue">
						Vacaciones disfrutadas
					</span>
					<div className="widget-main">
						<span className="widget-counter">{vacaciones_realizadas}</span>
						<span className="icon-calendar-wrapper">
							<FiCalendar className="icon-calendar" />
						</span>
					</div>
				</article>
			</Col>
			<Col sm={12} md={6} lg={3}>
				<article className="widget-content">
					<span className="widget-title title-green">
						Vacaciones planificadas
					</span>
					<div className="widget-main">
						<span className="widget-counter">{vacaciones_planificadas}</span>
						<span className="icon-calendar-wrapper">
							<FiCalendar className="icon-calendar" />
						</span>
					</div>
				</article>
			</Col>
			<Col sm={12} md={6} lg={3}>
				<article className="widget-content">
					<span className="widget-title title-red">
						Vacaciones disponibles
					</span>
					<div className="widget-main">
						<span className="widget-counter">{vacaciones_pendientes}</span>
						<span className="icon-calendar-wrapper">
							<FiCalendar className="icon-calendar" />
						</span>
					</div>
				</article>
			</Col>
		</Row>
	);
};

export default AbsenceHeader;
