import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiSearch } from "react-icons/fi";
import "./CustomSelector.scss";

class CustomSelector extends Component {
	constructor(props){
		super(props);
		// console.log(props);

		const defaultValue = this.props.defaultValue && Object.keys(this.props.defaultValue).length > 0;

		this.state = {
			openOptions: false,
			selected: defaultValue ? this.props.defaultValue : undefined,
			filteredValues: []
		}

		this.toggleOptions = this.toggleOptions.bind(this);
		this.closeOptions = this.closeOptions.bind(this);
		this.onSelect = this.onSelect.bind(this);
		this.filterSearch = this.filterSearch.bind(this);
		this.setList = this.setList.bind(this);
	}

	toggleOptions() {
		!this.state.disabled && !this.state.openOptions && this.setState({
			openOptions: true
		});

		!this.state.disabled && this.state.openOptions && this.setState({
			openOptions: false,
			filteredValues: [],
			filter:''
		});
	}
	closeOptions(event) {
		if (event.target !== this.refs.selectedItem && event.target !== this.refs.itemOptions && event.target !== this.refs.filterText ) {
			this.setState({
				openOptions: false,
				filteredValues: [],
				filter:''
			});
		}
	}

	onSelect(item) {
		this.setState({
			selected: item,
			filter : ''
		})
		this.props.onSelect && this.props.onSelect(item);
	}

	filterSearch(evt) {
		let filterValue = evt.target.value.toLowerCase();
		let filteredValues = filterValue && this.state.list.filter(item => {
			const name = item.name;
			const description = item.description;
			return  (name && name.toLowerCase().includes(filterValue)) || (description && description.toLowerCase().includes(filterValue))
		}) ;

		this.setState({filter : filterValue, filteredValues : filteredValues });
	}

	setList() {
		const { selected } = this.state;

		this.setState({
			list: this.props.list
		}, ()=> {
			if (selected && !this.state.list.some(item => item.id === selected.id)) {
				this.setState({ selected: null });
			}
		});
	}

	componentDidMount() {
		this.setList();

		!this.props.disabled && window.addEventListener("click", this.closeOptions);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.list !== this.props.list) {
			this.setList();
		}

		if (prevProps.defaultValue !== this.props.defaultValue) {
			this.updateSelected(this.props.defaultValue);
		}
	}

	componentWillUnmount() {
		!this.props.disabled && window.removeEventListener("click", this.closeOptions);
	}

	updateSelected(item) {
		this.setState({
			selected: item
		})
	}	

	removeSelected() {
		this.setState({
			selected: null
		})
	}

	render() {
		const { selected } = this.state;
		let isSelected = selected !== undefined && selected !== null && selected.name !== undefined;
		// console.log(selected);
		// console.log(isSelected);

		return (
			<div className={`custom-selector select-control ${this.props.showSelectedLabel ? "form-control" :  "no-label"} ${this.props.openOptions ? "active" :  ""} ${this.props.className ? this.props.className :  ""} ${this.props.isInvalid ? 'is-invalid' : ''}`}>
				<div ref="selectedItem" className={`selected-option ${this.props.disabled ? 'disabled' : ''}`} tabIndex="0" onClick={this.toggleOptions}>
					{isSelected &&
						<>
							<span className="option-tag" style={{ backgroundColor: selected.color && selected.color}}>{selected.name}</span>

							{this.props.showSelectedLabel &&
								<span className="option-label">{selected.description}</span>
							}
						</>
					}

					{!isSelected &&
						<span className="placeholder-option-label">{this.props.placeholder}</span>
					}
				</div>

				{this.state.openOptions &&
					<div ref="itemOptions" className={`list-options`}>
						{this.props.searchable &&
							<div className="filterBox form-search">
								<input 
									type="text" 
									className="form-control" 
									placeholder={this.props.searchPlaceholder} 
									ref="filterText"  
									onChange={this.filterSearch}
									value={this.filter}
									/>
								<FiSearch />
							</div>
						}
						{(this.state.filter ? this.state.filteredValues : this.state.list).map( (item, i) =>

							<div className={`list-option ${this.props.showOptionLabel ? 'has-label' : ''}`} key={item.id} tabIndex={i} onClick={() => this.onSelect(item)}>
								<span className="option-tag" style={{ backgroundColor: item.color && item.color}}>{item.name}</span>

								{this.props.showOptionLabel &&
									<span className="option-label">{ item.description }</span>
								}
							</div>
						)}

						{
							(this.state.filter && this.state.filteredValues.length < 1) &&
							<div className={`list-option-empty`}>
								Sin resultados
							</div>
						}
					</div>
				}
			</div>
		)
	}
}

CustomSelector.defaultProps = {
	placeholder: "Select",
	showSelectedLabel: true,
	showOptionLabel: true,
	disabled: false,
	searchable: false,
	searchPlaceholder: 'Filtrar...',
}

CustomSelector.propTypes = {
	list: PropTypes.array,
	defaultValue: PropTypes.object,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	showSelectedLabel: PropTypes.bool,
	showOptionLabel: PropTypes.bool,
	onSelect: PropTypes.func,
	disabled: PropTypes.bool,
	searchable: PropTypes.bool,
	searchPlaceholder: PropTypes.string,
}

export default CustomSelector;