import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Moment from 'react-moment';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { FiCalendar, FiTrash2 } from "react-icons/fi";
import { calendarsService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import CustomSelector from "components/CustomSelector";
import * as calendarsActions from 'actions/calendarsActions';
import "./Calendars.scss";

import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // datepicker locale
registerLocale("es", es);

class CalendarEdit extends React.Component {

	constructor(props) {
		super(props);
		// console.log(props);

		let calendar = {
			type: '',
			name: '',
			description: '',
			special_days: [],
			weekdays: this.props.weekdays_default,
		}

		let isEdit = false;

		if (props.calendar) {
			isEdit = true;
			calendar = props.calendar;

			// console.log(calendar.special_days);
			let special_days = [];

			if (calendar.special_days) {
				special_days = this.sortSpecialDays(calendar.special_days);
			}

			calendar.special_days = special_days;
		}

		this.state = {
			isEdit,
			isLoading: false,
			submitted: false,
			errors: {},
			calendar,
			new_day: {
				day_type: {},
				description: '',
				day: '',
			},
			new_day_errors: {},
		};
	}

	sortSpecialDays = (array) => {
		return array.sort((a, b) => {
			// Convertimos las fechas a objetos Date para compararlas
			let fechaA = new Date(a.day);
			let fechaB = new Date(b.day);

			// Orden descendente: la fecha más reciente primero
			return fechaB - fechaA;
		});
	}

	handleChange = event => {
		const { calendar } = this.state;

		this.setState({
			calendar: {
				...calendar,
				[event.target.id]: event.target.value
			},
		});
	}

	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	sanitizeDayList = (list) => {
		return list.map((day) => {
			day.day_type_id = day.day_type.id;
			return day;
		})
	}

	handleSubmit = async event => {
		event.preventDefault();

		this.setState({ isLoading: true });
		let response = '';
		const props = this.props;
		const { calendar } = this.state;

		const weekdays = this.sanitizeDayList(calendar.weekdays);
		const special_days = this.sanitizeDayList(calendar.special_days);

		calendar.weekdays = weekdays;
		calendar.special_days = special_days;


		// if edit => update; else => new
		if (this.state.isEdit) {
			response = await calendarsService.update(calendar);
		}
		else {
			response = await calendarsService.add(calendar);
		}

		//console.log(response);

		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
			}, () => {

				toastr.success('¡Bien!', 'Cambios guardados correctamente');
				this.props.getAllCalendars();
				props.handleClose();

			});
		}
		else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response)
			});
		}

	}

	handleDateChange = (id, date) => {
		const { new_day } = this.state;

		let key = id;
		let value = moment(date).format("YYYY-MM-DD");

		this.setState({
			new_day: {
				...new_day,
				[key]: value,
			},
		});
	};

	handleChangeDay = event => {
		const { new_day } = this.state;
		let target_id = "";
		let target_value = "";

		// if day_type
		if (event.target === undefined) {
			target_id = "day_type_id";
			target_value = event.id;
		}
		else {
			target_id = event.target.id;
			target_value = event.target.value;
		}

		this.setState({
			new_day: {
				...new_day,
				[target_id]: target_value
			},
		});
	}

	addSpecialDay = () => {
		const { calendar, new_day } = this.state;
		let isValid = true;
		const new_day_errors = {};
		const day_type = this.props.select_day_types.find(dt => dt.id === parseInt(new_day.day_type_id));

		if (day_type === undefined) {
			isValid = false;
			new_day_errors.day_type_id = false;
		}

		if (!new_day.day) {
			isValid = false;
			new_day_errors.day = false;
		}

		if (!new_day.description) {
			isValid = false;
			new_day_errors.description = false;
		}

		if (isValid) {
			new_day.day_type = day_type;

			// let special_days = calendar.special_days;
			let exists = false;

			// if day already exists: update:
			let special_days = calendar.special_days.map(item => {
				if (item.day === new_day.day) {
					item = new_day;
					exists = true;
				}
				return item;
			});

			// else: add new day and sort list
			if (!exists) {
				special_days = special_days.concat(new_day);
				special_days = special_days.sort(function (a, b) {
					a = new Date(a.day);
					b = new Date(b.day);

					return a < b ? -1 : a > b ? 1 : 0; // vell -> recent
				});
			}

			this.setState({
				calendar: {
					...calendar,
					special_days
				},
				new_day: {
					day_type: {},
					description: '',
					day: '',
				},
				new_day_errors: {},
			});
		}
		else {
			this.setState({
				new_day_errors,
			});
		}

	}

	deleteSpecialDay = (day) => {
		const { calendar } = this.state;
		const special_days = calendar.special_days.filter(d => d.day !== day.day);

		this.setState({
			calendar: {
				...calendar,
				special_days
			},
		});
	}


	handleSelectWeekDay = (day_type, i) => {
		const { calendar } = this.state;

		this.setState({
			calendar: {
				...calendar,
				weekdays: calendar.weekdays.map(item => (item.weekday === i + 1 ? { ...item, day_type: day_type } : item))
			},
		});
	}

	getWeekdaysHeader = () => this.props.weekdays_default.map((day, i) => {
		return (
			<th key={i}>
				{day.day_type.name}
			</th>
		)
	});

	getWeekdaysBody = () => this.state.calendar.weekdays.map((day, i) => {
		return (
			<td key={i}>
				<CustomSelector
					ref="newDayType"
					list={this.props.select_day_types}
					searchable={true}
					placeholder="Seleccionar tipo"
					onSelect={(item) => this.handleSelectWeekDay(item, i)}
					defaultValue={day.day_type}
					showSelectedLabel={false}
				/>
			</td>
		)
	});


	getSpecialDaysBody = () => this.state.calendar.special_days.map((day, i) => {
		return (
			<tr key={i}>
				<td><span className="tag-day-type" style={{ backgroundColor: day.day_type.color && day.day_type.color }}>{day.day_type.name}</span></td>
				<td><Moment format="DD/MM/YY">{day.day}</Moment></td>
				<td>{day.description}</td>
				<td className="td-actions"><button type="button" className="btn btn-icon" onClick={() => this.deleteSpecialDay(day)}><FiTrash2 /></button></td>
			</tr>
		)
	});


	render() {
		const { calendar, new_day, new_day_errors, isEdit } = this.state;
		const { select_day_types } = this.props;

		return (

			<form className="modal-calendar" >
				<div className="modal-body">

					<FormGroup controlId="name">
						<FormLabel>Nombre <span className="label-required">*</span></FormLabel>
						<FormControl
							type="text"
							value={calendar.name}
							onChange={this.handleChange}
							placeholder="Nombre del calendario"
							isInvalid={this.checkValidField('name')}
						/>

						<FormControl.Feedback type="invalid">
							{this.state.errors.name}
						</FormControl.Feedback>
					</FormGroup>

					<FormGroup controlId="description">
						<FormLabel>Descripción</FormLabel>
						<FormControl
							type="text"
							value={calendar.description}
							onChange={this.handleChange}
							placeholder="Descripción del calendario"
							isInvalid={this.checkValidField('description')}
						/>

						<FormControl.Feedback type="invalid">
							{this.state.errors.description}
						</FormControl.Feedback>
					</FormGroup>

					<h3 className="subtitle">Días de la semana</h3>

					<table className="table table-bordered table-condensed table-days table-weekdays">
						<thead>
							<tr>
								{this.getWeekdaysHeader()}
							</tr>
						</thead>
						<tbody>
							<tr>
								{this.getWeekdaysBody()}
							</tr>
						</tbody>
					</table>

					<h3 className="subtitle">Días especiales</h3>

					<div className="row-special-days flex-between">

						<FormGroup controlId="day_type_id">
							<FormLabel>Tipo de día</FormLabel>
							<CustomSelector
								list={select_day_types}
								searchable={true}
								placeholder="Seleccionar tipo"
								isInvalid={new_day_errors.day_type_id !== undefined}
								onSelect={this.handleChangeDay}
								defaultValue={new_day.day_type}
							/>
						</FormGroup>

						<FormGroup controlId="day" className={`form-date ${new_day.day === '' && 'empty'}`}>
							<FormLabel>Fecha</FormLabel>
							<DatePicker
								selected={new_day.day === "" ? new_day.day : moment(new_day.day).toDate()}
								onChange={(date) => this.handleDateChange("day", date)}
								isInvalid={new_day_errors.day !== undefined}
								className="form-control"
								dateFormat="dd/MM/yyyy"
								locale='es'
								placeholderText="dd/mm/aaaa"
							/>
							<FiCalendar />
						</FormGroup>

						<FormGroup controlId="description">
							<FormLabel>Descripción</FormLabel>
							<FormControl
								type="text"
								value={new_day.description}
								onChange={this.handleChangeDay}
								placeholder="Breve descripción"
								isInvalid={new_day_errors.description !== undefined}
							/>
						</FormGroup>

						<button type="button" className="btn btn-primary" onClick={this.addSpecialDay}>Añadir día</button>

					</div>

					{calendar.special_days.length > 0 &&
						(<>
							{isEdit && (
								<div className="alert alert-warning">
									<p className="alert-intro">
										<strong>Atención:</strong> al editar los días especiales es conveniente que replanifiques los días de los grupos afectados para evitar errores en la planificación</p>
								</div>
							)}

							<table className="table table-condensed table-zebra-reverse table-specialdays">
								<thead>
									<tr>
										<th>Tipo</th>
										<th>Día</th>
										<th>Descripción</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{this.getSpecialDaysBody()}
								</tbody>
							</table></>)
					}


				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="button"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
						onClick={this.handleSubmit}
					/>
				</div>
			</form>
		)
	}
}


const mapStateToProps = (reducers) => {
	return reducers.calendarsReducer;
};

export default connect(mapStateToProps, calendarsActions)(CalendarEdit)