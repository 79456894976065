import React from "react";
import { Button } from "react-bootstrap";
import "./styles/Loading.scss";

export default ({
  isLoading,
  text,
  loadingText,
  iconBefore,
  iconAfter,
  className = "",
  disabled = false,
  ...props
}) =>
  <Button
    className={`SubmitButton ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading && <div className="lds-grid">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>}
    {!isLoading && iconBefore && iconBefore}
    {!isLoading ? text : loadingText}
    {!isLoading && iconAfter && iconAfter}
  </Button>;
