import React from "react";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { FormGroup, FormControl, FormLabel, Row, Col } from "react-bootstrap";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

import { companyService } from "services";
import { errorFormating, getSubscription } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import * as companyActions from "actions/companyActions";
import { isTrial, getTrialDays } from "utils/utils";
import Switch from "components/Switch";
import "./Profile.scss";
import { authService } from "../../services/authService";

class SubscriptionRequest extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		let subscription_request = {
			subscription_type: props.selected_plan.name,
			how_did_you_know_us: "",
			observations: "",
			company: this.props.company,
		};

		this.state = {
			isLoading: false,
			submitted: false,
			isYearly: props.isYearly,
			maxUsers: props.employees_count,
			step: 0,
			errors: {},
			current_subscription: getSubscription(),
			subscription_request,
		};
	}

	handleChange = (event) => {
		const { subscription_request } = this.state;
		const company = subscription_request.company;

		let target = event.target.id;
		let value = event.target.value;

		if (target.includes("company")) {
			target = target.replace("company.", "");
			this.setState({
				subscription_request: {
					...subscription_request,
					company: {
						...company,
						[target]: value,
					},
				},
			});
		} else {
			// normal subscription_request change
			this.setState({
				subscription_request: {
					...subscription_request,
					[event.target.id]: value,
				},
			});
		}
	};

	handleEmployeeChange = (event) => {
		this.setState({
			maxUsers: event.target.value,
		});
	};

	handleSwitch = (event) => {
		this.setState({
			isYearly: event.target.checked,
		});
	};

	setStep(step) {
		this.setState({
			step,
		});
	}

	checkValidField = (name) => {
		return (
			this.state.submitted &&
			this.state.errors[name] !== undefined &&
			this.state.errors[name] !== ""
		);
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		const props = this.props;
		const { subscription_request, isYearly, maxUsers } = this.state;

		this.setState({ isLoading: true });

		if (subscription_request.subscription_type === "Pro") {
			subscription_request.payment_model = isYearly ? "Anual" : "Mensual";
			subscription_request.max_users = maxUsers;
		}

		const response = await companyService.addSubscriptionRequest(
			subscription_request
		);

		if (response.ok) {
			this.setState(
				{
					isLoading: false,
					errors: {},
					step: 2,
				},
				async () => {
					if (subscription_request.subscription_type === "Free") {
						await authService.setUser(props.user);
					}
				}
			);
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response),
			});
		}
	};

	getStep0Content() {
		const { selected_plan, employees_count } = this.props;
		const { isYearly, maxUsers } = this.state;

		if (selected_plan.name !== "Pro") {
			return (
				<div className="step-content step-content-free">
					<h3>Has seleccionado el Plan Trial</h3>
					<p>Con este plan puedes añadir hasta 5 empleados</p>
				</div>
			);
		}

		const employeesOptionList = Array(3000 - employees_count + 1)
			.fill()
			.map((_, i) => {
				return (
					<option key={employees_count + i} value={employees_count + i}>
						{employees_count + i}
					</option>
				);
			}, this);

		// var userAmount = isYearly ? 2.4 : 3;
		var userAmount = 4;
		var total = (maxUsers * userAmount)
			.toFixed(2)
			.toString()
			.replace(".00", "")
			.replace(".", ",");

		return (
			<div className="step-content">
				<h3>Plan Pro</h3>
				<p>
					Selecciona el tipo de pago (trimestral/anual) y el número de empleados
					que vas a contratar a continuación:
				</p>

				<div className="row-switch row-switch-double">
					<span
						className={`label-mensual label-switch ${!isYearly ? "active" : ""
							}`}
					>
						<span className="hidden-xs">Pago </span>trimestral
					</span>

					<Switch
						controlId="isYearlyModal"
						isOn={isYearly}
						handleToggle={this.handleSwitch}
					/>

					<span
						className={`label-anual label-switch ${isYearly ? "active" : ""}`}
					>
						<span className="hidden-xs">Pago </span>Anual
						<span className="oferta hidden"> (10% descuento)</span>
					</span>
				</div>

				<div className="row-empleados">
					<FormGroup controlId="maxUsers">
						<FormLabel>
							Empleados <span className="label-required">*</span>
						</FormLabel>
						<FormControl
							onChange={this.handleEmployeeChange}
							value={maxUsers}
							isInvalid={this.checkValidField("max_users")}
							as="select"
						>
							{employeesOptionList}
						</FormControl>
						<FormControl.Feedback type="invalid">
							{this.state.errors.max_users}
						</FormControl.Feedback>
					</FormGroup>

					<div className="total">
						<span className="total-label">Total</span>
						<span className="total-num">{total}€</span>
					</div>
				</div>
			</div>
		);
	}

	getStep1Content() {
		const { subscription_request, errors } = this.state;
		const { countries, user } = this.props;
		const company = subscription_request.company;

		const currentSubscription = user.subscription.subscription_type.name;

		const countriesOptionList =
			countries.length > 0 &&
			countries.map((item, i) => {
				return (
					<option key={i} value={item.iso}>
						{item.name}
					</option>
				);
			}, this);

		return (
			<>
				<p className="intro">
					Rellena los datos del formulario para que podamos gestionar tu
					solicitud
				</p>
				<Row>
					<Col sm={6} md={4}>
						<FormGroup controlId="company.name">
							<FormLabel>
								Nombre empresa <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="text"
								value={company.name}
								onChange={this.handleChange}
								placeholder="Nombre empresa"
								isInvalid={this.checkValidField("name")}
							/>
							<FormControl.Feedback type="invalid">
								{errors.name}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
					<Col sm={6} md={4}>
						<FormGroup controlId="company.vat_number">
							<FormLabel>
								NIF/CIF <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="text"
								value={company.vat_number}
								onChange={this.handleChange}
								placeholder="NIF o CIF de la empresa"
								isInvalid={this.checkValidField("vat_number")}
							/>
							<FormControl.Feedback type="invalid">
								{errors.vat_number}
							</FormControl.Feedback>
						</FormGroup>
					</Col>

					<Col sm={6} md={4}>
						<FormGroup controlId="company.phone">
							<FormLabel>Teléfono</FormLabel>
							<FormControl
								type="text"
								value={company.phone}
								onChange={this.handleChange}
								placeholder="Teléfono empresa"
								isInvalid={this.checkValidField("phone")}
							/>
							<FormControl.Feedback type="invalid">
								{errors.phone}
							</FormControl.Feedback>
						</FormGroup>
					</Col>

					<Col sm={6} md={4}>
						<FormGroup controlId="company.address">
							<FormLabel>
								Dirección <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="text"
								value={company.address}
								onChange={this.handleChange}
								placeholder="Dirección empresa"
								isInvalid={this.checkValidField("address")}
							/>
							<FormControl.Feedback type="invalid">
								{errors.address}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
					<Col sm={6} md={4}>
						<FormGroup controlId="company.postal_code">
							<FormLabel>
								Código postal <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="text"
								value={company.postal_code}
								onChange={this.handleChange}
								placeholder="Código postal empresa"
								isInvalid={this.checkValidField("postal_code")}
							/>
							<FormControl.Feedback type="invalid">
								{errors.postal_code}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
					<Col sm={6} md={4}>
						<FormGroup controlId="company.city">
							<FormLabel>
								Ciudad <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="text"
								value={company.city}
								onChange={this.handleChange}
								placeholder="Ciudad empresa"
								isInvalid={this.checkValidField("city")}
							/>
							<FormControl.Feedback type="invalid">
								{errors.city}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
					<Col sm={6} md={4}>
						<FormGroup controlId="company.province">
							<FormLabel>
								Provincia <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="text"
								value={company.province}
								onChange={this.handleChange}
								placeholder="Provincia empresa"
								isInvalid={this.checkValidField("province")}
							/>
							<FormControl.Feedback type="invalid">
								{errors.province}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
					<Col sm={6} md={4}>
						<FormGroup controlId="company.country_iso">
							<FormLabel>
								País <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								onChange={this.handleChange}
								value={company.country_iso}
								isInvalid={this.checkValidField("country_iso")}
								as="select"
							>
								<option value="-1">Seleccionar país</option>
								{countriesOptionList}
							</FormControl>
							<FormControl.Feedback type="invalid">
								{errors.country_iso}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
					<Col sm={6} md={4}>
						<FormGroup controlId="company.email">
							<FormLabel>
								E-mail <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="text"
								value={company.email}
								onChange={this.handleChange}
								placeholder="E-mail empresa"
								isInvalid={this.checkValidField("email")}
							/>
							<FormControl.Feedback type="invalid">
								{errors.email}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
				</Row>

				{currentSubscription !== "Pro" && (
					<FormGroup controlId="how_did_you_know_us">
						<FormLabel>
							¿Cómo nos has conocido? <span className="label-required">*</span>
						</FormLabel>
						<FormControl
							type="text"
							value={subscription_request.how_did_you_know_us}
							onChange={this.handleChange}
							placeholder="Indica cómo nos conociste"
							isInvalid={this.checkValidField("how_did_you_know_us")}
						/>
						<FormControl.Feedback type="invalid">
							{errors.how_did_you_know_us}
						</FormControl.Feedback>
					</FormGroup>
				)}

				<FormGroup controlId="observations">
					<FormLabel>Observaciones</FormLabel>
					<FormControl
						type="text"
						value={subscription_request.observations}
						onChange={this.handleChange}
						placeholder="Indica si tienes algún comentario"
						isInvalid={this.checkValidField("observations")}
					/>
					<FormControl.Feedback type="invalid">
						{errors.observations}
					</FormControl.Feedback>
				</FormGroup>
			</>
		);
	}

	getStep2Content() {
		const { selected_plan } = this.props;
		const totalDays = getTrialDays();

		if (selected_plan.name !== "Pro") {
			return (
				<div className="step-content">
					<h3>Tu suscripción al Plan Trial se ha realizado correctamente</h3>
					<p>
						Ya puedes seguir usando Plain hasta que finalice el periodo de prueba gratuito en <strong>{totalDays}</strong> días.
					</p>
				</div>
			);
		}

		return (
			<div className="step-content">
				<h3>Tu suscripción al Plan Pro se ha realizado correctamente</h3>
				<p>
					Estamos validando tu suscripción, te contactaremos lo antes posible
					para enviarte la factura de activación del servicio.
				</p>
			</div>
		);
	}

	render() {
		const { step } = this.state;
		const { selected_plan } = this.props;

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="modal-body modal-subscription">
					{step === 0 && this.getStep0Content()}
					{step === 1 && this.getStep1Content()}
					{step === 2 && this.getStep2Content()}
				</div>

				<div className="modal-footer">
					{step === 0 && (
						<>
							<button
								type="button"
								onClick={this.props.handleClose}
								className="btn btn-outline-primary"
							>
								Cancelar
							</button>
							<button
								type="button"
								onClick={() => this.setStep(1)}
								className="btn btn-primary"
							>
								Siguiente
								<FiChevronRight />
							</button>
						</>
					)}

					{step === 1 && (
						<>
							<button
								type="button"
								onClick={() => this.setStep(0)}
								className="btn btn-outline-primary"
							>
								<FiChevronLeft />
								Volver
							</button>
							<SubmitButton
								type="submit"
								isLoading={this.state.isLoading}
								text="Guardar y enviar"
								loadingText="Guardando..."
							/>
						</>
					)}

					{step === 2 && (
						<button
							type="button"
							onClick={() =>
								selected_plan.name === "Free"
									? window.location.reload(true)
									: this.props.handleClose()
							}
							className="btn btn-outline-primary"
						>
							Cerrar
						</button>
					)}
				</div>
			</form>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.companyReducer,
		user: reducers.authReducer.user,
	};
};

export default connect(mapStateToProps, companyActions)(SubscriptionRequest);
