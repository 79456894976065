import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { MdPlaylistAddCheck } from "react-icons/md";
import Dropzone from "react-dropzone";
import { timesService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import * as timesActions from 'actions/timesActions';
import "./TimeRegistrations.scss";

class TimeObservationEdit extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		// let isEdit = false;
		// if (props.time.observations !== undefined && props.time.observations.trim() !== '' ) {
		// 	isEdit = true;
		// }

		this.state = {
			isReadonly: props.isReadonly !== undefined && props.isReadonly,
			isLoading: false,
			submitted: false,
			errors: {},
			time: props.time,
			attachments: [],
			files: [],
		};
	}

	handleChange = event => {
		const { time } = this.state;

		this.setState({
			time: {
				...time,
				[event.target.id]: event.target.value
			},
		});
	}

	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	handleSubmit = async event => {
		event.preventDefault();

		this.setState({ isLoading: true });

		var formData = new FormData();
		var hasErrors = false;
		let response = "";
		var errorMsg = "";
		const props = this.props;
		const state_time = this.state.time;
		const files = this.state.files;
		let start_utc = null;
		let end_utc = null;

		if (state_time.start)
			start_utc = moment.utc(state_time.start).format();

		if (state_time.end)
			end_utc = moment.utc(state_time.end).format();

		const time = {
			id: state_time.id,
			start: start_utc,
			end: end_utc,
			approved: state_time.approved || false,
			type_id: state_time.type.id,
			observations: state_time.observations,
		};

		const hasFiles = files.length > 0;
		if (hasFiles) {
			files.map((file) => formData.append("filename", file));
		}

		response = await timesService.update(time.id, time);

		// si hay attachments temporales
		if (response.ok && hasFiles) {
			const responseAttachments = await timesService.updateAttachments(
				time.id,
				formData
			);
			// console.log(responseAttachments);

			if (!responseAttachments.ok) {
				hasErrors = true;
				errorMsg = errorFormating(responseAttachments);
			}
		}

		if (!response.ok) {
			hasErrors = true;
			errorMsg = errorFormating(response);
		}

		if (!hasErrors) {
			this.setState({
				isLoading: false,
				errors: {},
			}, () => {

				toastr.success('¡Bien!', 'Cambios guardados correctamente');
				this.props.getTimeList();
				props.handleClose();

			});
		}
		else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorMsg
			});
		}
	}


	onDrop = (files) => {
		this.setState({ files });
	};

	removeAttachment = async (attachment) => {
		const { time } = this.state;
		// console.log(attachment);
		const response = await timesService.removeAttachment(
			time.id,
			attachment.url
		);
		// console.log(response);
		if (response.ok) {
			this.setState({
				time: {
					...time,
					attachments: time.attachments.filter(
						(att) => att.url !== attachment.url
					), // remove attachment from the array
				},
			});
		}
	};
	removeFile = (file) => {
		const { files } = this.state;
		// console.log(files);

		this.setState({
			// files : files.splice(i, 1) // remove the file from the array
			files: files.filter((f) => f.lastModified !== file.lastModified), // remove the file from the array
		});
	};

	getAttachment = async (attachment) => {
		const { time } = this.state;
		// console.log(attachment);
		const response = await timesService.getAttachment(
			time.id,
			attachment.url
		);
		// console.log(response);
		var url = window.URL.createObjectURL(response);
		var a = document.createElement("a");
		document.body.appendChild(a);
		a.href = url;
		a.download = attachment.name;
		a.click();
	};

	render() {
		const { time, isReadonly } = this.state;

		// already saved attachments
		const attachments =
			time.attachments &&
			time.attachments.map((attachment) => {
				var size = attachment.size / 1000000;
				var sizeLetters = "Mb";

				if (size < 1) {
					size = attachment.size / 1000;
					sizeLetters = "Kb";
				}

				return (
					<li className="file-item" key={attachment.url}>
						<span
							className="file-info"
							title={attachment.name}
							onClick={() => this.getAttachment(attachment)}
						>
							<span className="file-name">{attachment.name}</span>
							<span className="file-size">
								({size.toFixed(2)}
								{sizeLetters})
							</span>
						</span>
						{!isReadonly && (
							<div className="file-action">
								<MdPlaylistAddCheck className="file-saved" />
								<button
									type="button"
									className="btn btn-icon"
									title="Eliminar"
									onClick={() => this.removeAttachment(attachment)}
								>
									<FiTrash2 />
								</button>
							</div>
						)}
					</li>
				);
			});

		// temporary dropzone files
		const files =
			this.state.files.length > 0 &&
			this.state.files.map((file, i) => {
				var size = file.size / 1000000;
				var sizeLetters = "Mb";

				if (size < 1) {
					size = file.size / 1000;
					sizeLetters = "Kb";
				}

				return (
					<li className="file-item-temp" key={i}>
						<span className="file-info" title={file.name}>
							<span className="file-name">{file.name}</span>
							<span className="file-size">
								({size.toFixed(2)}
								{sizeLetters})
							</span>
						</span>
						<div className="file-action">
							<button
								type="button"
								className="btn btn-icon"
								title="Eliminar"
								onClick={() => this.removeFile(file)}
							>
								<FiTrash2 />
							</button>
						</div>
					</li>
				);
			});

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="modal-body">

					<FormGroup controlId="observations">
						<FormLabel>Observaciones</FormLabel>
						<FormControl
							as="textarea"
							value={time.observations}
							onChange={this.handleChange}
							placeholder="Observaciones sobre el fichaje"
							isInvalid={this.checkValidField('observations')}
						/>

						<FormControl.Feedback type="invalid">
							{this.state.errors.observations}
						</FormControl.Feedback>
					</FormGroup>

					{isReadonly ? (
						<ul className="dropzone-files">
							{attachments}
							{files}
						</ul>
					) : (
						<Dropzone onDrop={this.onDrop}>
							{({ getRootProps, getInputProps }) => (
								<section className="dropzone-container">
									<div {...getRootProps({ className: "dropzone" })}>
										<input {...getInputProps()} />
										<p>Arrastra tus archivos o haz clic para seleccionar</p>
									</div>
									<ul className="dropzone-files">
										{attachments}
										{files}
									</ul>
								</section>
							)}
						</Dropzone>
					)}

				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">{isReadonly ? "Cerrar" : "Cancelar"}</button>
					{!isReadonly && (
						<SubmitButton
							type="submit"
							isLoading={this.state.isLoading}
							text="Guardar"
							loadingText="Guardando..."
						/>
					)}

				</div>
			</form >
		)
	}
}

const mapStateToProps = (reducers) => {
	return reducers.timesReducer;
};


export default connect(mapStateToProps, timesActions)(TimeObservationEdit)