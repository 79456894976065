import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { FiCalendar } from "react-icons/fi";
import { employeesService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import "./Employees.scss";
import * as employeesActions from 'actions/employeesActions';

import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // datepicker locale
registerLocale("es", es);

class EmployeeGroupEdit extends React.Component {
  constructor(props) {
    super(props);

    // console.log(props);

    let employee_group = {
      group_id: 0,
      start_day: '',
      end_day: '',
    }

    let isEdit = false;

    if (props.employee_group) {
      employee_group = {
        ...props.employee_group,
        group_id: props.employee_group.group.id
      };
      isEdit = true;
    }

    this.state = {
      isEdit,
      isLoading: false,
      submitted: false,
      errors: {},
      employee_group
    };
  }

  handleChange = event => {
    const { employee_group } = this.state;

    this.setState({
      employee_group: {
        ...employee_group,
        [event.target.id]: event.target.value
      },
    });
  }

  handleDateChange = (id, date) => {
    const { employee_group } = this.state;

    let key = id;
    // let value = moment(date).format("YYYY-MM-DD");
    let value = (date !== null) ? moment(date).format("YYYY-MM-DD") : '';

    this.setState({
      employee_group: {
        ...employee_group,
        [key]: value,
      },
    });
  };

  checkValidField = (name) => {
    return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    let response = '';

    const props = this.props;
    const { employee_group } = this.state;

    // if edit => update; else => new    
    if (this.state.isEdit) {
      response = await employeesService.updateGroup(props.employee.id, employee_group);
    }
    else {
      response = await employeesService.addGroup(props.employee.id, employee_group);
    }

    if (response.ok) {
      this.setState({
        isLoading: false,
        errors: {},
      }, () => {

        toastr.success('¡Bien!', 'Cambios guardados correctamente');
        this.props.getEmployeeGroups(props.employee.id);
        props.handleClose();

      });
    }
    else {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(response)
      });
    }
  }

  render() {
    const { groups } = this.props;
    const { employee_group } = this.state;

    const optionList = groups.length > 0 && groups.map((item, i) => {
      return (
        <option key={i} value={item.id}>{item.name}</option>
      )
    }, this);

    return (

      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          <FormGroup controlId="group_id">
            <FormLabel>Grupo</FormLabel>
            <FormControl
              onChange={this.handleChange}
              value={employee_group.group_id}
              isInvalid={this.checkValidField('group_id')}
              as="select">
              <option value="-1">Seleccionar grupo</option>
              {optionList}
            </FormControl>

            <FormControl.Feedback type="invalid">
              {this.state.errors.group_id}
            </FormControl.Feedback>
          </FormGroup>

          <FormGroup controlId="start_day" className={`form-date ${employee_group.start_day === '' && 'empty'}  ${this.checkValidField('start_day') && "is-invalid"}`}>
            <FormLabel>Fecha inicio</FormLabel>

            <DatePicker
              selected={employee_group.start_day === "" ? employee_group.start_day : moment(employee_group.start_day).toDate()}
              onChange={(date) => this.handleDateChange("start_day", date)}
              isInvalid={this.checkValidField("start_day")}
              className="form-control"
              dateFormat="dd/MM/yyyy"
              locale='es'
              placeholderText="dd/mm/aaaa"
            />

            <FiCalendar />
            <FormControl.Feedback type="invalid">
              {this.state.errors.start_day}
            </FormControl.Feedback>
          </FormGroup>

          <FormGroup controlId="end_day" className={`form-date ${this.checkValidField('end_day') && "is-invalid"}`}>
            <FormLabel>Fecha fin (opcional)</FormLabel>

            <DatePicker
              selected={!employee_group.end_day || employee_group.end_day === "" ? "" : moment(employee_group.end_day).toDate()}
              onChange={(date) => this.handleDateChange("end_day", date)}
              isInvalid={this.checkValidField("end_day")}
              className="form-control"
              dateFormat="dd/MM/yyyy"
              locale='es'
              placeholderText="dd/mm/aaaa"
            />

            <FiCalendar />
            <FormControl.Feedback type="invalid">
              {this.state.errors.end_day}
            </FormControl.Feedback>
          </FormGroup>

        </div>

        <div className="modal-footer">
          <button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
          <SubmitButton
            type="submit"
            isLoading={this.state.isLoading}
            text="Guardar"
            loadingText="Guardando..."
          />
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state, myProps) => {
  return {
    ...state.employeesReducer,
    groups: state.groupsReducer.groups,
    employee: myProps.employee,
  }
};

export default connect(mapStateToProps, employeesActions)(EmployeeGroupEdit);