import React, { Component } from "react";
import moment from "moment";
import { FiUsers, FiCalendar, FiList } from "react-icons/fi";
import {
	ButtonGroup,
	Button, OverlayTrigger, Tooltip
} from "react-bootstrap";
import { workflowsService } from "services";
import Avatar from "components/Avatar";
import "components/Dashboard/Dashboard.scss";

class Dashboard_NextAbsences extends Component {
	constructor(props) {
		super(props);
		// console.log(props);

		const today = new Date();

		this.state = {
			current: today,
			upcommig_absences: [],
			month_calendar: [],
			totalToday: 0,
			is_list_view: true
		};
	}

	async componentDidMount() {
		await this.getUpcommingAbsences();
		this.getCalendarMonth();
	}

	async getUpcommingAbsences() {
		const upcommig_absences = await workflowsService.getUpcomingAbsences({ absence_in_days: 15 });
		// console.log(upcommig_absences);

		if (upcommig_absences.ok) {
			const list = upcommig_absences.data;

			if (list.length > 0) {
				var totalToday = 0;
				list.forEach((absence, index) => {
					const now = new Date();
					const start = moment(absence.start, "YYYY-MM-DD");
					const end = absence.end ? moment(absence.end, "YYYY-MM-DD") : start;

					const isToday = moment(now).isBetween(start, end, "days", "[]"); // [] is all inclusive
					absence.isToday = isToday;

					if (isToday)
						totalToday += 1;
				});
			}

			this.setState({
				upcommig_absences: list,
				totalToday,
			});
		}
	}

	getCalendarMonth() {
		const { upcommig_absences, current } = this.state;
		// console.log(upcommig_absences);

		const today = new Date();
		const year = current.getFullYear();
		const month = current.getMonth();
		let firstDayOfTheMonth = (new Date(year, month)).getDay();
		firstDayOfTheMonth = (firstDayOfTheMonth === 0) ? 6 : firstDayOfTheMonth - 1;
		let daysInMonth = 32 - new Date(year, month, 32).getDate();

		// console.log(firstDayOfTheMonth);

		let day = 1;
		const month_calendar = [];
		for (let i = 0; i < 6; i++) {
			let calendar_week = [];

			for (let j = 0; j < 7; j++) {
				let j_date = new Date(year, month, day);

				// rellena días vacíos antes del inicio de mes
				if (i === 0 && j < firstDayOfTheMonth) {
					let calendar_day = {
						day: null,
						date: null,
						className: 'empty',
						absences: []
					};
					calendar_week.push(calendar_day);
				} else if (day > daysInMonth) {
					break;
				} else {
					let calendar_day = {
						day: day,
						date: moment(j_date).format("YYYY-MM-DD"),
						absences: []
					};

					if (day === today.getDate() && year === today.getFullYear() && month === today.getMonth()) {
						calendar_day.className = "today";
					}

					// busca ausencias y las enchufa
					upcommig_absences.map((item, i) => {
						if (moment(j_date).isBetween(moment(item.start), moment(item.end), 'days', '[]'))
							calendar_day.absences.push(item);
					});


					calendar_week.push(calendar_day);
					day++;
				}
			}
			month_calendar.push(calendar_week);
		}

		// console.log(month_calendar);

		this.setState({
			month_calendar,
		});

	}

	getAbsenceRows = (absence_list, isToday) => absence_list.map((absence, index) => {
		if (isToday === undefined || absence.isToday === isToday) {
			const employee = absence.requester;
			const start = moment(absence.start).format("DD MMMM");

			return (
				<li key={index}>
					<Avatar
						userId={employee.id}
						className="avatar-xs"
						alt={employee.name}
					/>
					<div className="item-main">
						<span className="item-name">
							{employee.name} {employee.surname}{" "}
							<span className="total-days">
								({absence.effective_days} días)
							</span>
						</span>
						<span className="item-info">
							{absence.shift.name} desde {start}
						</span>
					</div>
				</li>
			);
		} else return "";
	});

	// starts with a monday
	getWeekDays = () =>
		Array.from({ length: 7 }, (item, i) => (
			<th key={i}>
				{new Date(2021, 10, i + 1).toLocaleString('es-ES', { weekday: 'narrow' })}
			</th>
		));

	getWeeks = () => this.state.month_calendar.map((week, i) => {
		//console.log(week);
		return (
			<tr key={i}>
				{this.getCalendarDays(week)}
			</tr>
		);
	});

	getCalendarDays = (week) => week.map((calendar_day, i) => {
		if (calendar_day.date === null) return (<td key={i}></td>)

		let [year, month, date] = calendar_day.date.split("-")

		if (calendar_day.absences.length > 0) {

			var color, tooltipContent;
			tooltipContent = <ul className="list-employees">{this.getAbsenceRows(calendar_day.absences)}</ul>

			calendar_day.absences.map((item, i) => {
				color = item.shift.background_color;
			});

			// const shift = o.shift;
			// tooltipContent = o.comments ? o.comments : shift.name + " " + shift.description;
			// color = shift.background_color;

			const tooltip = <Tooltip className="tooltip-calendar">{tooltipContent}</Tooltip>;

			return (
				<td className={calendar_day.className} key={i}>
					<OverlayTrigger key={i} placement="top" overlay={tooltip}>
						<span style={{ color: color && color }}>{date}</span>
					</OverlayTrigger>
				</td>
			)
		}

		return (
			<td key={i} className={calendar_day.className}>{date}</td>
		)
	});

	getCalendarView = () => {
		const { current } = this.state;

		return (
			<>
				<h4 className="title-month">{current.toLocaleString('es-ES', { month: 'long' })} {current.getFullYear()}</h4>
				<table className="table-calendar">
					<thead>
						<tr>
							{this.getWeekDays()}
						</tr>
					</thead>
					<tbody>
						{this.getWeeks()}
					</tbody>
				</table>

				{this.getTodayView()}
			</>
		)
	}

	getListView = () => {
		const { upcommig_absences, totalToday } = this.state;

		var frase = totalToday === 1 ? "empleado está ausente" : "empleados están ausentes";

		return (
			<>
				{this.getTodayView()}

				{
					upcommig_absences.length - totalToday > 0 && (
						<h4 className="title">Próximos 15 días</h4>
					)
				}

				{
					upcommig_absences.length - totalToday > 0 && (
						<ul className="list-employees">{this.getAbsenceRows(upcommig_absences, false)}</ul>
					)
				}

				{
					totalToday !== 0 && upcommig_absences.length - totalToday === 0 && (
						<p className="mt30">
							No hay otras ausencias en los próximos 15 días
						</p>
					)
				}
			</>)
	}

	getTodayView = () => {
		const { upcommig_absences, totalToday } = this.state;

		var frase = totalToday === 1 ? "empleado está ausente" : "empleados están ausentes";
		return (
			<>
				<h4 className="title">
					Hoy {totalToday} {frase}
				</h4>
				{
					totalToday > 0 ? (
						<ul className="list-employees">{this.getAbsenceRows(upcommig_absences, true)}</ul>
					) : (
						<p>Todos estan trabajando hoy</p>
					)
				}
			</>)
	}

	// Absence view list / calendar
	handleChangeView = (is_list_view) => {
		this.setState({
			is_list_view,
		});
	};

	render() {
		const { upcommig_absences, is_list_view } = this.state;

		if (upcommig_absences.length > 0) {
			return (
				<>
					<div className="card-top">
						<h2>Próximas ausencias</h2>

						<ButtonGroup aria-label="Plan view">
							<Button
								variant={`${is_list_view ? "primary" : "default"}`}
								onClick={() => this.handleChangeView(true)}
							>
								<FiList className="icon" />
							</Button>
							<Button
								variant={`${!is_list_view ? "primary" : "default"}`}
								onClick={() => this.handleChangeView(false)}
							>
								<FiCalendar className="icon" />
							</Button>
						</ButtonGroup>
					</div>
					<div className="card-content">
						{is_list_view ? this.getListView() : this.getCalendarView()}
					</div>
				</>)
		} else {
			return (
				<>
					<div className="card-top">
						<h2>Próximas ausencias</h2>
					</div>
					<div className="card-content">
						<div className="empty">
							<FiUsers />
							<p>No hay ausencias en los próximos 15 días</p>
						</div>
					</div>
				</>);
		}
	}
}

export default Dashboard_NextAbsences;
