import React from "react";
import { connect } from "react-redux";
import { FiPlus } from "react-icons/fi";
import { Nav, Tab } from "react-bootstrap";
import Layout from "components/Layout";
import MyDocuments from "components/Documents";
import DocumentsBulkList from "components/Documents/DocumentsBulkList";
import DocumentsAdd from "components/Documents/DocumentAddModal";
import Loading from "components/Loading";
import { showModal, hideModal } from "actions/modalActions";
import Modal from "components/Modal";
import "components/Documents/Documents.scss";
import { isAdmin } from "utils/utils";

class Documents extends React.Component {
	constructor(props) {
		super(props);

		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
		this.openNewDocumentModal = this.openNewDocumentModal.bind(this);

		this.state = {
			loading: true,
			selectedTab: "mine",
		};
	}

	closeModal() {
		this.props.hideModal();
	}

	openAlertModal(message, title) {
		this.props.showModal(
			{
				open: true,
				title: title || "Error",
				style: { width: "400px" },
				message: message,
				closeModal: this.closeModal,
			},
			"alert"
		);
	}

	openNewDocumentModal() {
		const category = this.props.match.params.category;
		const tab = this.state.selectedTab;

		this.props.showModal(
			{
				open: true,
				title:
					tab == "mine" ? "Añadir documentos" : "Envío masivo de documentos",
				style: { width: "450px" },
				content: (
					<DocumentsAdd
						category={category}
						selectedTab={tab}
						handleClose={this.closeModal}
					/>
				),
				closeModal: this.closeModal,
			},
			"edit"
		);
	}
	/*
	async componentDidMount() {
		// get documents
		// console.log(documents);
	}
	*/

	selectTab = (tab) => {
		this.setState({
			selectedTab: tab,
		});
	};

	getEmployeeContent = () => {
		const category = this.props.match.params.category;
		return <MyDocuments category={category} />;
	};

	getAdminContent = () => {
		const category = this.props.match.params.category;

		return (
			<Tab.Container
				defaultActiveKey="mine"
				onSelect={(k) => this.selectTab(k)}
			>
				<Nav variant="tabs">
					<Nav.Item>
						<Nav.Link eventKey="mine">Mis documentos</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="bulk">Envío masivo</Nav.Link>
					</Nav.Item>
				</Nav>
				<Tab.Content>
					<Tab.Pane eventKey="mine">
						<MyDocuments category={category} />
					</Tab.Pane>
					<Tab.Pane eventKey="bulk">
						<DocumentsBulkList />
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
		);
	};

	getContent = () => {
		if (this.props.loading) {
			return <Loading />;
		}

		// TODO error control
		// if (!this.props.documents) {
		// 	return "Error cargando los documentos";
		// }

		// TODO first steps:
		// if (this.props.documents.length == 0) {
		// 	const step_text = isAdmin()
		// 		? "Desde aquí podrás aprobar o denegar las peticiones de ausencia de los empleados"
		// 		: "Desde aquí podrás solicitar vacaciones o ausencias para tu supervisor te las apruebe";

		// 	return (
		// 		<div className="first-steps">
		// 			<FiFolder className="icono-pasos" />
		// 			<h3 className="title2">Primeros pasos</h3>

		// 			<p>
		// 				{step_text}. Aquí te explicamos cómo funcionan:{" "}
		// 				<a
		// 					className="alink"
		// 					target="_blank"
		// 					href="https://help.plain.ninja/es/articles/3693962-que-es-una-ausencia"
		// 				>
		// 					https://help.plain.ninja/es/articles/3693962-que-es-una-ausencia
		// 				</a>
		// 			</p>
		// 		</div>
		// 	);
		// }

		return isAdmin() ? this.getAdminContent() : this.getEmployeeContent();
		// return this.getEmployeeContent();
	};

	render() {
		return (
			<>
				<Layout className="page-documents">
					<div className="heading">
						<h1 className="title">
							{isAdmin() ? "Documentos" : "Mis documentos"}
						</h1>

						<div className="heading-actions">
							<button
								type="button"
								title="Crear documento"
								onClick={this.openNewDocumentModal}
								className="btn btn-new"
							>
								<FiPlus />
							</button>
						</div>
					</div>

					{this.getContent()}
				</Layout>

				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		// ...reducers.employeesReducer,
		user: reducers.authReducer.user,
		// loading: reducers.employeesReducer.loading
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	//   getAllDocuments: () => dispatch(documentsActions.getAllDocuments()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
