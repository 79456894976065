import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import Autosuggest from "react-autosuggest";
import { FiCalendar, FiX, } from "react-icons/fi";
import { groupsService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import "./Groups.scss";
import * as groupsActions from 'actions/groupsActions';

import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // datepicker locale
registerLocale("es", es);

class GroupStaffingLevelsEdit extends React.Component {
  constructor(props) {
    super(props);

    // console.log(props);

    let group_staffing_level = {
      staffing_level_id: 0,
      start_day: props.start_day ? props.start_day : '',
      end_day: props.end_day ? props.end_day : '',
      comments: '',
    }

    let isEdit = false;

    // console.log(props.group_staffing_level);

    if (props.group_staffing_level) {
      group_staffing_level = {
        ...props.group_staffing_level,
        staffing_level_id: props.group_staffing_level.staffing_level.id,
        staffing_level_name: props.group_staffing_level.staffing_level.name
      };
      isEdit = true;
    }

    this.state = {
      isEdit,
      isLoading: false,
      submitted: false,
      errors: {},
      group_staffing_level,
      needs_suggestions: [],
      always_open: false,
    };
  }

  handleChange = event => {
    const { group_staffing_level } = this.state;

    this.setState({
      group_staffing_level: {
        ...group_staffing_level,
        [event.target.id]: event.target.value
      },
    });
  }

  handleChecked = (event) => {
    this.setState({
      [event.target.id]: event.target.checked,
    });
  };

  handleDateChange = (id, date) => {
    const { group_staffing_level } = this.state;

    let key = id;
    let value = moment(date).format("YYYY-MM-DD");

    this.setState({
      group_staffing_level: {
        ...group_staffing_level,
        [key]: value,
      },
    });
  };

  checkValidField = (name) => {
    return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    let response = '';

    const props = this.props;
    const { group_staffing_level, always_open, isEdit } = this.state;

    // if edit => update; else => new    
    if (isEdit) {
      response = await groupsService.updateStaffingLevel(props.group_id, group_staffing_level);
    }
    else {
      response = await groupsService.addStaffingLevel(props.group_id, group_staffing_level);
    }

    if (response.ok) {
      this.setState({
        isLoading: false,
        errors: {},
      }, () => {

        toastr.success('¡Bien!', 'Cambios guardados correctamente');
        props.getGroupStaffingLevels(props.group_id);

        if (props.triggerStaffingLevelAssignments)
          props.triggerStaffingLevelAssignments();

        if (!always_open) {
          props.handleClose();
        }
        else {

          // reset
          this.setState(prevState => ({
            group_staffing_level: {
              // ...prevState.group_staffing_level,
              staffing_level_id: 0,
              staffing_level_name: "",
              start_day: props.start_day ? props.start_day : '',
              end_day: props.end_day ? props.end_day : '',
            },
          }));
        }

      });
    }
    else {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(response)
      });
    }
  }

  // STAFFING NEEDS AUTOCOMPLETE SUGGESTIONS
  getNeedsSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const { select_staffing_levels } = this.props;

    if (inputLength === 0) return select_staffing_levels;

    return select_staffing_levels.filter((o) =>
      o.name.toLowerCase().includes(inputValue)
    );
  };

  onChangeNeedsAutosugest = (event, { newValue, method }) => {
    this.setState(prevState => ({
      group_staffing_level: {
        ...prevState.group_staffing_level,
        staffing_level_name: newValue, // Actualiza el nombre
      },
    }));
  };

  onClearNeedsAutosugest = () => {
    this.setState(prevState => ({
      group_staffing_level: {
        ...prevState.group_staffing_level,
        staffing_level_name: "", // Limpia el nombre
        staffing_level_id: 0, // Limpia el ID
      },
    }));
  };

  // Autosuggest will call this function every time you need to update suggestions
  onNeedsSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      needs_suggestions: this.getNeedsSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions
  onNeedsSuggestionsClearRequested = () => {
    this.setState({
      needs_suggestions: [],
    });
  };

  onNeedsSuggestionSelected = (event, { suggestion }) => {
    this.setState(prevState => ({
      group_staffing_level: {
        ...prevState.group_staffing_level,
        staffing_level_id: suggestion.id, // Actualiza el ID
        staffing_level_name: suggestion.name, // Actualiza el nombre
      },
    }), () => {
      this.setState({
        needs_suggestions: []
      });
    });
  };

  render() {
    const { group_staffing_level, needs_suggestions, always_open, isEdit, errors } = this.state;

    const renderSuggestion = (suggestion) => (
      <>
        <span className="suggestion-main">
          {suggestion.name}
        </span>
      </>
    );

    return (

      <form onSubmit={this.handleSubmit}>
        <div className="modal-body group-staffing-edit">

          <FormGroup controlId="staffing_level_id">
            <FormLabel>
              Necesidades de personal <span className="label-required">*</span>
            </FormLabel>
            <div className="react-autosuggest">
              <Autosuggest
                suggestions={needs_suggestions}
                onSuggestionsFetchRequested={this.onNeedsSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onNeedsSuggestionsClearRequested}
                onSuggestionSelected={this.onNeedsSuggestionSelected}
                getSuggestionValue={(suggestion) => suggestion.name}
                renderSuggestion={renderSuggestion}
                alwaysRenderSuggestions={true}
                inputProps={{
                  placeholder: "Selecciona necesidad",
                  value: group_staffing_level.staffing_level_name || "",
                  onFocus: () => this.onNeedsSuggestionsFetchRequested({ value: this.state.group_staffing_level.staffing_level_name || '' }),
                  onChange: this.onChangeNeedsAutosugest,
                  className: `form-control ${this.checkValidField('staffing_level_id') ? 'is-invalid' : ''}`,  // Aplicar estilos de Bootstrap para la validación
                  id: "staffing_level_id",
                }}
              />

              {group_staffing_level.staffing_level_name && (
                <button
                  className="btn-autosuggest-clear btn-transparent"
                  onClick={this.onClearNeedsAutosugest}
                  type="button"
                >
                  <FiX />
                </button>
              )}
            </div>

            {errors.staffing_level_id && (
              <div className="invalid-feedback d-block">{errors.staffing_level_id}
                {(errors.staffing_level_id_value && errors.staffing_level_id_value !== "0") && (<strong>: {errors.staffing_level_id_value}</strong>)}
              </div>
            )}
          </FormGroup>

          <FormGroup controlId="start_day" className={`form-date ${group_staffing_level.start_day === '' && 'empty'}  ${this.checkValidField('start_day') && "is-invalid"}`}>
            <FormLabel>Fecha inicio <span className="label-required">*</span></FormLabel>

            <DatePicker
              selected={group_staffing_level.start_day === "" ? group_staffing_level.start_day : moment(group_staffing_level.start_day).toDate()}
              onChange={(date) => this.handleDateChange("start_day", date)}
              isInvalid={this.checkValidField("start_day")}
              className="form-control"
              dateFormat="dd/MM/yyyy"
              locale='es'
              placeholderText="dd/mm/aaaa"
            />

            <FiCalendar />
            <FormControl.Feedback type="invalid">
              {errors.start_day}
            </FormControl.Feedback>
          </FormGroup>

          <FormGroup controlId="end_day" className={`form-date ${group_staffing_level.end_day === '' && 'empty'}  ${this.checkValidField('end_day') && "is-invalid"}`}>
            <FormLabel>Fecha fin</FormLabel>

            <DatePicker
              selected={(group_staffing_level.end_day === "" || !group_staffing_level.end_day) ? group_staffing_level.end_day : moment(group_staffing_level.end_day).toDate()}
              onChange={(date) => this.handleDateChange("end_day", date)}
              isInvalid={this.checkValidField("end_day")}
              className="form-control"
              dateFormat="dd/MM/yyyy"
              locale='es'
              placeholderText="dd/mm/aaaa"
            />

            <FiCalendar />
            <FormControl.Feedback type="invalid">
              {errors.end_day}
            </FormControl.Feedback>
          </FormGroup>

          <FormGroup controlId="comments">
            <FormLabel>Observaciones</FormLabel>
            <FormControl
              placeholder="Observaciones o comentarios"
              isInvalid={this.checkValidField('comments')}
              onChange={this.handleChange}
              value={group_staffing_level.comments}
              type="textarea"
            />
            <FormControl.Feedback type="invalid">
              {this.state.errors.comments}
            </FormControl.Feedback>
          </FormGroup>



          {!isEdit &&
            <div className="checkbox">
              <input
                type="checkbox"
                id="always_open"
                onChange={this.handleChecked}
                checked={always_open}
              />
              <label
                htmlFor="always_open"
                className="btn-check-label"
              >
                <span className="column-item-label">No cerrar popup después de guardar</span>
              </label>
            </div>
          }

        </div>

        <div className="modal-footer">
          <button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
          <SubmitButton
            type="submit"
            isLoading={this.state.isLoading}
            text="Guardar"
            loadingText="Guardando..."
          />
        </div>
      </form>
    )
  }
}

const mapStateToProps = (reducers) => {
  return {
    ...reducers.groupsReducer,
  }
};

export default connect(mapStateToProps, groupsActions)(GroupStaffingLevelsEdit);