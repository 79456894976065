import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { FiThumbsUp } from "react-icons/fi";
import { workflowsService } from "services";
import { isAdmin, isSupervisor } from "utils/utils";
import "components/Dashboard/Dashboard.scss";

class Dashboard_PendingRequests extends Component {
	constructor(props) {
		super(props);
		// console.log(props);

		this.state = {
			total: 0,
		};
	}

	componentDidMount() {
		this.getCounters();
	}

	async getCounters() {
		const counters = await workflowsService.getCounters();
		var total = 0;
		if (counters.ok) {
			if (isAdmin() || isSupervisor()) {
				total = counters.workflows_to_be_supervised_by_you;
			} else {
				total = counters.workflows_to_be_supervised_from_you;
			}

			this.setState({
				total,
			});
		}
	}

	render() {
		const { total } = this.state;

		if (total > 0) {
			return (
				<>
					<div className="counter">
						<span className="counter-item">{total}</span>
						<span className="text">No aprobadas</span>
					</div>

					<NavLink to="/absences" className="btn btn-primary btn-inline">
						Ver pendientes
					</NavLink>
				</>
			);
		} else {
			return (
				<div className="empty">
					<FiThumbsUp />
					<p>No hay ninguna solicitud pendiente</p>
				</div>
			);
		}
	}
}

export default Dashboard_PendingRequests;
