import React from 'react';
import { employeesService } from "services";
import "./Avatar.scss";

class Avatar extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      pic: ''
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getImage();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps)
      this.getImage();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Abrir o crear la base de datos
  openDatabase() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('avatarsDB', 1);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        db.createObjectStore('avatars', { keyPath: 'userId' });
      };

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject(event.target.errorCode);
      };
    });
  }

  // Obtener avatar de IndexedDB
  getAvatarFromDB(userId) {
    // console.log(userId);
    return new Promise(async (resolve, reject) => {
      // Validar si userId es válido antes de proceder
      if (!userId) {
        resolve(null);  // Devuelve null o un valor por defecto si userId no es válido
        return;
      }

      try {
        const db = await this.openDatabase();
        const transaction = db.transaction(['avatars'], 'readonly');
        const store = transaction.objectStore('avatars');
        const request = store.get(userId);

        request.onsuccess = () => {
          resolve(request.result ? request.result.img : null);
        };

        request.onerror = (event) => {
          reject(`Error al intentar recuperar el avatar: ${event.target.errorCode}`);
        };
      } catch (error) {
        reject(`Error inesperado al intentar recuperar el avatar: ${error.message}`);
      }
    });
  }


  // Guardar avatar en IndexedDB
  saveAvatarToDB(userId, img) {
    return new Promise(async (resolve, reject) => {
      const db = await this.openDatabase();
      const transaction = db.transaction(['avatars'], 'readwrite');
      const store = transaction.objectStore('avatars');
      const request = store.put({ userId, img });

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event) => {
        reject(event.target.errorCode);
      };
    });
  }

  // Obtener la imagen del avatar
  async getImage() {
    const storageData = await this.getAvatarFromDB(this.props.userId);
    let img = "";

    if (storageData !== null) {
      img = storageData;
    } else {
      img = await employeesService.getAvatar(this.props.userId);
      await this.saveAvatarToDB(this.props.userId, img);
    }

    if (this._isMounted) {
      this.setState({
        pic: img,
      });
    }
  }

  render() {
    var alt = "Avatar";
    if (this.props.alt !== undefined)
      alt = this.props.alt;

    var title = alt;
    if (this.props.title !== undefined)
      title = this.props.title;

    return (
      <img onClick={this.props.onClick} src={this.state.pic} className={`avatar ${this.props.className}`} alt={alt} title={title} />
    )
  }
}

export default Avatar;
