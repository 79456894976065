import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { toastr } from 'react-redux-toastr';
import { Row, Col, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { IoMdColorFill } from "react-icons/io";
import { FiDelete, FiX } from "react-icons/fi";
import { TwitterPicker } from 'react-color';
import { showModal, hideModal } from "actions/modalActions";
import Avatars from 'components/Avatar/Avatars';
import Switch from "components/Switch";
import { groupsService } from "services";
import { errorFormating } from "utils/utils";
import "./Groups.scss";

class GroupInfo extends React.Component {
	constructor(props) {
		super(props);
		console.log(props);

		const group = this.props.group;

		if (group.calendar)
			group.calendar_id = group.calendar.id;

		this.state = {
			group,
			prevGroup: group,
			isPickerVisible: false,
			errors: {},
			error_footer: '',
			temp_group: {},
			selected_groups: group.groups || [],
			new_item_error: false,
		};

		this.closeModal = this.closeModal.bind(this);
		this.openDeleteModal = this.openDeleteModal.bind(this);
	}

	closeModal() {
		this.props.hideModal()
	}

	openDeleteModal() {
		this.props.showModal({
			open: true,
			title: 'Eliminar grupo',
			style: { width: '400px' },
			message: '¿Desea eliminar el grupo? Esta acción no se puede deshacer',
			deleteAction: this.confirmDelete,
			closeModal: this.closeModal
		}, 'delete')
	}

	handleChange = event => {
		const { group } = this.state;
		const target = event.target.id;
		let value = event.target.value;

		if (target === "temp_group") {
			// add group to list
			this.setState({
				[target]: value
			});
		}
		else {
			// normal group change
			this.setState({
				prevGroup: group,
				group: {
					...group,
					[event.target.id]: value
				}
			});
		}


	}

	handleColorChange = ({ hex }) => {
		const { group } = this.state;

		if (hex === undefined)
			hex = '';

		this.setState({
			group: {
				...group,
				color: hex
			},
			isPickerVisible: false,
		}, () => {
			this._saveGroup();
		});
	}

	handleBlur = async () => {
		const hasChanged = this.state.group !== this.state.prevGroup;

		if (hasChanged)
			this._saveGroup();
	}

	confirmDelete = async () => {
		const response = await groupsService.remove(this.state.group.id);
		this.closeModal();

		if (response.status === 200)
			this.props.history.goBack();
		else {
			let textError = errorFormating(response);

			this.setState({
				error_footer: textError.id
			});
		}
	}

	_saveGroup = async () => {
		const { group, selected_groups } = this.state;
		// si es multigrup, pasar la lista de grupos por ids
		console.log(group);

		if (group.is_multigroup && selected_groups.length > 0)
			group.group_ids = selected_groups.map(group => group.id);
		else
			group.group_ids = [];

		const response = await groupsService.update(group);

		if (response.ok) {
			toastr.success('¡Bien!', 'Cambios realizados correctamente');
		}

		this.setState({
			prevGroup: group,
			errors: errorFormating(response)
		});
	}

	checkValidField = (name) => {
		return (this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	// MULTIGROUPS
	addGroup = () => {
		let { selected_groups, temp_group, new_item_error } = this.state;
		let isValid = true;
		let group;

		if (temp_group === undefined) {
			isValid = false;
		}
		else {
			group = this.props.select_groups.find(item => item.id === parseInt(temp_group));
			if (group === undefined) {
				isValid = false;
				new_item_error = true;
			}
		}

		if (isValid) {
			// check if day already exists
			const exists = selected_groups.find(item => item.id === parseInt(temp_group));

			// if not : add new group
			if (!exists) {
				selected_groups = selected_groups.concat(group);
			}

			this.setState({
				selected_groups,
				new_item_error: false,
				temp_group: {}
			}, () => {
				this._saveGroup();
			});
		}
		else {
			this.setState({
				new_item_error,
			});
		}
	}

	removeGroup = (idGroup) => {
		this.setState((prevState, props) => {
			return {
				selected_groups: prevState.selected_groups.filter(item => item.id !== parseInt(idGroup))
			};
		}, () => {
			this._saveGroup();
		});
	}

	getSelectedGroups = () => this.state.selected_groups.map((item) => {
		return (
			<li key={item.id} className="tag-default tag-delete">
				{item.name}
				<button type="button" onClick={() => this.removeGroup(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});

	getContent = () => {
		const { group, errors, new_item_error, temp_group, } = this.state;
		const { select_calendars, select_groups } = this.props;

		const optionCalendarList = select_calendars.length > 0 && select_calendars.map((item, i) => {
			return (
				<option key={item.id} value={item.id}>{item.name}</option>
			)
		}, this);

		const optionGroupList = select_groups.length > 0 && select_groups.map((item, i) => {
			return (
				<option key={i} value={item.id}>{item.name}</option>
			)
		}, this);

		let empleados_count = "Ningún empleado asignado";
		if (group.total_employees === 1)
			empleados_count = "1 empleado";
		else if (group.total_employees > 1)
			empleados_count = group.total_employees + " empleados";


		const onToggleColorPicker = () => { this.setState({ isPickerVisible: !this.state.isPickerVisible }) }
		const isMultigroup = group.is_multigroup;

		return (
			<div className="tab-info-content">
				<Row>
					<Col sm={6} md={4}>
						<FormGroup controlId="name">
							<FormLabel>Nombre <span className="label-required">*</span></FormLabel>
							<FormControl
								type="text"
								value={group.name}
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								placeholder="Nombre grupo"
								isInvalid={this.checkValidField('name')}
							/>
							<FormControl.Feedback type="invalid">
								{errors.name}
							</FormControl.Feedback>
						</FormGroup>

						<FormGroup controlId="calendar_id">
							<FormLabel>Calendario</FormLabel>
							<FormControl
								type="text"
								value={group.calendar_id}
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								isInvalid={this.checkValidField('calendar_id')}
								as="select">
								<option value="">Selecciona calendario</option>
								{optionCalendarList}
							</FormControl>

							<FormControl.Feedback type="invalid">
								{errors.calendar_id}
							</FormControl.Feedback>
						</FormGroup>

						<FormGroup controlId="color" className="form-color">
							<div className="flex-between">
								<FormLabel>Color</FormLabel>
								<FiDelete className={`icon-remove-color ${!group.color && 'hidden'} `} onClick={() => this.handleColorChange("")} />
							</div>
							<FormControl
								type="text"
								value={group.color}
								placeholder="Color"
								readOnly
								isInvalid={this.checkValidField('color')}
								style={{ backgroundColor: group.color && group.color }}
								onClick={onToggleColorPicker}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.color}
							</FormControl.Feedback>

							<IoMdColorFill onClick={onToggleColorPicker} />

							{this.state.isPickerVisible && (
								<div style={{ position: 'absolute' }}>
									<TwitterPicker
										color={group.color ? group.color : '#ffffff'}
										onChangeComplete={(hex) => this.handleColorChange(hex)}
									/>
								</div>
							)}
						</FormGroup>


						<FormGroup title="No se puede editar" className="form-group-flex form-group-main" controlId="is_multigroup">
							<FormLabel>Multigrupo</FormLabel>
							<Switch
								controlId="is_multigroup"
								isOn={isMultigroup}
								handleToggle={this.handleChange}
								disabled={true}
							/>
						</FormGroup>

					</Col>

					<Col sm={6} md={8} >
						{!isMultigroup ? (
							<>
								<h3 className="subtitle">Empleados del grupo</h3>

								<div className="avatar-container">
									{group.total_employees > 0 &&
										<Avatars employees={group.employees} />
									}
									{empleados_count}
								</div>
							</>
						) : (<div>
							<div className="flex-group mb15">
								<FormGroup controlId="temp_group">
									<FormLabel>Grupos</FormLabel>
									<FormControl
										type="text"
										onChange={this.handleChange}
										value={temp_group}
										placeholder="Selecciona grupo"
										isInvalid={new_item_error}
										as="select"
									>
										<option value="">Selecciona grupo</option>
										{optionGroupList}
									</FormControl>
									<FormControl.Feedback type="invalid">
										Debes agregar algún grupo
									</FormControl.Feedback>
								</FormGroup>
								<button type="button" onClick={this.addGroup} className="btn btn-primary">Añadir</button>
							</div>
							<ul className="list-tags">
								{this.getSelectedGroups()}
							</ul>
						</div>)}

					</Col>
				</Row>

				{this.state.error_footer &&
					<div className="alert alert-danger alert-dismissible fade show mt15" role="alert">
						{this.state.error_footer}
					</div>
				}

				<div className="row-buttons">
					<button type="button" className="btn btn-danger" onClick={this.openDeleteModal} >Eliminar grupo</button>
				</div>
			</div>
		);
	}
	render() {
		return (
			<>
				{this.getContent()}
			</>
		)
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.calendarsReducer,
		...reducers.groupsReducer,
	}
};


const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }))
	},
})


export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(GroupInfo);
