import React from "react";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { groupsService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import Switch from "components/Switch";
import "./Groups.scss";
import * as groupsActions from "actions/groupsActions";

class GroupSupervisorEdit extends React.Component {
	constructor(props) {
		super(props);

		// console.log(props);


		let group_supervisor = {
			employee_id: -1,
			read_only: false,
		}

		let isEdit = false;

		if (props.group_supervisor) {
			group_supervisor = props.group_supervisor;
			group_supervisor.employee_id = group_supervisor.employee.id;
			group_supervisor.id = group_supervisor.id;
			isEdit = true;
		}

		this.state = {
			isLoading: false,
			submitted: false,
			errors: {},
			group_supervisor,
			isEdit,
		};
	}

	handleChange = (event) => {
		const { group_supervisor } = this.state;

		this.setState({
			group_supervisor: {
				...group_supervisor,
				[event.target.id]: event.target.value
			},
		});
	};

	checkValidField = (name) => {
		return (
			this.state.submitted &&
			this.state.errors[name] !== undefined &&
			this.state.errors[name] !== ""
		);
	};

	handleSwitch = event => {
		const { group_supervisor } = this.state;

		this.setState({
			group_supervisor: {
				...group_supervisor,
				[event.target.id]: !event.target.checked
			},
		});
	}

	handleSubmit = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		const props = this.props;
		const { group_supervisor, isEdit } = this.state;

		let response = "";
		var success_message = "";

		// si es nuevo, asigna
		if (!isEdit) {
			response = await groupsService.addSupervisor(
				props.group.id,
				group_supervisor
			);

			success_message = "Supervisor añadido correctamente";
		}
		else {
			// si existe, edita
			response = await groupsService.editSupervisor(
				props.group.id,
				group_supervisor
			);
			success_message = "Supervisor editado correctamente";
		}


		if (response.ok) {
			this.setState(
				{
					isLoading: false,
					errors: {},
				},
				() => {
					toastr.success("¡Bien!", success_message);
					this.props.getGroupSupervisors(props.group.id);
					props.handleClose();
				}
			);
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response),
			});
		}
	};

	render() {
		const { select_employees } = this.props;
		const { group_supervisor, isEdit } = this.state;

		const optionList =
			select_employees.length > 0 &&
			select_employees.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name} {item.surname}
					</option>
				);
			}, this);

		const isEditable = !group_supervisor.read_only;

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="modal-body">
					<FormGroup controlId="employee_id">
						<FormLabel>Empleado</FormLabel>
						<FormControl
							onChange={this.handleChange}
							onBlur={this.handleBlur}
							value={group_supervisor.employee_id}
							isInvalid={this.checkValidField("employee_id")}
							as="select"
							disabled={isEdit}
						>
							<option value="-1">Seleccionar empleado supervisor</option>
							{optionList}
						</FormControl>

						<FormControl.Feedback type="invalid">
							{this.state.errors.employee_id}
						</FormControl.Feedback>
					</FormGroup>

					<FormGroup className="form-group-flex" controlId="read_only">
						<FormLabel>Puede editar planificación</FormLabel>
						<Switch
							controlId="read_only"
							isOn={isEditable}
							handleToggle={this.handleSwitch}
						/>

						<FormControl.Feedback type="invalid">
							{this.state.errors.read_only}
						</FormControl.Feedback>
					</FormGroup>
				</div>

				<div className="modal-footer">
					<button
						type="button"
						onClick={this.props.handleClose}
						className="btn btn-outline-primary"
					>
						Cancelar
					</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.groupsReducer,
		...reducers.employeesReducer,
	};
};

export default connect(mapStateToProps, groupsActions)(GroupSupervisorEdit);
