import React from "react";
import { PLAN_GRID_VIEWS } from "components/Plans";
import PlanTableHead from "components/Plans/PlanTableHead";
import PlanTableBody from "components/Plans/PlanTableBody";
import PlanTableHeadInverted from "components/Plans/PlanTableHeadInverted";
import PlanTableBodyInverted from "components/Plans/PlanTableBodyInverted";

const PlanTable = (props) => {
	// console.log(props);

	var tableClass = "";
	if (props.plan_grid_view === PLAN_GRID_VIEWS.ubications) {
		tableClass = "table-ubications"
	}

	if (props.plan_assignments.length === 0) return "";

	if (props.plan_grid_view === PLAN_GRID_VIEWS.vertical) {
		return (
			<table id="tablePlan" className={`table table-bordered table-plan table-plan-main table-plan-vertical`}>
				<PlanTableHeadInverted {...props} />
				<PlanTableBodyInverted {...props} />
			</table>
		);
	}

	return (
		<table id="tablePlan" className={`table table-bordered table-plan table-plan-main ${tableClass}`}>
			<PlanTableHead {...props} />
			<PlanTableBody {...props} />
		</table>
	);
};

export default PlanTable;
