import React, { Component, createRef } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import AvatarEditor from 'react-avatar-editor';
import * as userActions from 'actions/userActions';
import Dropzone from 'react-dropzone';
import { employeesService } from "services";
import './Avatar.scss';

class AvatarEdit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			image: '',
			hasImage: false,
			allowZoomOut: false,
			position: { x: 0, y: 0 },
			scale: 1,
			rotate: 0,
			width: 220,
			height: 220,
			user: {},
		}
	}

	async componentDidMount() {
		const user = await employeesService.getById(this.props.userId);
		this.setState({
			user
		}, () => {
			this.getAvatar();
		});
	}

	// Abrir o crear la base de datos
	openDatabase() {
		return new Promise((resolve, reject) => {
			const request = indexedDB.open('avatarsDB', 1);

			request.onupgradeneeded = (event) => {
				const db = event.target.result;
				db.createObjectStore('avatars', { keyPath: 'userId' });
			};

			request.onsuccess = (event) => {
				resolve(event.target.result);
			};

			request.onerror = (event) => {
				reject(event.target.errorCode);
			};
		});
	}

	// Obtener avatar de IndexedDB
	getAvatarFromDB(userId) {
		// console.log(userId);
		return new Promise(async (resolve, reject) => {
			// Validar si userId es válido antes de proceder
			if (!userId) {
				resolve(null);  // Devuelve null o un valor por defecto si userId no es válido
				return;
			}

			try {
				const db = await this.openDatabase();
				const transaction = db.transaction(['avatars'], 'readonly');
				const store = transaction.objectStore('avatars');
				const request = store.get(userId);

				request.onsuccess = () => {
					resolve(request.result ? request.result.img : null);
				};

				request.onerror = (event) => {
					reject(`Error al intentar recuperar el avatar: ${event.target.errorCode}`);
				};
			} catch (error) {
				reject(`Error inesperado al intentar recuperar el avatar: ${error.message}`);
			}
		});
	}

	// Guardar avatar en IndexedDB
	saveAvatarToDB(userId, img) {
		return new Promise(async (resolve, reject) => {
			const db = await this.openDatabase();
			const transaction = db.transaction(['avatars'], 'readwrite');
			const store = transaction.objectStore('avatars');
			const request = store.put({ userId, img });

			request.onsuccess = () => {
				resolve();
			};

			request.onerror = (event) => {
				reject(event.target.errorCode);
			};
		});
	}

	async getAvatar() {
		const storageData = await this.getAvatarFromDB(this.props.userId);
		let img = "";

		if (storageData !== null) {
			img = storageData;
		} else {
			img = await employeesService.getAvatar(this.props.userId);
			await this.saveAvatarToDB(this.props.userId, img);
		}

		this.setState({
			image: img,
			hasImage: (this.state.user.avatar !== undefined && this.state.user.avatar !== null)
		});
	}

	handleDrop = dropped => {
		this.setState({
			image: dropped[0],
			hasImage: true,
			scale: 1
		});
	}

	handlePositionChange = position => {
		this.setState({ position });
	}

	handleScale = e => {
		const scale = parseFloat(e.target.value);
		this.setState({ scale });
	}

	async updateUser() {
		// if its ME
		if (this.state.user.id === this.props.userId)
			await this.props.update();
	}

	onClickSave = async () => {
		if (this.editor) {
			const canvasScaled = this.editor.getImageScaledToCanvas();
			const dataURL = canvasScaled.toDataURL();

			// save avatar
			const response = await employeesService.saveAvatar(this.props.userId, dataURL.replace("data:image/png;base64,", ""));

			if (response.status === 201) {
				await this.removeAvatarFromDB(this.props.userId);
				this.updateUser();
				toastr.success('¡Bien!', 'Cambios guardados correctamente');
				this.props.onClose();
			}
		}
	}

	onClickDelete = async () => {
		// delete avatar
		await employeesService.deleteAvatar(this.props.userId);
		const { user } = this.state;
		this.setState({
			hasImage: false,
			scale: 1,
			user: {
				...user,
				avatar: null
			}
		}, () => {
			this.updateUser();
			this.getAvatar();
		});
	}

	removeAvatarFromDB(userId) {
		return new Promise(async (resolve, reject) => {
			const db = await this.openDatabase();
			const transaction = db.transaction(['avatars'], 'readwrite');
			const store = transaction.objectStore('avatars');
			const request = store.delete(userId);

			request.onsuccess = () => {
				resolve();
			};

			request.onerror = (event) => {
				reject(event.target.errorCode);
			};
		});
	}

	setEditorRef = (editor) => this.editor = editor;

	render() {
		const dropzoneRef = createRef();
		const openDialog = () => {
			if (dropzoneRef.current) {
				dropzoneRef.current.open();
			}
		};

		return (
			<div className="avatar-edit">
				<Dropzone
					onDrop={this.handleDrop}
					ref={dropzoneRef}
					multiple={false}
					style={{ width: '220px', height: '220px' }}
				>
					{({ getRootProps, getInputProps }) => (
						<div {...getRootProps()}>
							<input {...getInputProps()} />
							<AvatarEditor
								image={this.state.image}
								ref={this.setEditorRef}
								width={this.state.width}
								height={this.state.height}
								border={0}
								borderRadius={this.state.width / 2}
								color={[255, 255, 255, 0.5]} // RGBA
								scale={parseFloat(this.state.scale)}
								rotate={parseFloat(this.state.rotate)}
								position={this.state.position}
								onPositionChange={this.handlePositionChange}
							/>
						</div>
					)}
				</Dropzone>

				{this.state.hasImage ? (
					<>
						<div className="avatar-zoom">
							<label>Zoom:</label>
							<input
								name="scale"
								type="range"
								onChange={this.handleScale}
								min={this.state.allowZoomOut ? '0.1' : '1'}
								max="2"
								step="0.01"
								defaultValue="1"
							/>
						</div>
						<button type="button" className="btn btn-primary" onClick={this.onClickSave}>Guardar foto</button>
						<button type="button" className="btn btn-link-danger" onClick={this.onClickDelete}>Eliminar foto</button>
					</>
				) :
					(
						<button type="button" className="btn btn-primary" onClick={openDialog}>Subir foto</button>
					)
				}
			</div>
		);
	}
}

const mapStateToProps = (reducers) => {
	return reducers.authReducer;
};

export default connect(mapStateToProps, userActions)(AvatarEdit);
