import React, { Component } from "react";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as userActions from 'actions/userActions';
import { appService } from "services";
import SubmitButton from "components/SubmitButton";
import Loading from "components/Loading";
import "./styles/Auth.scss";
import logo from 'assets/logo.svg';

class NewPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
		isAvailable: true, // if remember password is available
		isLoading: true, // while validating invitation
		isSending: false, // sending new password
   		isChanged: false, // if new passord has been set
      	password_new: '',
     	confirmPassword: '',
    };
  }

  async componentDidMount(){
    const response = await appService.getPasswordToken(this.props.match.params.token);

	this.setState({
		isAvailable: response.ok,
		isLoading: false
	});
  }

  validateForm() {
  	const { password_new, confirmPassword } = this.state;
    return (
      password_new.length > 5 &&
      password_new === confirmPassword
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isSending: true });
    const response = await appService.newPassword(this.props.match.params.token, this.state.password_new);

	this.setState({
		password_new: '',
		confirmPassword: '',
		isChanged: response.ok,
		isSending: false,
	});
  }

	getContent = () => {
		// console.log(this.props);
		if (this.state.isLoading){
			return <Loading />;
		}

		if (!this.state.isAvailable){
			return (
	    		<div className="notfound container-sm">
		            <h3>:(</h3>
		          	<p>Ha pasado demasiado tiempo desde que solicitaste la contraseña y el token ya no es válido. Deberás solicitar la contraseña nuevamente.</p>
				</div>
				);
		}

		const { password_new, confirmPassword } = this.state;

		return(

			<form onSubmit={this.handleSubmit}>
				<p className="hello">Escribe tu nueva contraseña de al menos 6 carácteres a continuación:</p>

				<FormGroup controlId="password_new">
					<FormLabel>Contraseña <span className="label-required">*</span></FormLabel>
					<FormControl
					value={password_new}
					onChange={this.handleChange}
					type="password"
					/>				
				</FormGroup>
				<FormGroup controlId="confirmPassword">
					<FormLabel>Confirmar contraseña <span className="label-required">*</span></FormLabel>
					<FormControl
					value={confirmPassword}
					onChange={this.handleChange}
					type="password"
					/>				
				</FormGroup>
				<SubmitButton
					block
					disabled={!this.validateForm()}
					type="submit"
					isLoading={this.state.isSending}
					text="Enviar"
					loadingText="Actualizando..."
				/>

	            { this.state.isChanged && 
	              <div className="alert alert-success alert-dismissible fade show" role="alert">
	                Se ha cambiado la contraseña correctamente. Ya puedes <Link to="/login">iniciar sesión</Link>
	              </div>
	            }

			</form>
		);
	}    

  render() {

	return (
		<div className="Auth page-invitation">
			<Link to="/">
		  		<img className="logo" src={logo} alt="Plain" />
		  	</Link>

		  	{ this.getContent() }

		</div>
	);
  }
}

const mapStateToProps = (reducers) => {
	return reducers.authReducer;
};

export default connect(mapStateToProps, userActions)(NewPassword);