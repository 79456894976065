import React, {Component} from 'react';
import "./styles/Counter.scss";

class CounterStatic extends Component {

	componentDidMount() {
		let { hours, minutes, seconds } = this.props;
		if (hours === undefined)
			hours = 0;

		if (minutes === undefined)
			minutes = 0;

		if (minutes === 0)
			minutes='00'

		if (seconds === 0)
			seconds='00'			
	}	

	render() {
		const { hours, minutes, seconds } = this.props;

		return (
			<div className="counter">
				{hours !== undefined && (
					<div className='counter-item'>							
						{hours} 
						<span>h</span>
					</div>
				)}
				{minutes !== undefined && (
					<div className='counter-item'>
						{minutes} 
						<span>m</span>
					</div>
				)}
				{seconds !== undefined && seconds !== null && (
					<div className='counter-item counter-seconds'>
						{seconds} 
						<span>s</span>
					</div>
				)}
			</div>
		);
	}
}

export default CounterStatic;
