import React, { Component } from "react";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import moment from "moment";
// import { errorFormating } from "utils/utils";
import {
	FormGroup,
	FormControl,
	Dropdown,
	ButtonGroup,
	Button, OverlayTrigger, Tooltip, Nav, Tab
} from "react-bootstrap";
import {
	FiSearch,
	FiCalendar,
	FiMoreHorizontal,
	FiSettings,
	FiUpload,
	FiX,
	FiTrello,
	FiUnlock,
	FiFilter,
	FiUsers
} from "react-icons/fi";
import { PiSmileySadBold } from "react-icons/pi";
import { AiOutlineFunction } from "react-icons/ai";
import { BiGridVertical, BiSortAlt2 } from "react-icons/bi";
import { FaClock, FaTh, FaMapMarkerAlt, FaSearchPlus } from "react-icons/fa";
import { TbArrowsMaximize, TbArrowsMinimize } from "react-icons/tb";
import { FaMagic } from "react-icons/fa";
import Autosuggest from "react-autosuggest";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

import { showModal, hideModal, updateModalLoading } from "actions/modalActions";
import Layout from "components/Layout";
import Loading from "components/Loading";
import PlanShiftEditModal from "components/Plans/PlanShiftEditModal";
import EmployeeOrderModal from "components/Employees/EmployeeOrderModal";
import StaffingLevelEditModal from "components/Plans/StaffingLevelEditModal";
import PlanTable from "components/Plans/PlanTable";
import PlanStaffingLevels from "components/Plans/PlanStaffingLevels";
import PlanHourlyCounter from "components/Plans/PlanHourlyCounter";
import PlanStaffingHourly from "components/Plans/PlanStaffingHourly";
import PlanCounters from "components/Plans/PlanCounters";
import PlanMagicModal from 'components/Plans/PlanMagicModal';
import ShiftPreviewTooltip from 'components/Shifts/ShiftPreviewTooltip';
import GroupStaffingLevelsEdit from "components/Groups/GroupStaffingLevelEditModal";

import { ShiftContextMenu, DayContextMenu, StaffingContextMenu } from "components/Plans/PlanMenus";
import { isAdmin, isSupervisor } from "utils/utils";
import * as plansActions from "actions/plansActions";
import * as groupsActions from "actions/groupsActions";
import * as shiftsActions from "actions/shiftsActions";
import * as rulesActions from "actions/rulesActions";
import * as functionsActions from "actions/functionsActions";
import * as timesActions from "actions/timesActions";
import * as employeesActions from "actions/employeesActions";
import * as ubicationsActions from "actions/ubicationsActions";
import {
	plansService,
	plansSocketService,
	groupsService,
	accountsService,
	appService,
} from "services";
import ResizablePanels from "components/ResizablePanels";
import Modal from "components/Modal";
import { PLAN_GRID_VIEWS } from "components/Plans";
import { getContrastColor } from "utils/utils";

var scrollPlanLeft = 0;
var scrollPlanTop = 0;

class Plan extends Component {
	constructor(props) {
		super(props);
		// console.log(props);

		this.closeModal = this.closeModal.bind(this);
		this.openExportValoresCuentasModal = this.openExportValoresCuentasModal.bind(this);
		this.openExportPlanificacionModal = this.openExportPlanificacionModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
		this.openNewMagicPlan = this.openNewMagicPlan.bind(this);
		this.openEmployeesOrderModal = this.openEmployeesOrderModal.bind(this);

		this.getAllAssignments = this.getAllAssignments.bind(this);
		this.getPlanAssignments = this.getPlanAssignments.bind(this);
		this.getAccountAssignments = this.getAccountAssignments.bind(this);
		this.getStaffingLevelAssignments = this.getStaffingLevelAssignments.bind(this);
		this.changeMonthHeader = this.changeMonthHeader.bind(this);
		this.handleDaySelection = this.handleDaySelection.bind(this);
		this.selectSingleDay = this.selectSingleDay.bind(this);
		this.handleNeedSelection = this.handleNeedSelection.bind(this);
		this.handleDayMenuClick = this.handleDayMenuClick.bind(this);
		this.handleShiftMenuClick = this.handleShiftMenuClick.bind(this);
		this.handleEditShift = this.handleEditShift.bind(this);
		this.handleCopyShift = this.handleCopyShift.bind(this);
		this.handleStaffingMenuClick = this.handleStaffingMenuClick.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.updatePlanScroll = this.updatePlanScroll.bind(this);
		this.openNewStaffingLevelModal = this.openNewStaffingLevelModal.bind(this);

		this.refScrollPlan = React.createRef();
		this.refScrollCounter = React.createRef();
		this.refResizablePanels = React.createRef();

		const group_id = parseInt(this.props.match.params.group);
		const groups_supervised = this.props.user.groups_supervised;
		const isSupervisorWithSuperpowers = isSupervisor() && groups_supervised.length > 0 && groups_supervised.some((gs) => gs.id === parseInt(group_id) && !gs.read_only);
		const isSupervisorReadOnly = isSupervisor() && groups_supervised.length > 0 && groups_supervised.some((gs) => gs.id === parseInt(group_id) && gs.read_only);
		const hasPrivileges = isSupervisorWithSuperpowers || isAdmin();
		// console.log(isSupervisorReadOnly);
		// console.log(this.props.user);
		// console.log(groups_supervised);
		// console.log({ isSupervisorWithSuperpowers });

		this.state = {
			loading: true, // first loading
			isLocked: false,
			isPlanKO: false,
			hasPrivileges,
			isSupervisorReadOnly,
			search_shift: "",
			search_staffing_shift: "",
			search_employee: "",
			search_ubication: "",
			plan_view_id: -1,
			function_id: -1,
			ubication_id: -1,
			ubication_staffing_id: -1,
			function_staffing_id: -1,
			name: "", // staffing level name (filter)
			group_id,
			start: this.props.match.params.start,
			end: this.props.match.params.end,
			shift_list: props.shifts || [],
			ubication_list: props.select_ubications || [], // ubication list drag & drop
			select_ubications: props.select_ubications || [], // ubication option list
			copied_shift: "",
			day_selection: [],
			day_selection_copy: [],
			day_selection_dashed: [],
			needs_selection: [],
			plan_assignments: [],
			plan_no_results: false,
			accounts_loading: true,
			plan_loading: true,
			staffing_loading: hasPrivileges ? true : false, // solo carga para admin/super
			staffing_hourly_loading: true,
			staffing_level_hourly_assignments: [],
			isExtendedView: false,
			employee_suggestions: [],
			needs_suggestions: [],
			staffing_level_current_assignments: [],
			no_contract: false,
			showStaffingFilter: false,
			// plan_grid_view: PLAN_GRID_VIEWS.plan
		};
	}

	async componentDidMount() {
		const { group_id, start, end, hasPrivileges } = this.state;

		plansSocketService.connect({ group_id, hasPrivileges, user: this.props.user, start, end });

		const group = await groupsService.getById(group_id);

		// Ejecutar llamadas asíncronas en paralelo cuando sea posible
		await Promise.all([
			this.props.getDefaultPlanView(),
			this.props.getWorkingHours(),
			this.getUbications(),
			this.props.getDefaultShift(),
			this.props.getShiftTypes(),
			this.props.getSelectableStaffingLevels(),
			this.props.getSelectableFunctions(),
			this.props.getSelectableEmployees({ group_id }),
			this.getShifts(),
			this.props.getPlanViews(),
			this.props.getSelectableTimeTypes(),
			this.props.getAttendanceShifts()
		]);

		// plan days
		this.props.getPlanDays(group_id, start, end);

		//working hours
		const working_hours_list = this._getWorkingHoursList();

		// recoge grid view del localstorage
		const storedGridView = localStorage.getItem("plan_grid_view");
		// recoge showMax del localstorage
		const storedMaxPlan = localStorage.getItem("max_plan");

		this.setShowMax(storedMaxPlan);

		this.setState({
			plan_view_id: this.props.plan_view_default.id || -1,
			group,
			working_hours_list,
			select_ubications: this.props.select_ubications,
			plan_grid_view: parseInt(storedGridView) || PLAN_GRID_VIEWS.plan,
		});

		// asignments
		this.getPlanCalculations();

		// suscribe to open plan
		plansSocketService.sendMessage({ group_id, start, end });
	}

	componentWillUnmount() {
		plansSocketService.disconnect();
	}


	_getWorkingHoursList() {
		const { working_hours } = this.props;
		// console.log(working_hours);
		const format = 'H';
		const start = parseInt(moment(working_hours.start, 'HH:mm').format(format));
		var end = parseInt(moment(working_hours.end, 'HH:mm').format(format));
		const end_minutes = parseInt(moment(working_hours.end, 'HH:mm').format("mm"));

		// Si hay minutos en el tiempo de finalización > 0, suma una hora más
		if (end_minutes > 0) {
			end += 1;
		}

		// Ajustar para turnos que terminan al día siguiente
		let length = end - start;
		if (length <= 0) {
			length += 24; // Asume un ciclo completo de 24 horas si el turno termina al día siguiente
		}

		// console.log(end_minutes);
		// console.log(end);
		return new Array(length).fill().map((d, i) => (i + start) % 24);
	}

	async getPlanCalculations() {
		const { group_id, start, end, plan_view_id, function_id, search_employee } =
			this.state;

		const response = await plansService.getPlanCalculations({
			group_id,
			start,
			end,
			plan_view_id,
			function_id,
			q: search_employee,
		});

		//console.log(response);
		// response.state = "ERROR";

		const plan_state = response.state ? response.state : "CLOSED";
		const isLocked = plan_state === "OPEN" ? true : false;
		const isPlanKO = plan_state === "ERROR" ? true : false;


		if (plan_state === "CLOSED") {
			this.getAllAssignments();
		}
		else if (plan_state === "ERROR") {
			this.setState({
				loading: false,
			});
			this.openAlertModal(
				"Ha ocurrido un error al cargar la planificación, por favor, inténtalo de nuevo más tarde.",
				"Error al cargar datos"
			);
		}
		else {
			setTimeout(() => this.getPlanCalculations(), 1500);
		}

		this.setState({
			isLocked,
			isPlanKO
		});
	}

	// get data
	async getAllAssignments() {
		await Promise.all([
			this.getPlanAssignments(),
			this.getAccountAssignments(),
			this.getStaffingLevelAssignments(),
		]);

		if (this.state.grid_view === PLAN_GRID_VIEWS.hours) {
			await this.getStaffingLevelHourlyAssignments()
		}

		this.updatePlanScroll()
	}

	// get counters
	async getAccountAssignments() {
		const {
			group_id,
			start,
			end,
			accounts_loading,
			plan_view_id,
			function_id,
			search_employee,
		} = this.state;

		if (!accounts_loading) {
			this.setState({
				accounts_loading: true,
			});
		}
		await this.props.getAccountAssignments({
			group_id,
			start,
			end,
			plan_view_id,
			function_id,
			q: search_employee,
		});
		// console.log(this.props.account_assignments)

		this.setState({
			accounts_loading: false,
		});
	}

	_overlapInterval = (time_intervals, hours) => {
		var overlaped_count = 0;
		var pinta = false;

		time_intervals.forEach((interval) => {
			var format = 'HH:mm'; // Usamos formato 24h para comparación directa

			// Ajustamos el intervalo de fin para manejar la medianoche correctamente
			var interval_end_adjusted = interval.end === "00:00" ? "24:00" : interval.end;

			// Convertimos los tiempos a objetos moment
			var interval_start = moment(interval.start, format);
			var interval_end = moment(interval_end_adjusted, format);
			var hour_start = moment(hours + ':00', format);
			var hour_end = moment(hours + ':59', format);

			// Verificar si el intervalo cruza la medianoche
			var crossesMidnight = interval_end.isBefore(interval_start) || interval_end_adjusted === "24:00";
			if (crossesMidnight) {
				// Si el intervalo termina a las 24:00, consideramos hasta ese momento sin añadir un día.
				if (interval_end_adjusted !== "24:00") {
					interval_end.add(1, 'day'); // Ajustamos para el día siguiente
				}
			}

			// Calculamos la superposición dentro de la hora específica
			var overlapStart = moment.max(hour_start, interval_start);
			var overlapEnd = moment.min(hour_end, interval_end);

			if (overlapStart.isBefore(overlapEnd)) {
				pinta = true;
				var overlapDuration = moment.duration(overlapEnd.diff(overlapStart));
				var minutesOverlap = overlapDuration.asMinutes();

				// Ajustamos temp_count basándonos en la duración total de la superposición
				if (minutesOverlap >= 59) { // Consideramos 59 minutos o más como una hora completa
					overlaped_count = 1;
				} else if (minutesOverlap >= 45) {
					overlaped_count = 0.75;
				} else if (minutesOverlap >= 30) {
					overlaped_count = 0.5;
				} else if (minutesOverlap >= 15) {
					overlaped_count = 0.25;
				} else if (minutesOverlap > 0) {
					overlaped_count = 0.25; // Consideramos cualquier superposición >0 como 1/4 por simplicidad
				}
			}
		});

		return { overlaped_count, pinta };
	};

	// get plan
	async getPlanAssignments() {
		const { group_id, start, end, function_id, ubication_id, search_employee, plan_loading, working_hours_list } = this.state;

		if (!plan_loading) {
			this.setState({
				plan_loading: true,
			});
		}
		const response = await plansService.getPlanAssignments({
			group_id,
			start,
			end,
			function_id,
			ubication_id,
			q: search_employee,
		});

		if (response.ok) {
			const plan_assignments = response.data;
			var plan_assignments_hourly_totals = [];


			if (plan_assignments && plan_assignments.length > 0) {
				// console.log(plan_assignments);
				plan_assignments.map((plan_assignment, i) => {
					plan_assignments_hourly_totals = [];
					// Verificar si la asignación tiene planes definidos, si no, inicializarlos.
					if (plan_assignment.plans === undefined) {
						plan_assignment.plans = this.props.plan_days.map(d => ({
							day: d.day,
							active: this.checkEmployeeActive(plan_assignment.ranges, d.day),
							assignments: [],
							assignments_with_overnight: [] // Inicializa el array para asignaciones que cruzan la medianoche
						}));
					} else {

						var horasExtrasPorDia = {};

						plan_assignment.plans.forEach((plan) => {
							plan.active = this.checkEmployeeActive(plan_assignment.ranges, plan.day);
							// Importante: Crear una nueva instancia del array para evitar la mutación directa
							plan.plan_assignments_with_overnight = [...plan.assignments] || [];


							// miramos si hay horas extras para el día del plan
							if (Object.keys(horasExtrasPorDia).length > 0) {
								if (horasExtrasPorDia[plan.day]) {
									// plan.assignments.push(...horasExtrasPorDia[plan.day]);
									plan.plan_assignments_with_overnight.push(...horasExtrasPorDia[plan.day]);
									delete horasExtrasPorDia[plan.day]; // Limpia las horas extras para el día actual después de añadirlas
								}
							}

							plan.assignments.forEach(assignment => {
								if (!assignment.time_intervals_overnight || assignment.time_intervals_overnight < 1) {
									let time_intervals = assignment.time_intervals_from_shift ? assignment.shift.time_intervals : assignment.time_intervals;

									time_intervals && time_intervals.forEach(interval => {
										const format = "HH:mm";
										let startTime = moment(interval.start, format);
										let endTime = moment(interval.end, format);


										// Verificar si el turno cruza la medianoche
										if (endTime.isBefore(startTime) && endTime.format(format) !== "00:00") {
											// Calculamos el día siguiente a partir del día del plan
											const nextDay = moment(plan.day, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
											const assignmentCopy = { ...assignment, time_intervals_overnight: [{ start: "00:00", end: endTime.format(format), time_type: interval.time_type }] };

											// Guardar en horasExtrasPorDia
											if (!horasExtrasPorDia[nextDay]) {
												horasExtrasPorDia[nextDay] = [];
											}

											horasExtrasPorDia[nextDay].push(assignmentCopy);
										}
									});
								}
							});

							// preparamos el array plan_assignments_hourly_totals para la vista por horas del día
							const temp_hours = [];

							working_hours_list && working_hours_list.map((hours, i) => {
								var hours_count = 0;
								var hour_assignments = [];
								var pinta = false;

								// plan.assignments.map((assignment, j) => {
								// usamos plan.plan_assignments_with_overnight para tener en cuenta las horas extras
								plan.plan_assignments_with_overnight.map((assignment, j) => {
									// console.log({ assignment });
									var shift = assignment.shift;
									var hours_count_temp = 0;
									var pinta_part = false;

									if (shift.type === "Trabajo" && plan.active) {
										var time_intervals = assignment.time_intervals_from_shift ? shift.time_intervals : assignment.time_intervals;
										if (assignment.time_intervals_overnight)
											time_intervals = assignment.time_intervals_overnight;

										if (time_intervals && time_intervals.length > 0) {
											var overlap_interval_temp = this._overlapInterval(time_intervals, hours);
											hours_count_temp = overlap_interval_temp.overlaped_count;
											pinta_part = overlap_interval_temp.pinta;
											hours_count += hours_count_temp;
										}
									}

									var hour_assignment = {
										count: hours_count_temp,
										assignment,
										pinta: pinta_part
									}
									hour_assignments.push(hour_assignment);

									if (pinta_part)
										pinta = true;

									return assignment;
								});

								var hour = {
									hour: hours,
									count: hours_count,
									hour_assignments,
									pinta
								}
								temp_hours.push(hour);
							});

							var hourly_total = {
								day: plan.day,
								hours: temp_hours,
							}
							plan.hours = temp_hours;
							plan_assignments_hourly_totals.push(hourly_total);
						});
					}
					plan_assignment.plan_assignments_hourly_totals = plan_assignments_hourly_totals;
					return true;
				});
			}
			// console.log(plan_assignments);

			this.setState({
				plan_assignments,
				plan_no_results: plan_assignments && plan_assignments.length === 0,
				plan_loading: false,
				loading: false, // first loading
			});
		}
		else {
			this.setState({
				plan_loading: false,
				loading: false, // first loading
				plan_assignments: [],
				plan_no_results: true,
				no_contract: (response.errors[0] && response.errors[0].key === "contract.not_found")
			}, () => {
				let textError = response.errors[0].description;

				this.openAlertModal(
					textError,
					"Error al cargar datos"
				);
			});
		}
	}

	setShowMax = (value) => {
		var sidebar = document.querySelector('.sidebar');
		var mainContent = document.querySelector('.main-content');

		if (value === true || value === "true") {
			sidebar.classList.add('mini');
			mainContent.classList.add('mini');
			value = true;
			if (this.refResizablePanels.current)
				this.refResizablePanels.current.forceResize([99, 1]);
		} else {
			sidebar.classList.remove('mini');
			mainContent.classList.remove('mini');
			value = false;
		}

		localStorage.setItem("max_plan", value);

		this.setState({
			showMax: value,
		});
	};

	toggleShowMax = () => {
		const { showMax } = this.state;
		this.setShowMax(!showMax);
	};

	toggleStaffingFilter = () => {
		const { showStaffingFilter } = this.state;
		this.setState({
			showStaffingFilter: !showStaffingFilter,
		});
	};

	checkEmployeeActive = (ranges, day) => {
		var active = false;

		if (ranges) {
			ranges.forEach((range) => {
				if (moment(day).isBetween(range.start, range.end, undefined, "[]"))
					active = true;
			});
		}

		return active;
	};

	async getStaffingLevelAssignments() {
		const { name, group_id, start, end, staffing_loading, function_id, ubication_id, ubication_staffing_id, function_staffing_id, hasPrivileges } =
			this.state;

		const filtro_ubicacion_id = ubication_staffing_id > 0 ? ubication_staffing_id : ubication_id;
		const filtro_funcion_id = function_staffing_id > 0 ? function_staffing_id : function_id;

		if (hasPrivileges) {
			if (!staffing_loading) {
				this.setState({
					staffing_loading: true,
				});
			}
			await this.props.getStaffingLevelAssignments({
				name,
				group_id,
				start,
				end,
				function_id: filtro_funcion_id,
				ubication_id: filtro_ubicacion_id,
			});

			var staffing_level_assignments = this.props.staffing_level_assignments;


			if (staffing_level_assignments.length === 0) {
				await this.props.getStaffingLevelCurrentAssignments({
					name,
					group_id,
					start,
					end,
					function_id: filtro_funcion_id,
					ubication_id: filtro_ubicacion_id,
				});
			}

			this.getFilteredStaffingLevelAssignments();
		}
	}

	sortShiftsByLocationAndStartTime(needs) {
		return needs.sort((a, b) => {
			const locationA = a.ubication?.name || "";
			const locationB = b.ubication?.name || "";

			// Ordenar por ubicación
			if (locationA !== locationB) {
				return locationA.localeCompare(locationB);
			}

			// Función para normalizar el tiempo según la lógica de "mañana" y "noche"
			const normalizeTime = (time) => {
				const [hours, minutes] = time.split(':').map(Number);
				// Si la hora es menor que 4, se considera como parte de la noche anterior
				return hours < 4 ? hours + 24 : hours;
			};

			// Verificar que ambos tengan time_intervals con al menos un intervalo
			if (!a.shift.time_intervals?.length || !b.shift.time_intervals?.length) {
				// Si uno de los elementos no tiene time_intervals, no se puede comparar, los ponemos al final
				return !a.shift.time_intervals?.length ? 1 : -1;
			}

			// Si la ubicación es la misma, ordenar por la hora de inicio
			const startA = normalizeTime(a.shift.time_intervals[0].start);
			const startB = normalizeTime(b.shift.time_intervals[0].start);

			if (startA !== startB) {
				return startA - startB;
			}

			// Si las horas son iguales, se comparan los minutos
			const minutesA = parseInt(a.shift.time_intervals[0].start.split(':')[1], 10);
			const minutesB = parseInt(b.shift.time_intervals[0].start.split(':')[1], 10);
			return minutesA - minutesB;
		});
	}


	// get staffing levels assignments
	getFilteredStaffingLevelAssignments() {
		const { staffing_loading, function_id, ubication_id, ubication_staffing_id, function_staffing_id, name, search_staffing_shift } =
			this.state;

		const filtro_ubicacion_id = ubication_staffing_id > 0 ? ubication_staffing_id : ubication_id;
		const filtro_funcion_id = function_staffing_id > 0 ? function_staffing_id : function_id;

		if (!staffing_loading) {
			this.setState({
				staffing_loading: true,
			});
		}

		var staffing_level_assignments = this.props.staffing_level_assignments;
		// console.log({ staffing_level_assignments });

		// filtrar por nombre de necesidad de personal (con sugerencias)
		if (name && name.length > 0) {
			staffing_level_assignments = staffing_level_assignments ? staffing_level_assignments.filter((o) => o.name.toLowerCase().includes(name.toLowerCase())) : [];
		}

		// filtrar por nombre de turno
		if (search_staffing_shift && search_staffing_shift.length > 0) {
			staffing_level_assignments = staffing_level_assignments ? staffing_level_assignments.filter((o) => o.shift && o.shift.name.toLowerCase().includes(search_staffing_shift.toLowerCase())) : [];
		}

		// filtrar por ubicación 
		if (parseInt(filtro_ubicacion_id) > 0) {
			staffing_level_assignments = staffing_level_assignments ? staffing_level_assignments.filter((o) => o.ubication && o.ubication.id === parseInt(filtro_ubicacion_id)) : [];
		}

		// filtrar por función
		if (parseInt(filtro_funcion_id) > 0) {
			staffing_level_assignments = staffing_level_assignments ? staffing_level_assignments.filter((o) => o.function && o.function.id === parseInt(filtro_funcion_id)) : [];
		}

		if (staffing_level_assignments.length === 0) {
			var staffing_level_current_assignments = this.props.staffing_level_current_assignments;

			if (staffing_level_current_assignments.length > 0) {
				if (parseInt(filtro_ubicacion_id) > 0) {
					staffing_level_current_assignments = staffing_level_current_assignments ? staffing_level_current_assignments.filter((o) => (o.ubication && o.ubication.id === parseInt(filtro_ubicacion_id))) : [];
				}

				if (parseInt(filtro_funcion_id) > 0) {
					staffing_level_current_assignments = staffing_level_current_assignments ? staffing_level_current_assignments.filter((o) => (o.function && o.function.id === parseInt(filtro_funcion_id))) : [];
				}
			}

			this.setState({
				staffing_loading: false,
				staffing_level_assignments: [],
				staffing_level_current_assignments: this.sortShiftsByLocationAndStartTime(staffing_level_current_assignments),
				needs_selection: [] // reset selection
			});

		} else {
			this.setState({
				staffing_loading: false,
				staffing_level_assignments: this.sortShiftsByLocationAndStartTime(staffing_level_assignments),
				staffing_level_current_assignments: [],
				needs_selection: [] // reset selection
			});
		}
	}

	// get staffing levels HOURLY assignments
	async getStaffingLevelHourlyAssignments() {
		// console.log("carga getStaffingLevelHourlyAssignments");

		const { group_id, start, end, staffing_hourly_loading, function_id, ubication_id, hasPrivileges } =
			this.state;
		const { working_hours } = this.props;

		// const format = 'HH:mm';
		// var start_hour = moment(working_hours.start).format(format);
		// var end_hour = moment(working_hours.end).format(format);
		var start_hour = working_hours.start;
		var end_hour = working_hours.end;
		// console.log({ working_hours });
		// console.log({ start_hour });

		start_hour = start_hour.substring(0, 3) + "00"; // fix guarro perque sigui en punt

		if (hasPrivileges) {
			if (!staffing_hourly_loading) {
				this.setState({
					staffing_hourly_loading: true,
				});
			}
			await this.props.getStaffingLevelHourlyAssignments({
				group_id,
				start,
				end,
				function_id,
				ubication_id,
				start_hour,
				end_hour,
			});

			var staffing_level_hourly_assignments = this.props.staffing_level_hourly_assignments;

			this.setState({
				staffing_hourly_loading: false,
				staffing_level_hourly_assignments,
			});
		}
	}

	closeModal() {
		this.props.hideModal();
	}

	// edit shift modal
	openEditPlanShiftModal(plan) {
		// console.log(plan);
		const shift_name = plan.shift_plan.shift.name;

		this.props.showModal(
			{
				open: true,
				title: `Editar turno ${shift_name}`,
				style: { width: "550px" },
				content: (
					<PlanShiftEditModal
						plan={plan}
						getAllAssignments={this.getAllAssignments}
						handleClose={this.closeModal}
					/>
				),
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	// edit staffing level modal
	openEditStaffingLevelModal(options) {
		this.props.showModal(
			{
				open: true,
				title: `Editar necesidades de personal`,
				style: { width: "850px" },
				content: (
					<StaffingLevelEditModal
						options={options}
						getAllAssignments={this.getAllAssignments}
						handleClose={this.closeModal}
					/>
				),
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	// sort employees modal
	openEmployeesOrderModal(group) {
		this.props.showModal({
			open: true,
			title: 'Ordenar empleados',
			style: { width: '450px' },
			content: <EmployeeOrderModal
				group={group}
				getPlanAssignments={this.getPlanAssignments}
				getAccountAssignments={this.getAccountAssignments}
				handleClose={this.closeModal}
			/>,
			closeModal: this.closeModal
		}, 'edit')
	}


	// Plan view normal / timetables
	// plan: 1,
	// times: 2,
	// ubications: 3,
	// hours: 4,
	// combo: 5
	// vertical: 6
	handleChangeView = async (grid_view) => {
		localStorage.setItem("plan_grid_view", grid_view);

		if (grid_view === PLAN_GRID_VIEWS.hours && this.state.staffing_level_hourly_assignments.length === 0) {
			await this.getStaffingLevelHourlyAssignments();
		}

		this.setState({
			isExtendedView: grid_view == 2,
			plan_grid_view: grid_view
		});
	};

	// change plan view -> refresh counters or filter by function
	handleChangeSearch = (event) => {
		const target = event.target.id;
		const value = event.target.value;

		this.setState((prevState) => {
			// Crear un nuevo estado basado en la condición del target
			const newState = { [target]: value };

			if (target === "ubication_id") {
				newState["ubication_staffing_id"] = value;
			}

			if (target === "function_id") {
				newState["function_staffing_id"] = value;
			}

			return newState;
		},
			async () => {
				// console.log(target);

				if (target === "ubication_staffing_id" || target === "function_staffing_id" || target === "search_staffing_shift") {
					// filtro por front
					this.getFilteredStaffingLevelAssignments();
				}
				else if (target === "name") {
					// nada
				}
				else {
					if (target === "function_id" || target === "ubication_id") {
						await this.getPlanAssignments();
						await this.getStaffingLevelAssignments();
					}

					await this.getAccountAssignments();
				}
			}
		);
	};

	onClearStaffingShiftSearch = () => {
		this.setState({
			search_staffing_shift: "",
		}, () => {
			this.getFilteredStaffingLevelAssignments();
		});
	}

	autoPlan = async () => {
		const { group_id, start, end } = this.state;

		if (group_id && start && end) {
			await plansService.setAssignments({ group_id, start, end });
			this.getPlanCalculations();
		}
	};

	openAlertModal(message, title) {
		this.props.showModal(
			{
				open: true,
				title: title || "Error al eliminar",
				style: { width: "400px" },
				message: message,
				closeModal: this.closeModal,
			},
			"alert"
		);
	}

	// seleccionar días

	handleDaySelection = (row, index, event) => {
		const { plan_assignments } = this.state;
		var { day_selection } = this.state;
		const target = event.currentTarget;
		var value = target.checked;

		if (target.classList.contains("shift-item")) {
			if (target.closest(".shift-cell").classList.contains("active"))
				value = false;
			else value = true;
		} else {
			value = event.target.checked;
		}

		plan_assignments[row].plans[index].checked = value;

		if (value) {
			const newSelection = {
				row,
				index,
			};

			day_selection.push(newSelection);
		} else {
			// remove day from list
			day_selection = day_selection.filter(
				(o) => o.row !== row && o.index !== index
			);
		}

		this.setState({
			plan_assignments,
			day_selection,
		});
	};

	selectSingleDay = (row, index) => {
		const { plan_assignments, day_selection } = this.state;
		const newSelection = {
			row,
			index,
		};

		// check if exists
		const exists = day_selection.find(item => item.row === row && item.index === index);

		if (!exists) {
			day_selection.push(newSelection);

			plan_assignments[row].plans[index].checked = true;

			this.setState({
				plan_assignments,
				day_selection,
			});
		}
	};

	// seleccionar necesidades de personal (celdas)

	handleNeedSelection = (row, index, event) => {
		const { staffing_level_assignments, group } = this.state;
		var { needs_selection } = this.state;
		const value = event ? event.target.checked : true;

		// validar que siguin de la mateixa fila
		var isValid = true;

		var selectedAssignment = staffing_level_assignments[row].counters[index];
		// console.log(staffing_level_assignments);
		// console.log(staffing_level_assignments[row]);
		// console.log(selectedAssignment);

		if (value) {
			const newSelection = {
				row,
				index,
				day: selectedAssignment.day,
				needed: selectedAssignment.overwrite ? selectedAssignment.overwrite : selectedAssignment.needed,
				current: selectedAssignment.current,
				overwrite: selectedAssignment.overwrite,
				function_id: selectedAssignment.function.id,
				group_id: group.id, // selected group in plan
				shift_id: selectedAssignment.shift.id,
			};

			if (selectedAssignment.ubication) {
				newSelection.ubication_id = selectedAssignment.ubication.id
			}

			if (needs_selection.length > 0) {
				const temp = needs_selection.filter(
					(o) => o.row !== row
				);

				// si hay selecciones de otra fila
				if (temp.length > 0) {
					isValid = false;
				}
			}

			const exists = needs_selection.find(
				(o) => o.row === row && o.index === index
			);

			if (isValid && !exists)
				needs_selection.push(newSelection);

		} else {
			// remove need from list
			needs_selection = needs_selection.filter(
				(o) => o.index !== index
			);
		}

		if (isValid) {
			staffing_level_assignments[row].counters[index].checked = value;

			this.setState({
				staffing_level_assignments,
				needs_selection,
			});
		}
		else {
			this.openAlertModal(
				"Solo se pueden seleccionar múltiples celdas de una misma fila de función de empleado",
				"Error de selección"
			);
		}
	};

	// shifts stuff
	async getShifts() {
		await this.props.getAllShifts({
			q: this.state.search_shift,
			shift_type: this.state.search_shift_type_id,
			group_id: this.state.group_id
		});

		const shift_list = this.props.shifts;

		this.setState({
			shift_list,
		});
	}

	handleChangeFilter = event => {
		const target = event.target.id;
		// console.log(target);

		this.setState({
			[target]: event.target.value
		}, () => {
			if (target === "search_ubication") {
				this.getUbications();
			} else {
				this.getShifts();

			}
		});
	}

	handleShiftSelection = (event) => {
		const { shift_list } = this.state;
		const countSelected = shift_list.filter((o) => o.checked === true).length;
		var id = event.target.id;
		id = id.replace("shift_", "");
		const value = event.target.checked;

		if (countSelected >= 3 && value) {
			toastr.error("", "Puedes seleccionar un máximo de 3 turnos por jornada");
		} else {
			this.setState({
				shift_list: this.state.shift_list.map((o) =>
					o.id === parseInt(id) ? { ...o, checked: value } : o
				),
			});
		}
	};

	handleShiftDragSelection = (event) => {
		const { shift_list } = this.state;
		const countSelected = shift_list.filter((o) => o.checked === true).length;
		var id = event.target.id;
		id = id.replace("shift_li_", "");

		if (countSelected > 3) {
			toastr.error("", "Puedes seleccionar un máximo de 3 turnos por jornada");
		} else {
			this.setState({
				shift_list: this.state.shift_list.map((o) =>
					o.id === parseInt(id) ? { ...o, checked: true } : o
				),
			});
		}
	};

	// shift list

	getShiftList = () =>
		this.state.shift_list.map((item) => {
			const checked = item.checked || false;

			const tooltip = <Tooltip className="tooltip-shift"><ShiftPreviewTooltip shift={item} time_intervals={item.time_intervals} /></Tooltip>;

			return (
				<OverlayTrigger key={item.id} overlay={tooltip}>
					<li

						id={`shift_li_${item.id}`}
						title={`${item.name} - ${item.description}`}
						draggable
						onDragStart={(e) => this.handleShiftDragSelection(e)}
						className={`shift-item btn-checkbox ${checked ? "active" : ""}`}
						style={{
							backgroundColor: item.background_color && item.background_color,
							borderColor: item.background_color && item.background_color,
							color: getContrastColor(item.background_color, "#55536b"),
						}}
					>
						<input
							type="checkbox"
							id={`shift_${item.id}`}
							onChange={this.handleShiftSelection}
							checked={checked}
						/>
						<label htmlFor={`shift_${item.id}`} className="btn-check-label">
							{item.name}
						</label>
					</li>
				</OverlayTrigger>
			);
		});

	getShiftFilter = () => {
		const { shift_types } = this.props;
		const optionShiftTypeList =
			shift_types.length > 0 &&
			shift_types.map((item, i) => {
				return (
					<option key={i} value={item.name}>
						{item.name}
					</option>
				);
			}, this);

		return (
			<div className="pattern-filter">
				<div className="heading-filters">
					<FormGroup className="form-search" controlId="search_shift">
						<FormControl
							type="text"
							placeholder="Buscar..."
							value={this.state.search_shift}
							onChange={this.handleChangeFilter}
						/>
						<FiSearch />
					</FormGroup>
					<FormGroup className="form-search" controlId="search_shift_type_id">
						<FormControl
							type="text"
							placeholder="Buscar..."
							value={this.state.search_shift_type_id}
							onChange={this.handleChangeFilter}
							as="select"
						>
							<option value="">Filtrar por tipo</option>
							{optionShiftTypeList}
						</FormControl>
					</FormGroup>
				</div>

				<ul className="pattern-list">{this.getShiftList()}</ul>
			</div>
		);
	};

	// ubication list stuff

	async getUbications() {
		await this.props.getSelectableUbications({
			q: this.state.search_ubication
		});

		const ubication_list = this.props.select_ubications;

		this.setState({
			ubication_list,
		});
	}

	getUbicationList = () =>
		this.state.ubication_list.map((item) => {
			const checked = item.checked || false;
			return (
				<li
					key={item.id}
					id={`ubication_li_${item.id}`}
					title={`${item.name} - ${item.description}`}
					draggable
					onDragStart={(e) => this.handleUbicationDragSelection(e)}
					className={`shift-item btn-checkbox ${checked ? "active" : ""}`}
					style={{
						backgroundColor: item.background_color && item.background_color,
						borderColor: item.background_color && item.background_color,
						color: getContrastColor(item.background_color, "#55536b"),
					}}
				>
					<input
						type="checkbox"
						id={`ubication_${item.id}`}
						onChange={this.handleUbicationSelection}
						checked={checked}
					/>
					<label htmlFor={`ubication_${item.id}`} className="btn-check-label">
						{item.name}
					</label>
				</li>
			);
		});

	getUbicationFilter = () => {
		return (
			<div className="pattern-filter">
				<div className="heading-filters">
					<FormGroup className="form-search" controlId="search_ubication">
						<FormControl
							type="text"
							placeholder="Buscar..."
							value={this.state.search_ubication}
							onChange={this.handleChangeFilter}
						/>
						<FiSearch />
					</FormGroup>
				</div>

				<ul className="pattern-list">{this.getUbicationList()}</ul>
			</div>
		);
	};

	getComboFilter = () => {
		return (
			<div className="combo-filter" >
				<Tab.Container defaultActiveKey="shifts">
					<Nav variant="tabs">
						<Nav.Item>
							<Nav.Link eventKey="shifts">Turnos</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="ubications">Ubicaciones</Nav.Link>
						</Nav.Item>
					</Nav>
					<Tab.Content>
						<Tab.Pane eventKey="shifts">
							{this.getShiftFilter()}
						</Tab.Pane>
						<Tab.Pane eventKey="ubications">
							{this.getUbicationFilter()}
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
			</div>
		)
	};


	handleUbicationSelection = (event) => {
		const { ubication_list } = this.state;
		const countSelected = ubication_list.filter((o) => o.checked === true).length;
		var id = event.target.id;
		id = id.replace("ubication_", "");
		const value = event.target.checked;

		if (countSelected >= 3 && value) {
			toastr.error("", "Puedes seleccionar un máximo de 3 ubicaciones por turno");
		} else {
			this.setState({
				ubication_list: this.state.ubication_list.map((o) =>
					o.id === parseInt(id) ? { ...o, checked: value } : o
				),
			});
		}
	};

	handleUbicationDragSelection = (event) => {
		const { ubication_list } = this.state;
		const countSelected = ubication_list.filter((o) => o.checked === true).length;
		var id = event.target.id;
		id = id.replace("ubication_li_", "");

		if (countSelected > 3) {
			toastr.error("", "Puedes seleccionar un máximo de 3 ubicaciones por turno");
		} else {
			this.setState({
				ubication_list: this.state.ubication_list.map((o) => o.id === parseInt(id) ? { ...o, checked: true } : o),
			});
		}
	};


	// menu contextual turno
	handleLockShift = async (id, locked) => {
		const plan = {
			id,
			locked,
		};
		const response = await plansService.update(plan);
		//console.log(response);
		if (response.ok) this.getPlanAssignments();
	};

	handleEditShift = async (plan) => {
		this.openEditPlanShiftModal(plan);
	};

	handleCopyShift = async (plan) => {
		toastr.success("¡Bien!", "Turno copiado correctamente");
		// console.log(plan);

		this.setState({
			copied_shift: plan,
		});
	};

	handlePasteShiftPlan = async () => {
		const { copied_shift, plan_assignments, day_selection } = this.state;
		const shift_plan = copied_shift.shift_plan;
		const plans_to_save = [];

		for (const item of day_selection) {
			// console.log(item);
			const day = plan_assignments[item.row].plans[item.index].day;
			const employee = plan_assignments[item.row].employee;

			const newPlan = {
				day: day,
				employee_id: employee.id,
				shift_id: shift_plan.shift.id,
				locked: true,
			};

			if (shift_plan.function) {
				newPlan.function_id = shift_plan.function.id;
			}

			if (shift_plan.ubications) {
				const ubication_ids = [];
				shift_plan.ubications.map(ubication => {
					ubication_ids.push(ubication.id);
					return true;
				});

				newPlan.ubication_ids = ubication_ids;
			}

			if (shift_plan.comments)
				newPlan.comments = shift_plan.comments;

			if (shift_plan.time_intervals) {
				const time_intervals = shift_plan.time_intervals.map((interval) => {
					interval.time_type_id = interval.time_type.id;
					return interval;
				});
				newPlan.time_intervals = time_intervals;
			}

			plans_to_save.push(newPlan);
		}


		const response = await plansService.updateMultiple(plans_to_save);
		// console.log(response);
		var hasErrors = false;
		var textError = "Se han encontrado los siguientes errores: \r\n";
		response.map((item) => {
			if (item.errors !== undefined) {
				hasErrors = true;
				item.errors.map((error) => {
					// console.log(error);
					textError += "- " + error.description + "\r\n";
				})
			}
		});

		if (!hasErrors) {
			this.getAllAssignments();
		} else {
			this.openAlertModal(
				textError,
				"Error al pegar turnos"
			);
		}

		this.setState({
			day_selection: [],
			day_selection_dashed: [],
			plan_loading: false,
		});
	};

	handleRemoveShift = async (id) => {
		const response = await plansService.remove(id);

		if (response.ok) {
			this.getAllAssignments();

			if (this.state.day_selection_dashed.length > 0) {
				this.setState({
					day_selection_dashed: [],
				});
			}
		}
		else {
			// show error
			let textError = response.errors[0].description;

			this.openAlertModal(
				textError,
				"Error al eliminar turnos"
			);
		}
	};

	// menu contextual turno
	handleShiftMenuClick = (e, data, target) => {
		// console.log(data);
		// console.log(target);
		const targetId = data.targetId;

		if (data.action === "lock") {
			this.handleLockShift(targetId, true);
		}
		if (data.action === "unlock") {
			this.handleLockShift(targetId, false);
		}
		if (data.action === "copy") {
			this.handleCopyShift(data.plan);
		}
		if (data.action === "edit") {
			this.handleEditShift(data.plan);
		}
		if (data.action === "delete") {
			this.handleRemoveShift(targetId);
		}
		// if (data.action === "paste-ubication") {
		// 	this.handlePasteUbications(data);
		// 	// this.handleDayPasteUbications();
		// }
	};

	_hasSelection = (list) => {
		const result = list.find((o) => o.checked === true);
		return result !== undefined;
	};

	_filterSelected = (list) => {
		return list.filter((o) => o.checked === true);
	};

	_uncheckSelectedList = (list) => {
		return list.map((item) => {
			item.checked = false;
			return item;
		});
	};

	// menu contextual dia
	handleDayMenuClick = (e, data, target) => {
		// console.log(data);
		if (data.action === "paste-shift") {
			this.handlePasteShifts();
		}
		if (data.action === "paste-plan") {
			this.handlePasteShiftPlan();
		}
		if (data.action === "cut") {
			this.handleCutShifts();
		}
		if (data.action === "cut-paste") {
			this.handlePasteCutShifts();
		}
		if (data.action === "remove") {
			this.handleRemoveShifts();
		}
		if (data.action === "paste-ubication") {
			this.handleDayPasteUbications();
		}
	};

	handlePasteShifts = async () => {
		const { plan_assignments, day_selection, shift_list, ubication_id } = this.state;
		const filteredShifts = this._filterSelected(shift_list);
		const plans_to_save = [];

		// for each selected day, save shift list
		day_selection.forEach((item) => {
			// console.log(item);
			const employee = plan_assignments[item.row].employee;
			const day = plan_assignments[item.row].plans[item.index].day;
			const fun = employee.employee_with_function
				? employee.employee_with_function.function.id
				: -1;

			filteredShifts.forEach((shift) => {
				// console.log(shift);
				const newPlan = {
					day: day,
					employee_id: employee.id,
					shift_id: shift.id,
					locked: true,
					ubication_ids: []
				};


				if (shift.time_intervals) {
					const time_intervals = shift.time_intervals.map((interval) => {
						interval.time_type_id = interval.time_type.id;

						return interval;
					});

					newPlan.time_intervals = time_intervals;
				}

				if (fun > 0) newPlan.function_id = fun;

				if (ubication_id > 0)
					newPlan.ubication_ids.push(ubication_id * 1);

				plans_to_save.push(newPlan);
			});
		});

		const response = await plansService.updateMultiple(plans_to_save);
		var hasErrors = false;
		var textError = "Se han encontrado los siguientes errores: \r\n";
		response.map((item) => {
			if (item.errors !== undefined) {
				hasErrors = true;
				item.errors.map((error) => {
					// console.log(error);
					textError += "- " + error.description + "\r\n";
				})
			}
		});

		if (!hasErrors) {
			this.getAllAssignments();
		} else {
			this.openAlertModal(
				textError,
				"Error al pegar turnos"
			);
		}

		this.setState({
			day_selection: [],
			day_selection_dashed: [],
			shift_list: this._uncheckSelectedList(this.state.shift_list),
			// loading: false,
		}, () => {
			this.updatePlanScroll();
		});
	};

	updatePlanScroll = () => {
		if (this.refScrollPlan.current) {
			this.refScrollPlan.current.scrollLeft = scrollPlanLeft;
			this.refScrollPlan.current.scrollTop = scrollPlanTop;
		}
	}

	handleRemoveShifts = async () => {
		const { plan_assignments, day_selection } = this.state;
		const plans_to_delete = [];

		// for each selected day, iterate shift list and get id
		day_selection.forEach((item) => {
			const assignments =
				plan_assignments[item.row].plans[item.index].assignments;

			assignments.forEach((assignment) => {
				plans_to_delete.push({ id: assignment.id });
			});
		});

		const response = await plansService.removeMultiple(plans_to_delete);

		if (response.ok) {
			this.getAllAssignments();
		}
		else {
			// show error
			let textError = response.errors[0].description;

			this.openAlertModal(
				textError,
				"Error al eliminar turnos"
			);
		}

		this.setState({
			day_selection: [],
			day_selection_dashed: [],
			shift_list: this._uncheckSelectedList(this.state.shift_list),
			// loading: false,
		}, () => {
			//this.updatePlanScroll();
		});
	};

	// cortar turnos
	handleCutShifts = async () => {
		const { day_selection, plan_assignments } = this.state;

		// comprueba que solo corresponda a un empleado 
		var moreEmployees = false;
		var check_row;
		day_selection.map((item, index) => {
			if (index === 0) {
				check_row = item.row;
			}
			else {
				if (check_row != item.row) {
					moreEmployees = true;
				}
			}
		});

		if (day_selection.length >= 7) {
			toastr.error("", "Puedes seleccionar un máximo de 7 días para cortar");
		} else if (moreEmployees) {
			toastr.error("", "La selección a cortar debe ser del mismo empleado");
		}
		else {
			// todo ok, vamos:

			day_selection.forEach((item) => {
				plan_assignments[item.row].plans[item.index].checked = false;
			});

			this.setState({
				day_selection: [],
				shift_list: this._uncheckSelectedList(this.state.shift_list),
				day_selection_copy: day_selection,
				plan_assignments,
			}, () => {
				toastr.success("¡Bien!", "Selección cortada correctamente");
			});

		}

	};

	// pegar turnos cortados
	handlePasteCutShifts = async () => {
		const { day_selection, day_selection_copy, plan_assignments } = this.state;

		// empleado de destino (será único)
		const target_employee = plan_assignments[day_selection[0].row].employee;

		// el dia del plan lo cogemos de la selección de días de destino (day_selection), usando el primer objeto seleccionado
		const target_day = plan_assignments[day_selection[0].row].plans[day_selection[0].index].day;

		const object_to_paste = {
			day: target_day, // target day
			employee_id: target_employee.id // target employee
		}
		const plans_to_save = [];

		// recorremos la selección de días cortados (day_selection_copy)
		day_selection_copy.map((selected_day, index) => {
			const day_plans = plan_assignments[selected_day.row].plans[selected_day.index];
			const day = day_plans.day;
			const employee = plan_assignments[selected_day.row].employee;

			// entra en los assignments del dia seleccionado
			day_plans.assignments.map((shift_plan) => {
				const newPlan = {
					id: shift_plan.id,
					employee_id: employee.id,
					day,
					locked: true,
				};

				newPlan.shift_id = shift_plan.shift.id;

				if (shift_plan.function) {
					newPlan.function_id = shift_plan.function.id;
				}

				if (shift_plan.ubications) {
					const ubication_ids = [];
					shift_plan.ubications.map(ubication => {
						ubication_ids.push(ubication.id);
						return true;
					});

					newPlan.ubication_ids = ubication_ids;
				}

				if (shift_plan.comments)
					newPlan.comments = shift_plan.comments;

				if (shift_plan.time_intervals) {
					const time_intervals = shift_plan.time_intervals.map((interval) => {
						interval.time_type_id = interval.time_type.id;
						return interval;
					});
					newPlan.time_intervals = time_intervals;
				}

				plans_to_save.push(newPlan);

				return true;
			});

			return true;
		});

		// console.log(plans_to_save);
		object_to_paste.plans = plans_to_save;

		// PEGAR turnos planificados en la nueva selección:
		const paste_response = await plansService.cutAndPaste(object_to_paste);
		// console.log(paste_response);
		var hasErrors = false;
		var textError = "Se han encontrado los siguientes errores: \r\n";
		paste_response.map((item) => {
			if (item.errors !== undefined) {
				hasErrors = true;
				item.errors.map((error) => {
					// console.log(error);
					textError += "- " + error.description + "\r\n";
				})
			}
		});

		if (hasErrors) {
			this.openAlertModal(
				textError,
				"Error al pegar turnos"
			);
		}

		this.getAllAssignments();

		this.setState({
			day_selection_dashed: day_selection_copy,
			day_selection: [],
			day_selection_copy: [],
		});
	};

	// pegar ubicaciones en uno o varios días (menu day)
	handleDayPasteUbications = async () => {
		const { plan_assignments, day_selection, ubication_list } = this.state;
		const filteredUbications = this._filterSelected(ubication_list);
		const plans_to_save = [];

		// for each selected day and each shift, add ubication(s)
		day_selection.map((selected_day, index) => {
			const day_plans = plan_assignments[selected_day.row].plans[selected_day.index];
			const day = day_plans.day;
			const employee = plan_assignments[selected_day.row].employee;

			// entra en los assignments del dia seleccionado
			day_plans.assignments.map((shift_plan) => {
				// solo entramos si tiene turno asignado
				if (shift_plan.id !== -1) {
					const newPlan = {
						id: shift_plan.id,
						employee_id: employee.id,
						day,
						locked: true,
					};

					newPlan.shift_id = shift_plan.shift.id;

					if (shift_plan.function) {
						newPlan.function_id = shift_plan.function.id;
					}

					if (shift_plan.comments)
						newPlan.comments = shift_plan.comments;

					if (shift_plan.time_intervals) {
						const time_intervals = shift_plan.time_intervals.map((interval) => {
							interval.time_type_id = interval.time_type.id;
							return interval;
						});
						newPlan.time_intervals = time_intervals;
					}

					// y ahora sí, las ubicaciones
					const ubication_ids = [];
					// las existentes:
					shift_plan.ubications && shift_plan.ubications.map(ubication => {
						ubication_ids.push(ubication.id);
						return true;
					});
					// las nuevas:
					filteredUbications.map(ubication => {
						ubication_ids.push(ubication.id);
						return true;
					});
					newPlan.ubication_ids = ubication_ids;

					plans_to_save.push(newPlan);
				}
				return true;
			});

			return true;
		});

		// console.log(plans_to_save);

		if (plans_to_save.length > 0) {
			const response = await plansService.updateMultiple(plans_to_save);
			var hasErrors = false;
			var textError = "Se han encontrado los siguientes errores: \r\n";
			response.map((item) => {
				if (item.errors !== undefined) {
					hasErrors = true;
					item.errors.map((error) => {
						// console.log(error);
						textError += "- " + error.description + "\r\n";
					})
				}
			});

			if (!hasErrors) {
				this.getAllAssignments();
			} else {
				this.openAlertModal(
					textError,
					"Error al pegar ubicaciones"
				);
			}

			this.setState({
				day_selection: [],
				day_selection_dashed: [],
				ubication_list: this._uncheckSelectedList(this.state.ubication_list),
				// loading: false,
			}, () => {
				this.updatePlanScroll();
			});
		}
		else {
			this.openAlertModal(
				"Solo se pueden pegar ubicaciones sobre días con turnos ya planificados",
				"Error al pegar ubicaciones"
			);
		}
	};

	// menu contextual staffing levels

	handleEditStaffingLevel = async (options) => {
		const { group, needs_selection } = this.state;

		options.group = group;
		options.needs_selection = needs_selection;
		options.isMultiple = needs_selection !== undefined && needs_selection.length > 1;

		this.openEditStaffingLevelModal(options);
	};

	handleStaffingMenuClick = (e, data, target) => {
		if (data.action === "edit") {
			this.handleEditStaffingLevel(data.itemStaffing);
		}
	};

	handleScroll(e) {
		if (e.target.className === "plan-container") {
			const scrollTop = this.refScrollPlan.current.scrollTop;
			if (this.refScrollCounter.current)
				this.refScrollCounter.current.scrollTop = scrollTop;
			scrollPlanLeft = this.refScrollPlan.current.scrollLeft;
			scrollPlanTop = this.refScrollPlan.current.scrollTop;
		} else {
			const scrollTop = this.refScrollCounter.current.scrollTop;
			this.refScrollPlan.current.scrollTop = scrollTop;
		}
	}

	getPlanContent = () => {
		const { error } = this.props;
		const { loading, plan_grid_view, plan_assignments, isExtendedView, hasPrivileges, showMax, plan_no_results, no_contract } = this.state;

		// console.log(no_contract);

		// solo la primera vez
		if (loading) {
			return <Loading />;
		}

		if (no_contract) {
			return (
				<div className="empty-container w100">
					<FiCalendar />
					Su usuario no tiene contrato. Debe tener uno asignado para poder ver la planificación.
				</div>
			);
		}
		else if (error || !plan_assignments) {
			return (
				<div className="empty-container w100">
					<FiCalendar />
					Se ha producido un error al cargar la planificación
				</div>
			);
		}

		if (plan_assignments.length < 1 && plan_no_results) {
			return (
				<div className="empty-container w100">
					<FiCalendar />
					Sin resultados
				</div>
			);
		}

		if (PLAN_GRID_VIEWS.vertical === plan_grid_view) {
			return (
				<div
					ref={this.refScrollPlan}
					id="planContainer"
					className="plan-container"
					onScroll={this.handleScroll}
				>
					{this.getBloquePlan()}
				</div>
			);
		}

		return (
			<ResizablePanels
				ref={this.refResizablePanels}
				displayDirection="row"
				width={isExtendedView || !hasPrivileges ? "100%" : "calc(100% - 150px)"}
				panelsSize={[70, 30]}
				isMax={showMax}
				sizeUnitMeasure="%"
				className="resizable-wrapper"
			>
				<div
					ref={this.refScrollPlan}
					id="planContainer"
					className="plan-container"
					onScroll={this.handleScroll}
				>
					{this.getBloquePlan()}
					{(PLAN_GRID_VIEWS.plan === plan_grid_view || PLAN_GRID_VIEWS.ubications === plan_grid_view || PLAN_GRID_VIEWS.combo === plan_grid_view) &&
						this.getBloqueStaffingLevel()}

					{PLAN_GRID_VIEWS.hours === plan_grid_view &&
						this.getBloqueHourlyCounter()}
				</div>
				{PLAN_GRID_VIEWS.vertical !== plan_grid_view &&
					<div
						ref={this.refScrollCounter}
						className="counter-container"
						onScroll={this.handleScroll}
					>
						{this.getBloqueCounter()}
					</div>
				}
			</ResizablePanels>
		);

	}

	renderLoadingOverlay(loadingState) {
		if (!loadingState) {
			return null;
		}

		return (
			<div className="loading-overlay">
				<Loading />
			</div>
		);
	}

	// plan render
	getBloquePlan = () => {
		const { plan_days, default_shift, working_hours } = this.props;
		const {
			plan_assignments,
			day_selection,
			day_selection_copy,
			day_selection_dashed,
			current_month,
			plan_grid_view,
			hasPrivileges,
			working_hours_list,
			ubication_list,
			plan_no_results,
			plan_loading
		} = this.state;

		const isUbicationListSelected = this._hasSelection(ubication_list);

		return (
			<div className="relative">
				{this.renderLoadingOverlay(plan_loading)}
				<PlanTable
					hasPrivileges={hasPrivileges}
					isUbicationListSelected={isUbicationListSelected}
					plan_days={plan_days}
					plan_assignments={plan_assignments}
					day_selection={day_selection}
					day_selection_copy={day_selection_copy}
					day_selection_dashed={day_selection_dashed}
					default_shift={default_shift}
					current_month={current_month}
					plan_grid_view={plan_grid_view}
					working_hours={working_hours}
					working_hours_list={working_hours_list}
					changeMonthHeader={this.changeMonthHeader}
					handleDaySelection={this.handleDaySelection}
					selectSingleDay={this.selectSingleDay}
					handleDayMenuClick={this.handleDayMenuClick}
					handleShiftMenuClick={this.handleShiftMenuClick}
					handleEditShift={this.handleEditShift}
					handleCopyShift={this.handleCopyShift}
				/>
			</div>
		);
	};

	changeMonthHeader = (inView, entry, new_day) => {
		const { current_month } = this.state;
		const month = moment(current_month).format("MM");
		var day = moment(new_day).format("MM");

		if (inView && month !== day) {
			this.setState({
				current_month: new_day,
			});
		}
	};


	openNewStaffingLevelModal(data) {
		const { staffing_levels } = this.props;
		const { group_id } = this.state;
		// console.log(data);

		this.props.showModal({
			open: true,
			title: 'Nueva asignación con necesidades de personal',
			style: { width: '400px' },
			content: <GroupStaffingLevelsEdit handleClose={this.closeModal} triggerStaffingLevelAssignments={this.getStaffingLevelAssignments} start_day={data.day} end_day={data.day} staffing_levels={staffing_levels} group_id={group_id} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	//counter render
	getBloqueCounter = () => {
		const { error, account_assignments } = this.props;
		const { start, end, plan_grid_view, accounts_loading } = this.state;

		if (error) {
			return "Error cargando contadores. Intenta refrescar la página";
		}

		return (
			<div className="relative">
				{this.renderLoadingOverlay(accounts_loading)}
				<PlanCounters
					start={start}
					end={end}
					account_assignments={account_assignments}
					plan_grid_view={plan_grid_view}
				/>
			</div>
		);
	};

	// necesidades de personal
	getBloqueStaffingLevel = () => {
		const { plan_days, needs_selection } = this.props;
		const { needs_suggestions, search_staffing_shift, name, plan_grid_view, staffing_loading, ubication_staffing_id, function_staffing_id, staffing_level_assignments, staffing_level_current_assignments, showStaffingFilter } = this.state;
		const { filteredFunctions, filteredUbications } = this.filterSelectOptions();

		const optionFunctionList =
			filteredFunctions.length > 0 &&
			filteredFunctions.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name}
					</option>
				);
			}, this);

		const optionUbicationList =
			filteredUbications.length > 0 &&
			filteredUbications.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name}
					</option>
				);
			}, this);

		// Todo con etiquetas mostrar icono etiqueta o icono necesidad al lado
		const renderSuggestion = (suggestion) => (
			<>
				<span className="suggestion-main">
					{suggestion.name}
				</span>
				{/* <span className="suggestion-info">{suggestion. email}</span> */}
			</>
		);

		return (
			<div className="relative staffing-container">
				{this.renderLoadingOverlay(staffing_loading)}
				<div className="staffing-filters">
					{showStaffingFilter ?
						<div className="staffing-filters-content">
							{filteredUbications.length > 0 &&
								<FormGroup controlId="ubication_staffing_id">
									<FormControl
										type="text"
										value={ubication_staffing_id}
										onChange={this.handleChangeSearch}
										as="select"
									>
										<option value="-1">Filtrar por ubicación</option>
										{optionUbicationList}
									</FormControl>
								</FormGroup>
							}
							{filteredFunctions.length > 0 &&
								<FormGroup controlId="function_staffing_id">
									<FormControl
										type="text"
										value={function_staffing_id}
										onChange={this.handleChangeSearch}
										as="select"
									>
										<option value="-1">Filtrar por función</option>
										{optionFunctionList}
									</FormControl>
								</FormGroup>
							}

							<FormGroup className="form-search" controlId="search_staffing_shift">
								<FormControl
									type="text"
									placeholder="Filtrar por turno..."
									value={search_staffing_shift}
									onChange={this.handleChangeSearch}
								/>
								{search_staffing_shift &&
									<button
										className="btn-clear btn-transparent"
										onClick={this.onClearStaffingShiftSearch}
										type="button"
									>
										<FiX />
									</button>}
							</FormGroup>

							<div className="react-autosuggest">
								<Autosuggest
									suggestions={needs_suggestions}
									onSuggestionsFetchRequested={
										this.onNeedsSuggestionsFetchRequested
									}
									onSuggestionsClearRequested={
										this.onNeedsSuggestionsClearRequested
									}
									onSuggestionSelected={this.onNeedsSuggestionSelected}
									getSuggestionValue={(suggestion) => suggestion.name}
									renderSuggestion={renderSuggestion}
									alwaysRenderSuggestions={true}
									inputProps={{
										placeholder: "Filtrar por necesidad",
										value: name,
										onChange: this.onChangeNeedsAutosugest,
										className: "form-control",
									}}
								/>
								{name && (
									<button
										className="btn-autosuggest-clear btn-transparent"
										onClick={this.onClearNeedsAutosugest}
										type="button"
									>
										<FiX />
									</button>
								)}
							</div>
						</div> : <span></span>
					}

					{(filteredUbications.length > 0 || filteredFunctions.length > 0) &&
						<button type="button" className={`btn btn-filter ${showStaffingFilter ? "active" : ""}`} title={`${!showStaffingFilter ? "Mostrar filtros" : "Ocultar filtros"}`} onClick={() => this.toggleStaffingFilter()}>
							<FiFilter className="icon-filter" />
						</button>
					}
				</div>
				{((staffing_level_assignments && staffing_level_assignments.length > 0) || (staffing_level_current_assignments && staffing_level_current_assignments.length > 0)) ?
					<PlanStaffingLevels
						plan_days={plan_days}
						staffing_level_assignments={staffing_level_assignments}
						staffing_level_current_assignments={staffing_level_current_assignments}
						handleStaffingMenuClick={this.handleStaffingMenuClick}
						needs_selection={needs_selection}
						handleNeedSelection={this.handleNeedSelection}
						plan_grid_view={plan_grid_view}
						openNewStaffingLevelModal={this.openNewStaffingLevelModal}
					// selectSingleNeed={this.selectSingleNeed}
					/>
					: <div className="plan-error">
						<FiUsers className="icono-plan" />
						<p>Sin resultados de necesidades</p>
					</div>}
			</div>
		);

	};

	// tabla contador de horas trabajadas por día
	getBloqueHourlyCounter = () => {
		// TODO revisar porque no va bien el loading
		const { plan_assignments, working_hours_list, staffing_hourly_loading, plan_loading } = this.state;
		const { plan_days, staffing_level_hourly_assignments } = this.props;

		return (
			<>
				<div className="relative">
					{this.renderLoadingOverlay(plan_loading)}
					<PlanHourlyCounter
						plan_assignments={plan_assignments}
						plan_days={plan_days}
						working_hours_list={working_hours_list}
					/>
				</div>

				{staffing_level_hourly_assignments.length > 0 &&
					<PlanStaffingHourly
						plan_assignments={plan_assignments}
						plan_days={plan_days}
						staffing_level_hourly_assignments={staffing_level_hourly_assignments}
						working_hours_list={working_hours_list}
					/>}
			</>
		);
	};

	// EXPORT valores de cuentas
	openExportValoresCuentasModal(token) {
		this.props.showModal(
			{
				open: true,
				title: "Exportar valores de cuentas",
				style: { width: "400px" },
				confirmText: "Descargar",
				loadingText: "Descargando...",
				message:
					"Los valores de cuentas se han exportado correctamente. Haz click en Descargar para iniciar la descarga",
				closeModal: this.closeModal,
				confirmAction: () => {
					this.props.updateModalLoading(true);
					this.downloadExport(token, "valores_cuentas.xlsx")
				},
			},
			"confirm"
		);
	}

	async handleExportValoresCuentas() {
		const { group_id, start, end, plan_view_id, function_id } = this.state;
		const token = await accountsService.exportList({
			group_id,
			start,
			end,
			plan_view_id,
			function_id,
		});

		if (token.ok) {
			this.openExportValoresCuentasModal(token.access_token);
		} else {
			this.openAlertModal(
				"Ha ocurrido un error en la exportación",
				"Error al exportar"
			);
		}
	}

	// EXPORT planificación
	openExportPlanificacionModal(token) {
		this.props.showModal(
			{
				open: true,
				title: "Exportar planificación",
				style: { width: "400px" },
				confirmText: "Descargar",
				loadingText: "Descargando...",
				message:
					"La planificación se ha exportado correctamente. Haz click en Descargar para iniciar la descarga",
				closeModal: this.closeModal,
				confirmAction: () => {
					this.props.updateModalLoading(true);
					this.downloadExport(token, "planificacion.xlsx")
				},
			},
			"confirm"
		);
	}

	async handleExportPlanificacion(special) {
		const { group_id, start, end } = this.state;

		const token = await plansService.exportPlan({ group_id, start, end, special });

		if (token.ok) {
			this.openExportPlanificacionModal(token.access_token);
		} else {
			this.openAlertModal(
				"Ha ocurrido un error en la exportación",
				"Error al exportar"
			);
		}
	}

	async handleExportPlanificacionPDF() {
		// const tablePlan = document.getElementById('planContainer');
		const tablePlan = document.getElementById('tablePlan');

		html2canvas(tablePlan).then(function (canvas) {
			// document.body.appendChild(canvas);
			const imgData = canvas.toDataURL('image/png');
			// window.open(imgData, '_blank');
			const pdf = new jsPDF({
				orientation: 'l', // landscape
				unit: 'pt', // points, pixels won't work properly
				format: [canvas.width, canvas.height] // set needed dimensions for any element
			});
			pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
			pdf.save('plan.pdf');
		});
	}

	async handleExportNeedsPDF() {
		// const tablePlan = document.getElementById('planContainer');
		const tablePlan = document.getElementById('tableNeeds');

		html2canvas(tablePlan).then(function (canvas) {
			// document.body.appendChild(canvas);
			const imgData = canvas.toDataURL('image/png');
			// window.open(imgData, '_blank');
			const pdf = new jsPDF({
				orientation: 'l', // landscape
				unit: 'pt', // points, pixels won't work properly
				format: [canvas.width, canvas.height] // set needed dimensions for any element
			});
			pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
			pdf.save('needs.pdf');
		});
	}

	// EXPORT turnos
	openExportShiftsModal(token) {
		this.props.showModal(
			{
				open: true,
				title: "Exportar turnos planificados",
				style: { width: "400px" },
				confirmText: "Descargar",
				loadingText: "Descargando...",
				message:
					"Los turnos planificados se han exportado correctamente. Haz click en Descargar para iniciar la descarga",
				closeModal: this.closeModal,
				confirmAction: () => {
					this.props.updateModalLoading(true);
					this.downloadExport(token, "turnos_planificados.xlsx")
				},
			},
			"confirm"
		);
	}

	async handleExportShifts() {
		const { group_id, start, end } = this.state;
		const token = await plansService.exportShifts({
			group_id,
			start,
			end,
		});

		if (token.ok) {
			this.openExportShiftsModal(token.access_token);
		} else {
			this.openAlertModal(
				"Ha ocurrido un error en la exportación",
				"Error al exportar"
			);
		}
	}

	async downloadExport(token, filename) {
		const response = await appService.getExported(token);

		var url = window.URL.createObjectURL(response);
		var a = document.createElement("a");
		document.body.appendChild(a);
		a.href = url;
		a.download = filename;
		a.click();

		this.props.updateModalLoading(false); // Cambiar a no cargando
		this.closeModal(); // cerrar modal de confirmación
	}

	// Confirmar asignar funciones a planificación
	handleSetFunciones() {
		this.props.showModal(
			{
				open: true,
				title: "Asignar funciones a los turnos planificados",
				style: { width: "450px" },
				confirmText: "Asignar funciones",
				loadingText: "Asignando...",
				message:
					"Se asignarán las funciones que los empleados tengan por defecto en los turnos planificados del grupo y periodo seleccionado. ¿Deseas continuar?",
				closeModal: this.closeModal,
				confirmAction: () => {
					this.props.updateModalLoading(true); // Cambiar a cargando
					this.confirmSetFunciones();
				},
			},
			"confirm"
		);
	}

	async confirmSetFunciones() {
		const { group_id, start, end } = this.state;
		const response = await plansService.setFunctions({
			group_id,
			start,
			end,
		});

		// console.log(response);

		// Después de recibir la respuesta:
		this.props.updateModalLoading(false); // Cambiar a no cargando
		this.closeModal(); // cerrar modal de confirmación

		if (response.ok) {
			toastr.success("¡Bien!", "Funciones asignadas correctamente");
		}
		else {
			// show error
			let textError = response.errors[0].description;

			this.openAlertModal(
				textError,
				"Error al asignar funciones"
			);
		}
	}

	// unlock todos los turnos
	openUnlockModal(props) {
		const { presence_shifts_only } = props;
		var text_turno = presence_shifts_only ? "de trabajo " : "";

		this.props.showModal(
			{
				open: true,
				title: "Desbloquear turnos",
				message: "Si confirmas se desbloquearan todos los turnos " + text_turno + "del grupo y periodo abierto en pantalla",
				confirmAction: () => this.unlockPlan(presence_shifts_only),
				closeModal: this.closeModal,
				style: { width: "420px" },
			},
			"confirm"
		);
	}

	async unlockPlan(presence_shifts_only) {
		const { group_id, start, end } = this.state;
		const response = await plansService.unlock({
			group_id,
			start,
			end,
			presence_shifts_only
		});

		this.closeModal();

		if (response.ok) {
			this.getAllAssignments();
		} else {
			// console.log(response);
			this.openAlertModal(
				"Ha ocurrido un error con el desbloqueo de turnos",
				"Error al desbloquear"
			);
		}
	}


	// Automagic planification
	openNewMagicPlan() {
		const { group_id, start, end } = this.state;

		this.props.showModal({
			open: true,
			title: 'Planificación automática',
			style: { width: '860px' },
			content: <PlanMagicModal group_id={group_id} start={start} end={end} handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	// EMPLOYEE AUTOCOMPLETE SUGGESTIONS

	getEmployeeSuggestions = (value) => {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;

		return inputLength === 0
			? []
			: this.props.select_employees.filter(
				(employee) =>
					employee.name.toLowerCase().includes(inputValue) ||
					employee.surname.toLowerCase().includes(inputValue) ||
					employee.email.toLowerCase().includes(inputValue)
			);
	};

	onChangeEmployeeAutosugest = (event, { newValue, method }) => {
		// console.log(method);

		this.setState(
			{
				search_employee: newValue,
			},
			async () => {
				if (method !== "type") {
					this.getAllAssignments();
				}
			}
		);
	};

	onClearEmployeeAutosugest = () => {
		this.setState(
			{
				search_employee: "",
			},
			async () => {
				this.getAllAssignments();
			}
		);
	};
	// Autosuggest will call this function every time you need to update suggestions
	onEmployeeSuggestionsFetchRequested = ({ value }) => {
		this.setState({
			employee_suggestions: this.getEmployeeSuggestions(value),
		});
	};

	// Autosuggest will call this function every time you need to clear suggestions
	onEmployeeSuggestionsClearRequested = () => {
		this.setState({
			employee_suggestions: [],
		});
	};


	// STAFFING NEEDS AUTOCOMPLETE SUGGESTIONS

	// TODO añadir búsqueda por tags
	getNeedsSuggestions = (value) => {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;
		const { staffing_level_assignments } = this.state;

		if (inputLength === 0) return [];

		const uniqueNames = [];
		return staffing_level_assignments.filter((o) => {
			const lowerCaseName = o.name.toLowerCase();
			if (lowerCaseName.includes(inputValue) && !uniqueNames.includes(lowerCaseName)) {
				uniqueNames.push(lowerCaseName);
				return true;
			}
			return false;
		});
	};

	onChangeNeedsAutosugest = (event, { newValue, method }) => {
		// console.log(method);
		// console.log(newValue);

		this.setState(
			{
				name: newValue,
			},
			async () => {
				this.getFilteredStaffingLevelAssignments();
			}
		);
	};

	onClearNeedsAutosugest = () => {
		this.setState(
			{
				name: "",
			},
			async () => {
				this.getFilteredStaffingLevelAssignments();
			}
		);
	};
	// Autosuggest will call this function every time you need to update suggestions
	onNeedsSuggestionsFetchRequested = ({ value }) => {
		this.setState({
			needs_suggestions: this.getNeedsSuggestions(value),
		});
	};

	// Autosuggest will call this function every time you need to clear suggestions
	onNeedsSuggestionsClearRequested = () => {
		this.setState({
			needs_suggestions: [],
		});
	};

	onNeedsSuggestionSelected = (event, { suggestion }) => {
		this.setState({
			name: suggestion.name
		}, () => {
			this.setState({
				needs_suggestions: []
			}, () => {
				this.onNeedsSuggestionsClearRequested();
				this.getFilteredStaffingLevelAssignments();
			});
		});
	};


	// ************* HELPERS *********************
	// filtra ubicaciones y funciones existentes en la lista de necesidades de personal

	filterSelectOptions = () => {
		const { staffing_level_assignments, staffing_level_current_assignments } = this.props;

		// Usar staffing_level_assignments si no está vacío, de lo contrario usar staffing_level_current_assignments
		const assignments = staffing_level_assignments.length > 0 ? staffing_level_assignments : staffing_level_current_assignments;

		const uniqueFunctions = new Map();
		const uniqueUbications = new Map();

		assignments.forEach((assignment) => {
			if (assignment.function && !uniqueFunctions.has(assignment.function.id)) {
				uniqueFunctions.set(assignment.function.id, assignment.function);
			}
			if (assignment.ubication && !uniqueUbications.has(assignment.ubication.id)) {
				uniqueUbications.set(assignment.ubication.id, assignment.ubication);
			}
		});

		// Convertir Map a array, luego ordenar por nombre ascendente
		const filteredFunctions = Array.from(uniqueFunctions.values()).sort((a, b) =>
			a.name.localeCompare(b.name)
		);

		const filteredUbications = Array.from(uniqueUbications.values()).sort((a, b) =>
			a.name.localeCompare(b.name)
		);

		return {
			filteredFunctions,
			filteredUbications
		};
	}
	// ************* RENDER *********************

	render() {
		const { select_employees, plan_views } = this.props;
		const {
			start,
			end,
			day_selection,
			needs_selection,
			group,
			isExtendedView,
			search_employee,
			employee_suggestions,
			isLocked,
			isPlanKO,
			hasPrivileges,
			plan_grid_view,
			showMax,
			isSupervisorReadOnly,
			select_ubications,
			no_contract
		} = this.state;

		const { filteredFunctions, filteredUbications } = this.filterSelectOptions();

		// var start_format = moment(start).format("D MMMM");
		const start_month = moment(start).format("MMMM");
		const end_month = moment(end).format("MMMM");

		var start_format = moment(start).format("D");
		const end_format = moment(end).format("D") + " de " + end_month;

		const start_year = moment(start).format("YYYY");
		const end_year = moment(end).format("YYYY");

		if (start_month !== end_month)
			start_format = start_format + " de " + start_month;

		if (start_year !== end_year)
			start_format = start_format + " de " + start_year;

		const optionViewList =
			plan_views.length > 0 &&
			plan_views.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name}
					</option>
				);
			}, this);

		const optionFunctionList =
			filteredFunctions.length > 0 &&
			filteredFunctions.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name}
					</option>
				);
			}, this);

		const optionUbicationList =
			filteredUbications.length > 0 &&
			filteredUbications.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name}
					</option>
				);
			}, this);

		const isShiftListSelected = this._hasSelection(this.state.shift_list) && day_selection.length > 0;
		const isUbicationListSelected = this._hasSelection(this.state.ubication_list);
		const isCopiedPlanSelected = this.state.copied_shift !== "" && day_selection.length > 0;
		const isNeedSelected = needs_selection.length > 0;
		const isCutSelected = this.state.day_selection_copy.length > 0;

		const getSuggestionValue = (suggestion) =>
			suggestion.name + " " + suggestion.surname;
		const renderSuggestion = (suggestion) => (
			<>
				<span className="suggestion-main">
					{suggestion.name} {suggestion.surname}
				</span>
				<span className="suggestion-info">{suggestion.email}</span>
			</>
		);

		return (
			<>
				<Layout className="page-plan">
					<div className="full-height-wrapper">
						<div className="heading">
							<div className="heading-left">
								<h1 className="title">
									Planificación {group && group.name}
									<span className="plan-periodo">
										del {start_format} al {end_format} de {end_year}
									</span>
								</h1>
							</div>
							<div className="heading-filters">
								<button type="button" className={`btn btn-filter ${showMax ? "active" : ""}`} title={`${!showMax ? "Maximizar plan" : "Minimizar plan"}`} onClick={() => this.toggleShowMax()}>
									{showMax ? <TbArrowsMinimize className="icon-maxmin icon-min" /> : <TbArrowsMaximize className="icon-maxmin icon-max" />}
								</button>
							</div>
							{!no_contract && (hasPrivileges || isSupervisorReadOnly) && (
								<div className="heading-actions">

									{hasPrivileges && (
										<>
											<button
												type="button"
												title="Planificar"
												disabled={isLocked}
												onClick={this.openNewMagicPlan}
												className="btn btn-outline-primary btn-wand"
											>
												Planificación automática
												<FaMagic className="icon-wand" />
											</button>


											<button
												type="button"
												title="Planificar"
												disabled={isLocked}
												onClick={this.autoPlan}
												className="btn btn-primary"
											>
												Planificar
											</button>
										</>
									)}


									<Dropdown>
										<Dropdown.Toggle variant="action">
											<FiMoreHorizontal />
										</Dropdown.Toggle>
										<Dropdown.Menu alignRight>

											{hasPrivileges && (
												<>
													<Dropdown.Item
														as="button"
														onClick={() => this.openUnlockModal({ presence_shifts_only: false })}
													>
														<FiUnlock /> Desbloquear todos los turnos
													</Dropdown.Item>

													<Dropdown.Item
														as="button"
														onClick={() => this.openUnlockModal({ presence_shifts_only: true })}
													>
														<FiUnlock /> Desbloquear turnos de trabajo
													</Dropdown.Item>

													<Dropdown.Item
														as="button"
														onClick={() =>
															this.props.history.push("/plan-views")
														}
													>
														<FiSettings /> Configurar vistas
													</Dropdown.Item>

													<Dropdown.Item
														as="button"
														onClick={() => this.openEmployeesOrderModal(group)}
													>
														<BiSortAlt2 /> Ordenar empleados
													</Dropdown.Item>
												</>
											)}

											<Dropdown.Item
												as="button"
												onClick={() => this.handleExportPlanificacion()}
											>
												<FiUpload className="dropdown-icon" /> Exportar	planificación
											</Dropdown.Item>

											<Dropdown.Item
												as="button"
												onClick={() => this.handleExportPlanificacionPDF()}
											>
												<FiUpload className="dropdown-icon" /> Exportar	planificación a PDF
											</Dropdown.Item>

											<Dropdown.Item
												as="button"
												onClick={() => this.handleExportPlanificacion("weekly")}
											>
												<FiUpload className="dropdown-icon" /> Exportar	planificación por semanas
											</Dropdown.Item>

											<Dropdown.Item
												as="button"
												onClick={() => this.handleExportPlanificacion("detailed")}
											>
												<FiUpload className="dropdown-icon" /> Exportar planificación agrupando por ubicación y función
											</Dropdown.Item>

											<Dropdown.Item
												as="button"
												onClick={() => this.handleExportNeedsPDF()}
											>
												<FiUpload className="dropdown-icon" /> Exportar
												necesidades de personal a PDF
											</Dropdown.Item>

											<Dropdown.Item
												as="button"
												onClick={() => this.handleExportShifts()}
											>
												<FiUpload className="dropdown-icon" /> Exportar	turnos planificados
											</Dropdown.Item>

											<Dropdown.Item
												as="button"
												onClick={() => this.handleExportValoresCuentas()}
											>
												<FiUpload className="dropdown-icon" /> Exportar
												valores de cuentas
											</Dropdown.Item>
											<Dropdown.Item
												as="button"
												onClick={() => this.handleSetFunciones()}
											>
												<AiOutlineFunction className="dropdown-icon" /> Asignar funciones a planes
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							)}

						</div>

						{isLocked && (
							<section className="bloque-plan">
								<div className="plan-pending">
									<FiTrello className="icono-plan" />
									<h3 className="title2">Procesando</h3>
									<p>Hay procesos de planificación en curso</p>
								</div>
							</section>
						)}

						{isPlanKO && (
							<section className="bloque-plan">
								<div className="plan-error">
									<PiSmileySadBold className="icono-plan" />
									<h3 className="title2">Error</h3>
									<p>Error cargando la planificación</p>
								</div>
							</section>
						)}

						{!isLocked && !isPlanKO && (
							<section className="bloque-plan">
								{!showMax && (
									<div className="heading">
										<div className="heading-left">
											<ButtonGroup className="btn-group-view" aria-label="Plan view">
												<Button
													variant={`${PLAN_GRID_VIEWS.plan === plan_grid_view ? "primary" : "default"}`}
													onClick={() => this.handleChangeView(PLAN_GRID_VIEWS.plan)}
												>
													<FaTh className="icon" />
												</Button>
												<Button
													variant={`${PLAN_GRID_VIEWS.times === plan_grid_view ? "primary" : "default"}`}
													onClick={() => this.handleChangeView(PLAN_GRID_VIEWS.times)}
												>
													<FaClock className="icon" />
												</Button>
												{(select_ubications && select_ubications.length > 0) &&
													<Button
														variant={`${PLAN_GRID_VIEWS.ubications === plan_grid_view ? "primary" : "default"}`}
														onClick={() => this.handleChangeView(PLAN_GRID_VIEWS.ubications)}
													>
														<FaMapMarkerAlt className="icon" />
													</Button>
												}
												<Button
													variant={`${PLAN_GRID_VIEWS.hours === plan_grid_view ? "primary" : "default"}`}
													onClick={() => this.handleChangeView(PLAN_GRID_VIEWS.hours)}
												>
													<FaSearchPlus className="icon icon-zoom" />
												</Button>
												{(select_ubications && select_ubications.length > 0) &&
													<Button
														className="btn-flex"
														variant={`${PLAN_GRID_VIEWS.combo === plan_grid_view ? "primary" : "default"}`}
														onClick={() => this.handleChangeView(PLAN_GRID_VIEWS.combo)}
													>
														<FaTh className="icon" /><span className="plus">+</span>
														<FaMapMarkerAlt className="icon" />
													</Button>
												}
												<Button
													variant={`${PLAN_GRID_VIEWS.vertical === plan_grid_view ? "primary" : "default"}`}
													onClick={() => this.handleChangeView(PLAN_GRID_VIEWS.vertical)}
												>
													<BiGridVertical className="icon icon-vertical" />
												</Button>
											</ButtonGroup>
										</div>
										{hasPrivileges && !showMax && (
											<div className="heading-filters">
												{select_employees.length > 1 &&
													<div className="react-autosuggest">
														<Autosuggest
															suggestions={employee_suggestions}
															onSuggestionsFetchRequested={
																this.onEmployeeSuggestionsFetchRequested
															}
															onSuggestionsClearRequested={
																this.onEmployeeSuggestionsClearRequested
															}
															getSuggestionValue={getSuggestionValue}
															renderSuggestion={renderSuggestion}
															alwaysRenderSuggestions={true}
															inputProps={{
																placeholder: "Filtrar por empleado",
																value: search_employee,
																onChange: this.onChangeEmployeeAutosugest,
																className: "form-control",
															}}
														/>
														{search_employee && (
															<button
																className="btn-autosuggest-clear btn-transparent"
																onClick={this.onClearEmployeeAutosugest}
																type="button"
															>
																<FiX />
															</button>
														)}
													</div>
												}
												{filteredFunctions.length > 0 &&
													<FormGroup controlId="function_id">
														<FormControl
															type="text"
															value={this.state.function_id}
															onChange={this.handleChangeSearch}
															as="select"
														>
															<option value="-1">Filtrar por función</option>
															{optionFunctionList}
														</FormControl>
													</FormGroup>
												}
												{filteredUbications.length > 0 &&
													<FormGroup controlId="ubication_id">
														<FormControl
															type="text"
															value={this.state.ubication_id}
															onChange={this.handleChangeSearch}
															as="select"
														>
															<option value="-1">Filtrar por ubicación</option>
															{optionUbicationList}
														</FormControl>
													</FormGroup>
												}
												{plan_views.length > 1 &&
													<FormGroup controlId="plan_view_id">
														<FormControl
															type="text"
															value={this.state.plan_view_id}
															onChange={this.handleChangeSearch}
															as="select"
														>
															{optionViewList}
														</FormControl>
													</FormGroup>
												}
											</div>
										)}
										<div className="heading-actions">

										</div>
									</div>
								)}

								<div
									className={`full-plan-container ${isExtendedView || !hasPrivileges ? "extended-view" : "normal-view"} ${showMax ? "maximized" : ""}`}
								>
									{(hasPrivileges && PLAN_GRID_VIEWS.ubications === plan_grid_view) && this.getUbicationFilter()}
									{(hasPrivileges && (PLAN_GRID_VIEWS.plan === plan_grid_view || PLAN_GRID_VIEWS.hours === plan_grid_view || PLAN_GRID_VIEWS.vertical === plan_grid_view)) && this.getShiftFilter()}
									{(hasPrivileges && PLAN_GRID_VIEWS.combo === plan_grid_view) && this.getComboFilter()}
									{this.getPlanContent()}
								</div>
							</section>
						)}

						{hasPrivileges && (
							<>
								<ShiftContextMenu
									isUbicationListSelected={isUbicationListSelected}
								/>
								<DayContextMenu
									isShiftListSelected={isShiftListSelected}
									isUbicationListSelected={isUbicationListSelected}
									isCopiedPlanSelected={isCopiedPlanSelected}
									isCutSelected={isCutSelected}
								/>
								<StaffingContextMenu isSelected={isNeedSelected} />
							</>
						)}
					</div>
				</Layout>

				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.functionsReducer,
		...reducers.employeesReducer,
		...reducers.shiftsReducer,
		...reducers.rulesReducer,
		...reducers.timesReducer,
		...reducers.plansReducer,
		...reducers.groupsReducer,
		...reducers.ubicationsReducer,
		user: reducers.authReducer.user,
		loading: reducers.plansReducer.loading,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	updateModalLoading: (isLoading) => dispatch(updateModalLoading(isLoading)),
	getDefaultShift: () => dispatch(shiftsActions.getDefaultShift()),
	getWorkingHours: () => dispatch(shiftsActions.getWorkingHours()),
	removeLoading: () => dispatch(plansActions.removeLoading()),
	setPlanAssignments: (options) =>
		dispatch(plansActions.setPlanAssignments(options)),
	getPlanViews: () => dispatch(plansActions.getPlanViews()),
	getDefaultPlanView: () => dispatch(plansActions.getDefaultPlanView()),
	getAccountAssignments: (options) =>
		dispatch(rulesActions.getAccountAssignments(options)),
	getStaffingLevelAssignments: (options) => dispatch(groupsActions.getStaffingLevelAssignments(options)),
	getStaffingLevelCurrentAssignments: (options) => dispatch(groupsActions.getStaffingLevelCurrentAssignments(options)),
	getStaffingLevelHourlyAssignments: (options) =>
		dispatch(groupsActions.getStaffingLevelHourlyAssignments(options)),
	getPlanDays: (group_id, start, end) =>
		dispatch(plansActions.getPlanDays(group_id, start, end)),
	getAllShifts: (options) => dispatch(shiftsActions.getAllShifts(options)), // necessitem tots, no selectable, per poder agafar tota la info
	getAttendanceShifts: () => dispatch(shiftsActions.getAttendanceShifts()),
	getShiftTypes: () => dispatch(shiftsActions.getShiftTypes()),
	getSelectableFunctions: () => dispatch(functionsActions.getSelectableFunctions()),
	getSelectableTimeTypes: () => dispatch(timesActions.getSelectableTimeTypes()),
	getSelectableUbications: (options) => dispatch(ubicationsActions.getSelectableUbications(options)),
	getSelectableEmployees: (options) => dispatch(employeesActions.getSelectableEmployees(options)), // autosuggest filter
	getSelectableStaffingLevels: () => dispatch(groupsActions.getSelectableStaffingLevels()),

});

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
