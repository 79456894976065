import React, { Fragment } from "react"
import moment from 'moment';
import { FaLock, FaComment } from "react-icons/fa";
import { RiPencilFill } from "react-icons/ri";
import { ImClock } from "react-icons/im";
import { GoAlert } from "react-icons/go";
import { OverlayTrigger, Tooltip, } from "react-bootstrap";
import { ContextMenuTrigger } from "react-contextmenu";
import ShiftPreviewTooltip from 'components/Shifts/ShiftPreviewTooltip';
const MENU_SHIFT_ID = "menu_shift";
const MENU_DAY_ID = "menu_day";
import { getContrastColor } from "utils/utils";


function collect(props) {
	return props;
}


const getRowContent = (props) => props.plan_days.map((d, i) => {
	// console.log(props);
	const key = `${d.day}_${i}`;

	return (
		<tr key={key}>
			{getDayHeader(d)}
			{getPlanRowContent(props, d, i)}
		</tr>
	)
});

const getDayHeader = (d) => {
	const day = moment(d.day).format("ddd");
	const dayNum = moment(d.day).format("DD/MM");
	const details = d.details;

	// console.log(d);
	let day_type;

	// TODO adaptar multigrup
	if (details.length > 0) {
		if (details[0].special_day)
			day_type = details[0].special_day.day_type;

		if (details[0].weekday)
			day_type = details[0].weekday.day_type;
	}

	const color = day_type.color;
	const key = `th_${day}`;
	return (
		<th
			className="th-day th-day-extended"
			style={{ color: color }}
			key={key}
		>
			<div className="th-day-content">
				<span className="span-day">{day}</span>
				<span className="span-num">{dayNum}</span>
			</div>
		</th>
	)
};

// contenido celdas turnos
const getPlanRowContent = (props, day, row_inv) => props.plan_assignments.map((plan_assignment, i_inv) => {

	const employee = plan_assignment.employee;
	const item = plan_assignment.plans.find(item => item.day === day.day);
	const checked = item.checked || false;

	// fix para invertir filas y columnas
	const row = i_inv;
	const i = row_inv;

	const cut = props.day_selection_copy.length > 0 && props.day_selection_copy.find(item => item.row === row && item.index === i);
	const dashed = props.day_selection_dashed.length > 0 && props.day_selection_dashed.find(item => item.row === row && item.index === i);
	// const cut = props.day_selection_copy.length > 0;
	const key = `td_${day.day}_${row}_${i}`;

	if (item.active) {
		if (item.assignments.length === 0) {
			item.assignments = [{ id: -1, shift: props.default_shift }];
		}

		return (
			<td
				key={key}
				className={`shift-cell btn-checkbox ${checked ? "active" : ""} ${cut ? "cut" : ""} ${dashed ? "dashed" : ""}`}
				onDragOver={(e) => onDragOver(e)}
				onDrop={(e) => onShiftsDrop(e, props, row, i)}
				data={{ action: "paste-shift" }}
			>
				<ContextMenuTrigger
					id={MENU_DAY_ID}
					holdToDisplay={1000}
					collect={collect}
					hasShifts={item.shifts ? true : false}
					onItemClick={props.handleDayMenuClick}
				>
					<input
						type="checkbox"
						id={`day_${row}_${i}`}
						onChange={(e) =>
							props.hasPrivileges && props.handleDaySelection(row, i, e)
						}
						checked={checked}
					/>
					<label
						htmlFor={`day_${row}_${i}`}
						className="btn-check-label"
						onContextMenu={() => props.selectSingleDay(row, i)}
						draggable
						onDragEnter={(e) => props.selectSingleDay(row, i)}
					></label>

					<ul className="shift-list-items">
						{getAssignedShifts(props, employee, item, row, i)}
					</ul>
				</ContextMenuTrigger>
			</td>
		);
	} else {
		return <td key={i} className="shift-cell disabled"></td>;
	}
});


const getAssignedShifts = (props, employee, sp_item, row, index) =>
	sp_item.assignments.map((item) => {
		const shift = item.shift;

		// time intervals editados
		// const editedTimes = item.time_intervals && item.time_intervals.find(item => item.time_type.work_time === false);
		const editedTimes = item.time_intervals_from_manual && item.time_intervals && item.time_intervals.length > 0;

		const day_selection = props.day_selection;
		const plan = {
			employee: employee,
			day: sp_item.day,
			shift_plan: item,
		};

		const shiftname = shift.description ? shift.name + " - " + shift.description : shift.name;

		const tooltip_alerta = <Tooltip className="tooltip-alerta">
			<ul>
				{item.alert_min_rest_hours && (<li><GoAlert className="tooltip-icon icon-alert" /> No se cumple el mínimo de horas entre turnos</li>)}
				{item.alert_max_consecutive_working_days && (<li><GoAlert className="tooltip-icon icon-alert" /> No se cumple el máximo de días de trabajo consecutivos</li>)}
			</ul>
		</Tooltip>;

		const tooltip_turno = <Tooltip className="tooltip-shift"><ShiftPreviewTooltip shift={shift} time_intervals={shift.time_intervals} /></Tooltip>;


		// console.log(sp_item.day);
		// console.log(item);
		return (
			<OverlayTrigger overlay={tooltip_turno}>
				<li
					key={shift.id}
					title={`${shiftname}`}
					className="shift-item"
					style={{
						backgroundColor: shift.background_color && shift.background_color,
						borderColor: shift.border_color && shift.border_color,
						color: getContrastColor(shift.background_color),
					}}
					onClick={(e) =>
						props.hasPrivileges && props.handleDaySelection(row, index, e)
					}
				>
					<ContextMenuTrigger
						id={day_selection.length > 0 ? MENU_DAY_ID : MENU_SHIFT_ID}
						holdToDisplay={1000}
						collect={collect}
						onItemClick={
							day_selection.length > 0
								? props.handleDayMenuClick
								: props.handleShiftMenuClick
						}
						targetId={item.id}
						plan={plan}
					>
						{shift.id !== props.default_shift.id
							? shift.name
							: "-"}

						{item.locked && <FaLock className="plan-icon icon-locked" />}
						{item.time_intervals_from_override_time_registrations && (
							<ImClock className="plan-icon icon-clock" />
						)}
						{item.comments && (
							<FaComment className="plan-icon icon-comments" />
						)}
						{(item.alert_min_rest_hours || item.alert_max_consecutive_working_days) && (
							<OverlayTrigger overlay={tooltip_alerta}>
								<GoAlert className="plan-icon icon-alert" />
							</OverlayTrigger>
						)}
						{editedTimes && <RiPencilFill className="plan-icon icon-edited" />}
					</ContextMenuTrigger>
				</li>
			</OverlayTrigger>
		);
	});



const onDragOver = (e) => {
	e.preventDefault();
}

const onShiftsDrop = (e, props, row, i) => {
	props.selectSingleDay(row, i);
	props.handleDayMenuClick(e, { action: "paste-shift" });
}

const PlanTableBodyInverted = (props) => {
	//console.log(props);
	return (
		<tbody>
			{getRowContent(props)}
		</tbody>
	)
}

export default PlanTableBodyInverted