import React, { Component } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import Layout from "components/Layout";
import { Nav, Tab, FormGroup, FormControl, Dropdown } from "react-bootstrap";
import { FiPlus, FiExternalLink, FiMoreHorizontal, FiDownload, FiUpload, } from "react-icons/fi";
import Moment from 'react-moment';
import Loading from "components/Loading";
import AbsenceCalendar from "components/Absences/AbsenceCalendar";
import MyAbsences from "components/Absences";
import AbsenceEditModal from "components/Absences/AbsenceEditModal";
import AbsenceHeader from "components/Absences/AbsenceHeader";
import { showModal, hideModal, updateModalLoading } from "actions/modalActions";
import * as absencesActions from "actions/absencesActions";
import * as employeesActions from "actions/employeesActions";
import * as shiftsActions from "actions/shiftsActions";
import { workflowsService, appService } from "services";
import Modal from "components/Modal";
import { isAdmin, isSupervisor } from "utils/utils";

class Absences extends Component {
	constructor(props) {
		super(props);
		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openNewAbsenceModal = this.openNewAbsenceModal.bind(this);
		this.openEditAbsenceModal = this.openEditAbsenceModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
		this.handleCancelRequest = this.handleCancelRequest.bind(this);

		const today = new Date();
		const year = today.getFullYear();


		this.state = {
			year,
			search_employee: this.props.user.id,
			search_employee_name: this.props.user.name,
			isSubmit: false,
			show_worked_holidays: false,
			worked_holidays: [],
		};
	}

	async componentDidMount() {
		this.getData();
	}


	async getData() {
		this.setState({ loading: true })  // loading

		var contract_absence_shifts = true; // no recuerdo por qué se ponía a true
		await Promise.all([
			this.props.getEmployeeAbsences(this.state.search_employee),
			this.props.getEmployeeAbsenceCalendar(this.state.search_employee, this.state.year),
			this.props.getSelectableEmployees(),
			this.props.getAbsenceShifts({ shift_type: "Ausencia,Vacaciones", contract_only: contract_absence_shifts })
		]);

		const worked_holidays = this.props.employee_absence_calendar.filter((o) => {
			return o.worked_holiday === true;
		});


		this.setState({
			loading: false,
			worked_holidays
		})
	}


	toggleWorkedHolidays() {
		this.setState(prevState => ({
			show_worked_holidays: !prevState.show_worked_holidays
		}));
	}


	handleChangeSearch = event => {
		const target_id = event.target.id;
		var search_employee_name = this.state.search_employee_name;

		if (target_id === 'search_employee') {
			search_employee_name = event.target.options[event.target.selectedIndex].text;
		}
		// console.log(event.target.options[event.target.selectedIndex].text)

		this.setState({
			[target_id]: event.target.value,
			search_employee_name,
		}, async () => {

			await this.props.getEmployeeAbsenceCalendar(this.state.search_employee, this.state.year);

			if ((isAdmin() || isSupervisor()) && target_id === 'search_employee') {
				await this.props.getEmployeeAbsences(this.state.search_employee);
			}

		});
	}

	closeModal() {
		this.props.hideModal();
	}

	openNewAbsenceModal() {
		this.props.showModal(
			{
				open: true,
				title: "Nueva ausencia",
				style: { width: "430px" },
				content: <AbsenceEditModal handleClose={this.closeModal} />,
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	openEditAbsenceModal(absence, disabled) {
		this.props.showModal(
			{
				open: true,
				title: disabled ? "Ver ausencia" : "Editar ausencia",
				style: { width: "430px" },
				content: (
					<AbsenceEditModal
						absence={absence}
						disabled={disabled}
						handleClose={this.closeModal}
					/>
				),
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	openAlertModal(message, title) {
		this.props.showModal(
			{
				open: true,
				title: title || "Error",
				style: { width: "400px" },
				message: message,
				closeModal: this.closeModal,
			},
			"alert"
		);
	}

	handleCancelRequest(absence) {
		this.props.showModal({
			open: true,
			title: 'Cancelar ausencia',
			style: { width: '350px' },
			message: '¿Desea cancelar la ausencia? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmCancelRequest(absence),
			closeModal: this.closeModal
		}, 'delete')
	}

	confirmCancelRequest = async (absence) => {
		absence.status = "Anulado";
		absence.shift_id = absence.shift.id;

		if (!this.state.isSubmit) {
			this.setState({ isSubmit: true });
			const response = await workflowsService.update(absence);
			this.setState({ isSubmit: false });

			// console.log(response);
			if (response.ok) {
				toastr.success("¡Bien!", "Cambios guardados correctamente");

				this.props.getEmployeeAbsences(this.state.search_employee);
				this.closeModal();
			} else {

				if (
					response.errors !== undefined &&
					Object.keys(response.errors).length > 0
				) {
					let textError = response.errors[0].description;
					this.openAlertModal(textError, "Error al cancelar");
				}
				else {
					this.openAlertModal("Ha ocurrido un error", "Error al cancelar");
				}

			}
		}
	}


	// exportar

	openExportAbsencesModal(token) {
		this.props.showModal(
			{
				open: true,
				title: "Exportar ausencias",
				style: { width: "400px" },
				confirmText: "Descargar",
				loadingText: "Descargando...",
				message:
					"Las ausencias se han exportado correctamente. Haz click en Descargar para iniciar la descarga",
				closeModal: this.closeModal,
				confirmAction: () => {
					this.props.updateModalLoading(true);
					this.downloadExport(token, "ausencias.xlsx")
				},
			},
			"confirm"
		);
	}

	async handleExportAbsences(workflow_status) {
		// TODO const { start_to, start_from, employee_id } = this.state;

		// Aprobado,Denegado (Gestionadas)  / Pendiente (Pendientes)
		const token = await workflowsService.exportWorkflows({
			workflow_status,
		});

		if (token.ok) {
			this.openExportAbsencesModal(token.access_token);
		} else {
			this.openAlertModal(
				"Ha ocurrido un error en la exportación",
				"Error al exportar"
			);
		}
	}

	async downloadExport(token, filename) {
		const response = await appService.getExported(token);
		var url = window.URL.createObjectURL(response);
		var a = document.createElement("a");
		document.body.appendChild(a);
		a.href = url;
		a.download = filename;
		a.click();

		this.props.updateModalLoading(false); // Cambiar a no cargando
		this.closeModal(); // cerrar modal de confirmación
	}

	getContent = () => {
		if (this.props.loading && !this.props.employee_absences) {
			return <Loading />;
		}

		if (!this.props.employee_absences) {
			return "Error cargando las ausencias";
		}

		let first_steps = "";

		if (
			this.props.employee_absences.length == 0 &&
			this.props.pending_absences.length === 0 &&
			this.props.managed_absences.length === 0
		) {
			const step_text =
				isAdmin() || isSupervisor()
					? "Desde aquí podrás aprobar o denegar las peticiones de ausencia de los empleados"
					: "Desde aquí podrás solicitar vacaciones o ausencias para tu supervisor te las apruebe";

			first_steps = (
				<div className="first-steps">
					<FiExternalLink className="icono-pasos" />
					<h3 className="title2">Primeros pasos</h3>

					<p>
						{step_text}. Aquí te explicamos cómo funcionan:{" "}
						<a
							className="alink"
							target="_blank"
							href="https://help.plain.ninja/es/articles/3693962-que-es-una-ausencia"
						>
							https://help.plain.ninja/es/articles/3693962-que-es-una-ausencia
						</a>
					</p>
				</div>
			);
		}

		const { worked_holidays, show_worked_holidays, search_employee, search_employee_name } = this.state;


		var title_mis_ausencias = "Mis ausencias";

		if (this.props.user.id !== search_employee * 1)
			title_mis_ausencias = "Ausencias de " + search_employee_name;

		return (
			<>
				{first_steps}
				<Tab.Container defaultActiveKey="calendar">
					<Nav variant="tabs">
						<Nav.Item>
							<Nav.Link eventKey="calendar">Calendario de ausencias</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="mine">{title_mis_ausencias}</Nav.Link>
						</Nav.Item>
					</Nav>
					<Tab.Content>
						<Tab.Pane eventKey="calendar">
							<AbsenceCalendar />
							<div className="box box-total">
								<div className="box-total-header">
									<h4>Total días festivos trabajados: <strong>{worked_holidays.length}</strong></h4>
									<button type="button" className='button-link' onClick={() => this.toggleWorkedHolidays()}>{show_worked_holidays ? "Ocultar detalle" : "Mostrar detalle"}</button>
								</div>

								{show_worked_holidays && (
									<ul className="list-worked-holidays">
										{worked_holidays.map((item, i) => {
											return (
												<li key={i}>
													<span className="tag-day-type" style={{ backgroundColor: item.day_type.color && item.day_type.color }}>{item.day_type.name}</span>
													<Moment format="DD/MM/YY">{item.day}</Moment>
													<span>{item.comments}</span>
												</li>
											)
										})}
									</ul>
								)}

							</div>
						</Tab.Pane>
						<Tab.Pane eventKey="mine">
							<MyAbsences
								handleCancelRequest={this.handleCancelRequest}
								openEditAbsenceModal={this.openEditAbsenceModal}
							/>
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
			</>
		);
	};

	render() {
		const { select_employees } = this.props;
		const currentYear = (new Date()).getFullYear();
		const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

		const optionYearList = range(currentYear + 1, 2020, -1).map((item, i) => {
			return (
				<option key={i} value={item}>{item}</option>
			)
		}, this);

		const employee_in_list = select_employees.some((e) => e.id === this.props.user.id);
		if (!employee_in_list)
			select_employees.push(this.props.user)

		let employeeList = select_employees.length > 0 &&
			select_employees.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name} {item.surname}
					</option>
				);
			}, this);

		return (
			<>
				<Layout className="page-absences" context="absences">
					<div className="heading">
						<h1 className="title">
							{isAdmin() || isSupervisor() ? "Ausencias" : "Mis ausencias"}
						</h1>

						<div className="heading-actions">
							<FormGroup
								className="form-select-user mr10"
								controlId="search_employee"
							>
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={this.state.search_employee}
									className={!isAdmin() && !isSupervisor() || select_employees.length < 1 ? 'hidden' : ''}
									onChange={this.handleChangeSearch}
									as="select"
								>
									{employeeList}
								</FormControl>
							</FormGroup>

							<FormGroup controlId="year">
								<FormControl
									type="text"
									value={this.state.year}
									onChange={this.handleChangeSearch}
									as="select"
								>
									{optionYearList}
								</FormControl>
							</FormGroup>

							<button
								type="button"
								title="Crear ausencia"
								onClick={this.openNewAbsenceModal}
								className="btn btn-new"
							>
								<FiPlus />
							</button>

							<Dropdown>
								<Dropdown.Toggle variant="action">
									<FiMoreHorizontal />
								</Dropdown.Toggle>
								<Dropdown.Menu alignRight>
									<Dropdown.Item
										as="button"
										onClick={() =>
											this.props.history.push("/absences/import")
										}
									>
										<FiDownload /> Importar ausencias
									</Dropdown.Item>

									<Dropdown.Item
										as="button"
										onClick={() => this.handleExportAbsences("Pendiente")}
									>
										<FiUpload className="dropdown-icon" /> Exportar ausencias pendientes
									</Dropdown.Item>

									<Dropdown.Item
										as="button"
										onClick={() => this.handleExportAbsences("Aprobado,Denegado")}
									>
										<FiUpload className="dropdown-icon" /> Exportar ausencias gestionadas
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					<AbsenceHeader employee_id={this.state.search_employee} year={this.state.year} />

					{this.getContent()}
				</Layout>

				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.shiftsReducer,
		...reducers.absencesReducer,
		...reducers.employeesReducer,
		user: reducers.authReducer.user,
		loading: reducers.absencesReducer.loading,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	updateModalLoading: (isLoading) => dispatch(updateModalLoading(isLoading)),
	getEmployeeAbsences: (employee_id) => dispatch(employeesActions.getEmployeeAbsences(employee_id)),
	getEmployeeAbsenceCalendar: (employee_id, year) => dispatch(employeesActions.getEmployeeAbsenceCalendar(employee_id, year)),
	getPendingAbsences: (options) => dispatch(absencesActions.getPendingAbsences(options)),
	getManagedAbsences: (options) => dispatch(absencesActions.getManagedAbsences(options)),
	getAbsenceShifts: (options) => dispatch(shiftsActions.getAllShifts(options)),
	getSelectableEmployees: (options) => dispatch(employeesActions.getSelectableEmployees(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Absences);
