import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel, Row, Col } from "react-bootstrap";
import { IoMdColorFill } from "react-icons/io";
import { FiDelete, FiX, FiClock, FiTrash2 } from "react-icons/fi";
import moment from 'moment';
import { shiftsService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import Switch from "components/Switch";
import * as shiftsActions from 'actions/shiftsActions';
import { TwitterPicker } from 'react-color';
import "./Shifts.scss";

class ShiftEdit extends React.Component {

	constructor(props) {
		super(props);
		// console.log(props);

		let shift = {
			type: '',
			name: '',
			description: '',
			background_color: '',
			border_color: '',
			time_intervals: [],
			allow_hourly_absence_request: false,
			has_groups: false,
			group_ids: []
		}

		let isEdit = false;
		let selected_day_types = [];
		let selected_groups = [];
		let show_timetype_disclaimer = false;

		if (props.shift) {
			isEdit = true;
			shift = props.shift;

			if (shift.time_intervals === undefined)
				shift.time_intervals = [];
			else
				show_timetype_disclaimer = this.checkTimeTypeDisponibilidad(shift.time_intervals);

			selected_day_types = shift.day_types;
			selected_groups = shift.groups || [];
		}

		this.state = {
			isEdit,
			isLoading: false,
			isBorderPickerVisible: false,
			isBackgroundPickerVisible: false,
			submitted: false,
			errors: {},
			new_day_type: {},
			new_group: {},
			new_interval: {},
			selected_day_types,
			selected_groups,
			new_interval_errors: {},
			shift,
			show_timetype_disclaimer,
		};
	}

	handleChange = event => {
		const { shift } = this.state;

		this.setState({
			shift: {
				...shift,
				[event.target.id]: event.target.value
			},
		});
	}

	handleSwitch = (event) => {
		const { shift } = this.state;

		this.setState({
			shift: {
				...shift,
				[event.target.id]: event.target.checked,
			},
		});
	};

	handleColorChange = ({ hex }, type) => {
		const { shift } = this.state;

		if (hex === undefined)
			hex = '';

		this.setState({
			shift: {
				...shift,
				[type]: hex
			},
			isBackgroundPickerVisible: false,
			isBorderPickerVisible: false,
		});
	}

	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	prepareDayTypesToSubmit = () => {
		const { selected_day_types } = this.state;

		return selected_day_types.map((day_type) => (day_type.id));
	}

	prepareGroupsToSubmit = () => {
		const { selected_groups } = this.state;

		return selected_groups.map((group) => (group.id));
	}

	prepareTimeIntervalsToSubmit = () => {
		const { shift } = this.state;

		return shift.time_intervals.map((time_interval) => {

			const new_interval = {
				start: time_interval.start,
				end: time_interval.end,
				time_type_id: time_interval.time_type_id,
			}

			if (!isNaN(time_interval.id))
				new_interval.id = time_interval.id;

			if (new_interval.time_type_id === undefined)
				new_interval.time_type_id = time_interval.time_type.id;

			return new_interval
		}
		);
	}

	handleSubmit = async event => {
		event.preventDefault();
		const { isEdit, shift } = this.state;

		this.setState({ isLoading: true });
		let response = '';
		const props = this.props;

		const shift_temp = {
			...shift,
			day_type_ids: this.prepareDayTypesToSubmit(),
			group_ids: this.prepareGroupsToSubmit(),
			time_intervals: this.prepareTimeIntervalsToSubmit()
		}

		// TEMP fix mentre el backend no valida que hi hagi group_ids quan has_groups és true
		if (shift.has_groups && shift_temp.group_ids.length === 0)
			shift_temp.has_groups = false;

		// if edit => update; else => new
		if (isEdit) {
			response = await shiftsService.update(shift_temp);
		}
		else {
			response = await shiftsService.add(shift_temp);
		}

		// console.log(response);

		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
			}, () => {

				toastr.success('¡Bien!', 'Cambios guardados correctamente');
				// this.props.getAllShifts({});

				const { list_state } = props;
				if (list_state)
					props.getAllShifts({ q: list_state.searchShift, shift_type: list_state.filterTypeId });
				else
					props.getAllShifts({});

				props.handleClose();

			});
		}
		else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: this.extendedErrorFormatting(response)
			});
		}
	}

	extendedErrorFormatting = (response) => {
		const errors = errorFormating(response);

		if (response.errors.length > 0) {
			response.errors.map((item) => {
				if (item.key.includes("time_intervals")) {
					errors.time_intervals = item.description;
				}
			});
		}
		return errors;
	}

	handleAddChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	}

	// add/remove tipos de día
	addDayType = () => {
		let { selected_day_types, new_day_type } = this.state;
		let isValid = true;
		let day_type;
		const errors = {};

		if (new_day_type === undefined) {
			isValid = false;
		}
		else {
			day_type = this.props.select_day_types.find(item => item.id === parseInt(new_day_type));
			if (day_type === undefined) {
				isValid = false;
				errors.day_type = false;
			}
		}

		if (isValid) {
			// check if day already exists
			const exists = selected_day_types.find(item => item.id === parseInt(new_day_type));

			if (!exists) {
				selected_day_types = selected_day_types.concat(day_type);
			}

			this.setState({
				selected_day_types,
				errors: {},
				new_day_type: {},
			});
		}
		else {
			this.setState({
				errors,
			});
		}
	}

	addBulkDayTypes = (start, end) => {
		const selected_day_types = [];
		const { select_day_types } = this.props;

		for (let i = start; i <= end; i++) {
			const day_type = select_day_types.find(item => item.weekday === i);
			selected_day_types.push(day_type)
		}

		this.setState({
			selected_day_types,
			errors: {},
			new_day_type: {},
		});
	}

	removeDayType = (idDayType) => {
		this.setState((prevState, props) => ({
			selected_day_types: prevState.selected_day_types.filter(item => item.id !== parseInt(idDayType))
		}));
	}

	// add/remove grupos a los que aplica
	addGroup = () => {
		let { selected_groups, new_group } = this.state;
		let isValid = true;
		let group;
		const errors = {};

		if (new_group === undefined) {
			isValid = false;
		}
		else {
			group = this.props.groups.find(item => item.id === parseInt(new_group));
			if (group === undefined) {
				isValid = false;
				errors.group = false;
			}
		}

		if (isValid) {
			// check if group already exists
			const exists = selected_groups.find(item => item.id === parseInt(new_group));

			if (!exists) {
				selected_groups = selected_groups.concat(group);
			}

			this.setState({
				selected_groups,
				errors: {},
				new_group: {},
			});
		}
		else {
			this.setState({
				errors,
			});
		}
	}

	removeGroup = (idGroup) => {
		this.setState((prevState, props) => ({
			selected_groups: prevState.selected_groups.filter(item => item.id !== parseInt(idGroup))
		}));
	}



	// findTimeTypeName = (id) => {
	// 	const { select_time_types } = this.props;
	// 	const time_type = select_time_types.find(item => item.id === parseInt(id));
	// 	return time_type.name;
	// }

	// time intervals
	handleChangeInterval = event => {
		const { new_interval } = this.state;
		// var selected_timetype = this.state.selected_timetype;

		const key = event.target.id;
		// if (key === "time_type_id") {
		// 	selected_timetype = this.findTimeTypeName(event.target.value);
		// }

		this.setState({
			new_interval: {
				...new_interval,
				[key]: event.target.value
			},
			// selected_timetype
		});
	}

	addTimeInterval = () => {
		const { shift, new_interval } = this.state;
		const hourFormat = "hh:mm";
		let isValid = true;
		const new_interval_errors = {};
		const time_type = this.props.select_time_types.find(dt => dt.id === parseInt(new_interval.time_type_id));

		if (time_type === undefined) {
			isValid = false;
			new_interval_errors.time_type_id = false;
		}

		if (!new_interval.start) {
			isValid = false;
			new_interval_errors.start = false;
		}

		if (!new_interval.end) {
			isValid = false;
			new_interval_errors.end = false;
		}

		if (isValid) {
			const start = moment(new_interval.start, hourFormat);
			const end = moment(new_interval.end, hourFormat);

			// if (start.isSameOrAfter(end)) {
			// 	isValid = false;
			// 	new_interval_errors.start = false;
			// 	new_interval_errors.end = false;
			// }
		}

		if (isValid) {
			new_interval.time_type = time_type;

			let time_intervals = shift.time_intervals.concat(new_interval);
			time_intervals = time_intervals.sort(function (a, b) {
				a = moment(a.start, hourFormat);
				b = moment(b.start, hourFormat);
				return a.isBefore(b) ? -1 : b.isBefore(a) ? 1 : 0;
			});

			// check if time_intervals contain a time_type with the name "Disponibilidad"
			const show_timetype_disclaimer = this.checkTimeTypeDisponibilidad(time_intervals);

			this.setState({
				shift: {
					...shift,
					time_intervals
				},
				new_interval: {
					time_type_id: '',
					start: '',
					end: '',
				},
				new_interval_errors: {},
				show_timetype_disclaimer
			});
		}
		else {
			this.setState({
				new_interval_errors,
			});
		}
	}

	checkTimeTypeDisponibilidad = (time_intervals) => {
		if (time_intervals.length === 0) return false;

		return time_intervals.find(item => item.time_type.name === "Disponibilidad");
	}

	removeTimeInterval = (interval) => {
		const { shift } = this.state;
		const time_intervals = shift.time_intervals.filter(item => (item.start === interval.start && item.end === interval.end && item.time_type_id === interval.time_type_id) === false);
		const show_timetype_disclaimer = this.checkTimeTypeDisponibilidad(time_intervals);

		this.setState({
			shift: {
				...shift,
				time_intervals,
			},
			show_timetype_disclaimer,
		});
	}


	getSelectedDayTypes = () => this.state.selected_day_types.map((item) => {
		return (
			<li key={item.id} aria-label={item.description} title={item.description} className="tag-default tag-delete" style={{ backgroundColor: item.background_color && item.background_color }}>
				{item.name}
				<button type="button" onClick={() => this.removeDayType(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});

	getSelectedGroups = () => this.state.selected_groups.map((item) => {
		return (
			<li key={item.id} aria-label={item.description} title={item.description} className="tag-default tag-delete" style={{ backgroundColor: item.background_color && item.background_color }}>
				{item.name}
				<button type="button" onClick={() => this.removeGroup(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});

	getTimeIntervalsBody = () => this.state.shift.time_intervals.map((interval, i) => {
		return (
			<tr key={i}>
				<td>{interval.time_type.name}</td>
				<td>{interval.start}</td>
				<td>{interval.end}</td>
				<td className="td-actions"><button type="button" className="btn btn-icon" onClick={() => this.removeTimeInterval(interval)}><FiTrash2 /></button></td>
			</tr>
		)
	});


	render() {
		const { shift, new_day_type, new_group, errors, new_interval, new_interval_errors, selected_day_types, show_timetype_disclaimer } = this.state;
		const { shift_types, select_day_types, groups, select_time_types } = this.props;
		const optionList = shift_types.length > 0 && shift_types.map((item, i) => {
			return (
				<option key={i} value={item.id}>{item.name}</option>
			)
		}, this);

		const optionDayTypeList = select_day_types.length > 0 && select_day_types.map((item, i) => {

			const label = item.description ? (item.name + " (" + item.description + ")") : item.name;
			return (
				<option key={i} value={item.id}>{label}</option>
			)
		}, this);

		const optionGroupList = groups.length > 0 && groups.map((item, i) => {

			const label = item.description ? (item.name + " (" + item.description + ")") : item.name;
			return (
				<option key={i} value={item.id}>{label}</option>
			)
		}, this);

		const optionTimeTypeList = select_time_types.length > 0 && select_time_types.map((item, i) => {
			return (
				<option key={i} value={item.id}>{item.name}</option>
			)
		}, this);

		const rest_time_list = select_time_types.filter((t) => t.work_time === false);
		const optionRestTimeTypeList = rest_time_list.map((item, i) => {
			return (
				<option key={i} value={item.id}>{item.name}</option>
			)
		}, this);

		const onToggleBackgroundPicker = () => this.setState({ isBackgroundPickerVisible: !this.state.isBackgroundPickerVisible })
		const onToggleBorderPicker = () => this.setState({ isBorderPickerVisible: !this.state.isBorderPickerVisible })


		const allowHourly = shift.allow_hourly_absence_request;
		const hasGroups = shift.has_groups;

		return (

			<form onSubmit={this.handleSubmit}>
				<div className="modal-body shift-edit">

					<Row>
						<Col sm={6} md={4}>
							<FormGroup controlId="name">
								<FormLabel>Nombre corto <span className="label-required">*</span></FormLabel>
								<FormControl
									type="text"
									value={shift.name}
									onChange={this.handleChange}
									placeholder="Nombre"
									isInvalid={this.checkValidField('name')}
								/>

								<FormControl.Feedback type="invalid">
									{errors.name}
								</FormControl.Feedback>
							</FormGroup>
						</Col>
						<Col sm={6} md={8}>
							<FormGroup controlId="description">
								<FormLabel>Descripción</FormLabel>
								<FormControl
									//as="textarea"
									value={shift.description}
									onChange={this.handleChange}
									placeholder="Descripción del turno"
									isInvalid={this.checkValidField('description')}
								/>

								<FormControl.Feedback type="invalid">
									{errors.description}
								</FormControl.Feedback>
							</FormGroup>
						</Col>


						<Col sm={6} md={3}>
							<FormGroup controlId="background_color" className="form-color">
								<div className="flex-between">
									<FormLabel>Color fondo</FormLabel>
									<FiDelete className={`icon-remove-color ${!shift.background_color && 'hidden'} `} onClick={() => this.handleColorChange("", "background_color")} />
								</div>
								<FormControl
									type="text"
									value={shift.background_color}
									placeholder="#FFFFFF"
									readOnly
									isInvalid={this.checkValidField('background_color')}
									style={{ backgroundColor: shift.background_color && shift.background_color }}
									onClick={onToggleBackgroundPicker}
								/>
								<FormControl.Feedback type="invalid">
									{errors.background_color}
								</FormControl.Feedback>

								<IoMdColorFill onClick={onToggleBackgroundPicker} />

								{this.state.isBackgroundPickerVisible && (
									<div style={{ position: 'absolute' }}>
										<TwitterPicker
											color={shift.background_color ? shift.background_color : '#ffffff'}
											onChangeComplete={(hex) => this.handleColorChange(hex, "background_color")}
										/>
									</div>
								)}
							</FormGroup>
						</Col>
						<Col sm={6} md={3}>
							<FormGroup controlId="border_color" className="form-color">

								<div className="flex-between">
									<FormLabel>Color borde</FormLabel>
									<FiDelete className={`icon-remove-color ${!shift.border_color && 'hidden'} `} onClick={() => this.handleColorChange("", "border_color")} />
								</div>

								<FormControl
									type="text"
									value={shift.border_color}
									placeholder="#FFFFFF"
									readOnly
									isInvalid={this.checkValidField('border_color')}
									style={{ backgroundColor: shift.border_color && shift.border_color }}
									onClick={onToggleBorderPicker}
								/>
								<FormControl.Feedback type="invalid">
									{errors.border_color}
								</FormControl.Feedback>

								<IoMdColorFill onClick={onToggleBorderPicker} />

								{this.state.isBorderPickerVisible && (
									<div style={{ position: 'absolute' }}>
										<TwitterPicker
											color={shift.border_color ? shift.border_color : '#ffffff'}
											onChangeComplete={(hex) => this.handleColorChange(hex, "border_color")}
										/>
									</div>
								)}
							</FormGroup>
						</Col>
						<Col sm={6} md={6}>
							<FormGroup controlId="type">
								<FormLabel>Tipo de turno <span className="label-required">*</span></FormLabel>
								<FormControl
									type="text"
									value={shift.type}
									onChange={this.handleChange}
									isInvalid={this.checkValidField('type')}
									as="select">
									<option value="">Tipo de turno</option>
									{optionList}
								</FormControl>

								<FormControl.Feedback type="invalid">
									{errors.type}
								</FormControl.Feedback>
							</FormGroup>
						</Col>
					</Row>

					{(shift.type === "Ausencia" || shift.type === "Vacaciones") &&
						<FormGroup
							className="form-group-flex"
							controlId="allow_hourly_absence_request"
						>
							<FormLabel>Permitir peticiones de <span className="lowercase">{shift.type}</span> por horas</FormLabel>
							<Switch
								controlId="allow_hourly_absence_request"
								isOn={allowHourly}
								handleToggle={this.handleSwitch}
							/>
						</FormGroup>
					}

					{shift.type === "Ausencia" && allowHourly &&
						<FormGroup controlId="time_type_id">
							<FormLabel>Selecciona un tipo de tiempo <span className="label-required">*</span></FormLabel>
							<FormControl
								placeholder="Seleccionar tipo"
								isInvalid={this.checkValidField('time_type_id')}
								onChange={this.handleChange}
								value={shift.time_type_id}
								as="select"
							>
								<option value="">Selecciona</option>
								{optionRestTimeTypeList}
							</FormControl>
						</FormGroup>
					}

					<div className="row-adding row-adding-days">
						<div className="flex-group">
							<FormGroup controlId="new_day_type">
								<FormLabel>Tipos de día a los que aplica <span className="label-required">*</span></FormLabel>
								<FormControl
									onChange={this.handleAddChange}
									value={new_day_type}
									isInvalid={this.checkValidField('day_type_ids')}
									as="select"
								>
									<option value="">Selecciona un tipo de día</option>
									{optionDayTypeList}
								</FormControl>

								<FormControl.Feedback type="invalid">
									{errors.day_type_ids}
								</FormControl.Feedback>

							</FormGroup>
							<button type="button" onClick={this.addDayType} className="btn btn-primary">Añadir</button>
						</div>

						{selected_day_types.length === 0 ? (
							<div className="row-adding-help">
								<button className="btn-link" onClick={() => this.addBulkDayTypes(1, 5)}>Añadir de lunes a viernes</button>
								<button className="btn-link" onClick={() => this.addBulkDayTypes(1, 7)}>Añadir de lunes a domingo</button>
							</div>) : (
							<ul className="list-tags">
								{this.getSelectedDayTypes()}
							</ul>
						)
						}
					</div>


					{shift.type === "Trabajo" &&
						<div className="time-intervals">

							<h3 className="subtitle">Periodos de tiempo</h3>

							<div className="row-special-days flex-between mb20">

								<FormGroup className="form-time-type" controlId="time_type_id">
									<FormLabel>Tipo de tiempo</FormLabel>
									<FormControl
										placeholder="Seleccionar tipo"
										isInvalid={new_interval_errors.time_type_id !== undefined}
										onChange={this.handleChangeInterval}
										value={new_interval.time_type_id}
										as="select"
									>
										<option value="">Selecciona</option>
										{optionTimeTypeList}
									</FormControl>
								</FormGroup>

								<FormGroup controlId="start" className="form-group-time">
									<FormLabel>Inicio</FormLabel>
									<FormControl
										type="time"
										value={new_interval.start}
										onChange={this.handleChangeInterval}
										isInvalid={new_interval_errors.start !== undefined}
									/>
									<FiClock />
								</FormGroup>


								<FormGroup controlId="end" className="form-group-time">
									<FormLabel>Fin</FormLabel>
									<FormControl
										type="time"
										value={new_interval.end}
										onChange={this.handleChangeInterval}
										isInvalid={new_interval_errors.end !== undefined}
									/>
									<FiClock />
								</FormGroup>

								<button type="button" className="btn btn-primary" onClick={this.addTimeInterval}>Añadir periodo</button>

							</div>

							<div className="form-error">{errors.time_intervals}</div>
							{show_timetype_disclaimer && <div className="alert alert-info time-interval-disclaimer">
								<p>Si se define un intervalo de disponibilidad de 24h, se tiene que definir con la hora del primer turno del dia. Por ejemplo, si hay 3 turnos de trabajo posibles:</p>
								<ul>
									<li>7 a 15h</li>
									<li>15 a 23h</li>
									<li>23 a 7h</li>
								</ul>
								<p>El turno de disponibilidad de 24h tiene que ser de 7:00 a 7:00. Si lo creamos de 00:00 a 00:00, no aplicará al turno de noche porque se parte, por lo que entenderá que solo está disponible para los primeros dos turnos.</p>
							</div>}

							{shift.time_intervals.length > 0 &&
								(<table className="table table-condensed table-zebra-reverse table-specialdays">
									<thead>
										<tr>
											<th>Tipo</th>
											<th>Inicio</th>
											<th>Fin</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{this.getTimeIntervalsBody()}
									</tbody>
								</table>)
							}
						</div>
					}

					<h3 className="subtitle">Grupos</h3>

					<FormGroup
						className="form-group-flex form-grupos"
						controlId="has_groups"
					>
						<FormLabel>Mostrar solamente en los grupos seleccionados</FormLabel>
						<Switch
							controlId="has_groups"
							isOn={hasGroups}
							handleToggle={this.handleSwitch}
						/>
					</FormGroup>

					{hasGroups && (
						<div className="row-adding">
							<div className="flex-group">
								<FormGroup controlId="new_group">
									<FormLabel>Grupos a los que aplica <span className="label-required">*</span></FormLabel>
									<FormControl
										onChange={this.handleAddChange}
										value={new_group}
										isInvalid={this.checkValidField('group_ids')}
										as="select"
									>
										<option value="">Selecciona un grupo</option>
										{optionGroupList}
									</FormControl>

									<FormControl.Feedback type="invalid">
										{errors.group_ids}
									</FormControl.Feedback>

								</FormGroup>
								<button type="button" onClick={this.addGroup} className="btn btn-primary">Añadir</button>
							</div>

							<ul className="list-tags">
								{this.getSelectedGroups()}
							</ul>
						</div>
					)}

				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		)
	}
}


const mapStateToProps = (reducers) => {
	return {
		...reducers.calendarsReducer,
		...reducers.timesReducer,
		...reducers.shiftsReducer,
		...reducers.groupsReducer,
		loading: reducers.shiftsReducer.loading
	}
};

export default connect(mapStateToProps, shiftsActions)(ShiftEdit)
