import React from "react";
import { default as WeekTimeRegistrations } from "components/Dashboard/Dashboard_WeekTimeRegistrations"
import "components/Dashboard/Dashboard.scss";
import { WIDGET_NAMES } from "components/WidgetTemplates/utils";

const Card_WeekTimeRegistrations = (props) => {
	// console.log(props);

	return (
		<article className="card card-week-chart">
			<div className="card-top">
				<h2>{WIDGET_NAMES[props.name]}</h2>
			</div>
			<div className="card-content">
				<WeekTimeRegistrations />
			</div>
		</article>
	);
};

export default Card_WeekTimeRegistrations;