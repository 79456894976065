import React from "react";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { IoMdColorFill } from "react-icons/io";
import { FiDelete } from "react-icons/fi";
import { dayTypesService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import Switch from "components/Switch";
import * as calendarsActions from "actions/calendarsActions";
import { TwitterPicker } from "react-color";
import "./DayTypes.scss";

class DayTypeEdit extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		let day_type = {
			name: "",
			description: "",
			color: "",
			holiday: false,
		};

		let isEdit = false;
		let isReadOnly = false;

		if (props.day_type) {
			day_type = props.day_type;
			isEdit = true;
			isReadOnly = day_type.system ? true : false;
		}

		this.state = {
			isEdit,
			isReadOnly,
			isLoading: false,
			isColorPickerVisible: false,
			submitted: false,
			errors: {},
			day_type,
		};
	}

	handleChange = (event) => {
		const { day_type } = this.state;

		this.setState({
			day_type: {
				...day_type,
				[event.target.id]: event.target.value,
			},
		});
	};

	handleColorChange = ({ hex }) => {
		const { day_type } = this.state;

		if (hex === undefined) hex = "";

		this.setState({
			day_type: {
				...day_type,
				color: hex,
			},
			isColorPickerVisible: false,
		});
	};

	checkValidField = (name) => {
		return (
			this.state.submitted &&
			this.state.errors[name] !== undefined &&
			this.state.errors[name] !== ""
		);
	};
	handleSwitch = (event) => {
		const { day_type } = this.state;

		this.setState({
			day_type: {
				...day_type,
				[event.target.id]: event.target.checked,
			},
		});
	};
	handleSubmit = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });
		let response = "";
		const props = this.props;

		// if edit => update; else => new
		if (this.state.isEdit) {
			response = await dayTypesService.update(this.state.day_type);
		} else {
			response = await dayTypesService.add(this.state.day_type);
		}

		//console.log(response);

		if (response.ok) {
			this.setState(
				{
					isLoading: false,
					errors: {},
				},
				() => {
					toastr.success("¡Bien!", "Cambios guardados correctamente");
					this.props.getAllDayTypes();
					props.handleClose();
				}
			);
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response),
			});
		}
	};

	render() {
		const { day_type, isReadOnly } = this.state;
		const onToggleColorPicker = () =>
			this.setState({ isColorPickerVisible: !this.state.isColorPickerVisible });

		const isHoliday = day_type.holiday;

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="modal-body daytype-edit">
					<FormGroup controlId="name">
						<FormLabel>Nombre <span className="label-required">*</span></FormLabel>
						<FormControl
							type="text"
							value={day_type.name}
							onChange={this.handleChange}
							placeholder="Nombre del tipo de día"
							isInvalid={this.checkValidField("name")}
							disabled={isReadOnly}
						/>

						<FormControl.Feedback type="invalid">
							{this.state.errors.name}
						</FormControl.Feedback>
					</FormGroup>

					<FormGroup controlId="description">
						<FormLabel>Descripción</FormLabel>
						<FormControl
							as="textarea"
							value={day_type.description}
							onChange={this.handleChange}
							placeholder="Descripción del tipo de día"
							isInvalid={this.checkValidField("description")}
							disabled={isReadOnly}
						/>

						<FormControl.Feedback type="invalid">
							{this.state.errors.description}
						</FormControl.Feedback>
					</FormGroup>
					<div className="simple-row">
						<FormGroup controlId="color" className="form-color">
							<div className="flex-between">
								<FormLabel>Color</FormLabel>
								<FiDelete
									className={`icon-remove-color ${!day_type.color && "hidden"
										} `}
									onClick={() => this.handleColorChange("")}
								/>
							</div>

							<FormControl
								type="text"
								value={day_type.color}
								placeholder="Color"
								readOnly
								isInvalid={this.checkValidField("color")}
								style={{ backgroundColor: day_type.color && day_type.color }}
								onClick={onToggleColorPicker}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.color}
							</FormControl.Feedback>

							<IoMdColorFill onClick={onToggleColorPicker} />

							{this.state.isColorPickerVisible && (
								<div style={{ position: "absolute" }}>
									<TwitterPicker
										color={day_type.color ? day_type.color : "#ffffff"}
										onChangeComplete={(hex) => this.handleColorChange(hex)}
									/>
								</div>
							)}
						</FormGroup>

						<FormGroup className="form-group-flex" controlId="holiday">
							<FormLabel>Festivo</FormLabel>
							<Switch
								controlId="holiday"
								isOn={isHoliday}
								handleToggle={this.handleSwitch}
							/>
						</FormGroup>
					</div>
				</div>

				<div className="modal-footer">
					<button
						type="button"
						onClick={this.props.handleClose}
						className="btn btn-outline-primary"
					>
						Cancelar
					</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (reducers) => {
	return reducers.calendarsReducer;
};

export default connect(mapStateToProps, calendarsActions)(DayTypeEdit);
