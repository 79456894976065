import React from "react";
import { FormControl } from "react-bootstrap";
import Layout from "components/Layout";
import SubmitButton from "components/SubmitButton";
import { functionsService } from "services";
import { errorFormating } from "utils/utils";
import "components/Imports/Imports.scss";

class FunctionsImports extends React.Component {
	constructor(props) {
		super(props);
		//console.log(props);
		this.state = {
			isLoading: false,
			submitted: false,
			errors: {},
			importErrors: [],
			importMessages: [],
			selectedFile: null,
		};
	}

	async handleDownloadTemplate() {
		const response = await functionsService.downloadImportTemplate();

		var url = window.URL.createObjectURL(response);
		var a = document.createElement("a");
		document.body.appendChild(a);
		a.href = url;
		a.download = "import_template.xlsx";
		a.click();
	}

	handleImport = async (event) => {
		event.preventDefault();
		this.setState({ isLoading: true });

		if (this.state.selectedFile) {
			var formData = new FormData();
			formData.append("filename", this.state.selectedFile);
			const response = await functionsService.prepareImport(formData);

			// console.log(response);

			if (response.ok && !response.has_errors) {
				this.setState(
					{
						isLoading: false,
						errors: {},
						importMessages: [],
						importErrors: [],
					},
					() => {
						if (response.token) {
							this.runImport(response.token);
						}
					}
				);
			} else if (!response.ok) {
				this.setState({
					isLoading: false,
					submitted: true,
					importMessages: [],
					importErrors: [],
					errors: {
						message:
							"Se ha producido un error",
					},
				});
			}
			else {

				var importErrors = [];
				if (response.rows.length > 0)
					importErrors = importErrors.concat(response.rows);
				if (response.header.errors !== undefined && response.header.errors.length > 0)
					importErrors = importErrors.concat(response.header.errors);

				this.setState({
					isLoading: false,
					submitted: true,
					errors: response.rows.length > 0 ? {} : response.errors[0],
					importMessages: [],
					importErrors,
				});
			}
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				importMessages: [],
				importErrors: [],
				errors: {
					message:
						"Debes seleccionar el archivo en formato excel con las funciones a importar",
				},
			});
		}
	};

	// send token and run import
	runImport = async (token) => {
		this.setState({ isLoading: true });

		const response = await functionsService.runImport(token);
		// console.log(response);

		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
				importErrors:
					response.has_errors && response.rows.length > 0 ? response.rows : [],
				importMessages: response.rows.length > 0 ? response.rows : [],
			});
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response),
			});
		}
	};

	getImportErrors = (rows) =>
		rows.map((row, i) => {
			const noRowErrors = (row.errors === undefined || row.errors.length === 0);
			if (noRowErrors && row.message === undefined) {
				return (<li key={i} className="hidden"></li>)
			}
			else if (noRowErrors && row.message !== undefined) {
				return (
					<li key={i}>
						<span className="import-count">{row.message}</span>
					</li>
				);
			}
			return (
				<li key={i}>
					<span className="import-count">Fila {row.row}</span>
					<ul className="import-details">
						{this.getImportErrorsDetails(row.errors)}
					</ul>
				</li>
			);
		});
	getImportErrorsDetails = (errors) =>
		errors.map((error, i) => {
			return <li key={i}>{error.message}</li>;
		});

	getImportMessages = (rows) =>
		rows.map((row, i) => {
			return (
				<li key={i}>
					<span className="import-count">Fila {row.row}</span>
					<span className="import-text">{row.message}</span>
				</li>
			);
		});

	handleChange = (event) => {
		this.setState({
			selectedFile: event.target.files[0],
		});
	};

	getContent = () => {
		return (
			<form onSubmit={this.handleImport}>
				<ol className="steps">
					<li className="step-download">
						Descarga{" "}
						<span
							className="link"
							onClick={() => this.handleDownloadTemplate()}
						>
							la plantilla de ejemplo
						</span>{" "}
						en formato xls
					</li>
					<li className="step-upload">
						<div className="step-upload-content">
							Sube la plantilla con los datos de las funciones que vas a
							importar
							<FormControl
								type="file"
								onChange={this.handleChange}
								className="form-control"
							/>
						</div>
					</li>
				</ol>
				<SubmitButton
					type="submit"
					isLoading={this.state.isLoading}
					text="Importar funciones"
					loadingText="Importando..."
				/>
			</form>
		);
	};

	render() {
		const { importErrors, importMessages, errors } = this.state;

		return (
			<Layout className="page-imports" context="employees">
				<div className="heading">
					<div className="heading-left">
						<h1 className="title">Importar funciones</h1>
					</div>
				</div>

				{this.getContent()}

				{errors.message && <p className="error-message">{errors.message}</p>}

				{importErrors.length > 0 && (
					<div className="import-list import-errors">
						<p className="import-list-text">
							Se han encontrado los siguientes errores en la importación:
						</p>
						<ul className="import-rows">
							{this.getImportErrors(importErrors)}
						</ul>
					</div>
				)}

				{importMessages.length > 0 && (
					<div className="import-list import-messages">
						<p className="import-list-text">Resultado de la importación</p>
						<ul className="import-rows">
							{this.getImportMessages(importMessages)}
						</ul>
					</div>
				)}
			</Layout>
		);
	}
}

export default FunctionsImports;
