import React, { Component } from "react";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { appService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import "./styles/Auth.scss";
import logo from 'assets/logo.svg';

class RememberPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      submitted: false,
      email: "",
      errors: {},
      response_ok: false,
    };
  }

  handleChange = event => {
    this.setState({
      	[event.target.id]: event.target.value
    });
  }

  checkValidField = (name) => {
    return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
  }     

	handleSubmit = async event => {
		event.preventDefault();
		this.setState({ isLoading: true });
		var response_ok = false;

		var response = await appService.resetPassword(this.state.email);
		// console.log(response);

		if (response.errors === undefined){
			response_ok = true;
		}

		this.setState({ 
			submitted: true, 
			isLoading: false,
			response_ok: response_ok,
			errors: errorFormating(response)
		});
	} 

  render() {
	return (
		<div className="Auth">
			<Link to="/login"><img className="logo" src={logo} alt="Plain" /></Link>

			<form onSubmit={this.handleSubmit}>
				<FormGroup controlId="email">
					<FormLabel>Email</FormLabel>
					<FormControl
					autoFocus
					type="email"
					value={this.state.email}
					onChange={this.handleChange}
          			isInvalid = {this.checkValidField('email')}
          			placeholder="Introduce tu e-mail"
					/>
		          <FormControl.Feedback type="invalid">
		            {this.state.errors.email}
		          </FormControl.Feedback>            
				</FormGroup>		

				<SubmitButton
					block
					type="submit"
					isLoading={this.state.isLoading}
					text="Recordar contraseña"
					loadingText="Enviando..."
				/>

	        { this.state.response_ok && 
	          <div className="alert alert-success alert-dismissible fade show" role="alert">
	            Se ha enviado reseteado la contraseña. Recibirás un email para poder generar una contraseña nueva.
	          </div>
	        }

            { this.state.errors.employee_id && 
              <div className="alert alert-danger alert-dismissible fade show mt15" role="alert">
                {this.state.errors.employee_id}
              </div>
            }   

			</form>

			<p className="login-register">
				¿Ya tienes cuenta? <Link to="/login">Inicia sesión</Link>
			</p>
		</div>
	);
  }
}



export default RememberPassword;