import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Layout from "components/Layout";
import { FormGroup, FormControl, FormLabel, Dropdown } from "react-bootstrap";
import { isAdmin, isSupervisor } from "utils/utils";
import { Link } from "react-router-dom";
import { FiTrello, FiSettings, FiDownload, FiMoreHorizontal, FiUpload } from "react-icons/fi";
import { FaMagic } from "react-icons/fa";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import * as groupsActions from "actions/groupsActions";
import * as plansActions from "actions/plansActions";
import * as shiftsActions from "actions/shiftsActions";
import SubmitButton from "components/SubmitButton";
import Avatars from "components/Avatar/Avatars";
// import { iPlanService } from "services";
import PlanMagicModal from 'components/Plans/PlanMagicModal';
import ExportPlanModal from 'components/Plans/ExportPlanModal';
import { showModal, hideModal } from "actions/modalActions";
import Modal from "components/Modal";
import "components/Plans/Plans.scss";

class Plans extends Component {
	constructor(props) {
		super(props);
		// console.log(props);
		this.openNewMagicPlan = this.openNewMagicPlan.bind(this);
		this.openExportPlanModal = this.openExportPlanModal.bind(this);
		this.closeModal = this.closeModal.bind(this);

		this.state = {
			group_id: "",
			start: "",
			end: "",
			submitted: false,
			error: "",
		};
	}

	componentDidMount() {
		this.props.getAllGroups();
		this.props.getPlanVisits();

		// work shifts for magic modal
		this.props.getAttendanceShifts();
	}

	onDatesChange = (dates) => {
		var { startDate, endDate } = dates;

		if (startDate) startDate = startDate.format("YYYY-MM-DD");

		if (endDate) endDate = endDate.format("YYYY-MM-DD");

		this.setState({
			start: startDate,
			end: endDate,
		});
	};

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	openPlan = (event) => {
		event.preventDefault();
		const { group_id, start, end } = this.state;
		var error = "";

		// if (
		// 	start &&
		// 	end &&
		// 	moment(start).format("YYYY") !== moment(end).format("YYYY")
		// ) {
		// 	error = "El año de inicio y fin deben coincidir";
		// }

		if (group_id && start && end && error === "")
			this.props.history.push("/plans/" + group_id + "/" + start + "@" + end);

		this.setState({
			submitted: true,
			error,
		});
	};

	checkInvalidRange = () => {
		return (
			this.state.submitted &&
			(this.state.start === "" ||
				this.state.end === "" ||
				this.state.start === null ||
				this.state.end === null ||
				this.state.error !== "")
		);
	};

	// export plan config modal
	openExportPlanModal() {
		// console.log(plan);
		this.props.showModal(
			{
				open: true,
				title: `Exportar planificación`,
				style: { width: "450px" },
				content: (
					<ExportPlanModal
						handleClose={this.closeModal}
					/>
				),
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	// render stuff

	getGroupList = (group) =>
		group.groups.map((item) => {
			return (
				<li key={item.id} className="tag-default">
					{item.name}
				</li>
			);
		});

	getVisitList = () =>
		this.props.plan_visits.map((plan, i) => {
			// console.log(plan);
			const start_format = moment(plan.start).format("D MMMM");
			const end_format = moment(plan.end).format("D MMMM");
			const time_ago = moment.utc(plan.last_visit).fromNow();
			const group = plan.group;
			const is_multigroup = group.is_multigroup;

			let empleados_count = "Ningún empleado asignado";
			let total_employees = group.total_employees;
			let employees_avatars = group.employees;

			const groups_supervised = this.props.user.groups_supervised;
			const showSupervisor =
				isSupervisor() &&
				groups_supervised.length > 0 &&
				groups_supervised.some((gs) => gs.id === parseInt(group.id));

			if (group.is_multigroup) {
				employees_avatars = [];

				group.groups.map((g, i) => {
					total_employees += g.total_employees;
					employees_avatars.push(...g.employees);
				});

				if (employees_avatars.length > 2) {
					employees_avatars = employees_avatars.slice(0, 3);
				}
			}

			if (total_employees === 1) empleados_count = "1 empleado";
			else if (total_employees > 1)
				empleados_count = total_employees + " empleados";

			return (
				<div key={i} className="col-sm-6 col-md-4 col-visit">
					<article className="plan-visit card">
						{showSupervisor && (
							<div className="card-tag">
								<div className="card-tag-content">
									<FiSettings
										className="card-tag-icon"
										title="Supervisas este grupo"
									/>
								</div>
							</div>
						)}
						<div className="plan-visit-header">
							<h2 className="card-title">{group.name}</h2>
							<h3 className="card-subtitle">
								{group.is_multigroup && group.groups.length + " grupos / "}
								{empleados_count}
							</h3>
							<div className="avatar-container">
								{total_employees > 0 && (
									<Avatars employees={employees_avatars} />
								)}
							</div>
						</div>

						{is_multigroup ? (
							<div className="form-group no-form mt10">
								<ul className="list-tags">{this.getGroupList(group)}</ul>
							</div>
						) : (
							<div className="form-group no-form">
								<label>Calendario</label>
								<span>{group.calendar.name}</span>
							</div>
						)}

						<div className="form-group no-form">
							<label>Periodo</label>
							<span>
								{start_format} - {end_format}
							</span>
						</div>

						<div className="flex-between">
							<Link
								to={`/plans/${group.id}/${plan.start}@${plan.end}`}
								className="btn btn-primary btn-inline"
							>
								Abrir
							</Link>
							<span className="time-ago">{time_ago}</span>
						</div>
					</article>
				</div>
			);
		});


	// Automagic planification
	openNewMagicPlan() {
		this.props.showModal({
			open: true,
			title: 'Planificación automática',
			style: { width: '860px' },
			content: <PlanMagicModal handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	closeModal() {
		this.props.hideModal();
	}

	render() {
		const { detailed_groups, plan_visits, user } = this.props;
		const { error } = this.state;
		var totalEmployees = 0;
		const optionGroupList =
			detailed_groups.length > 0 &&
			detailed_groups.map((item, i) => {
				let multigroup = item.is_multigroup ? " (multigrupo)" : "";
				totalEmployees += item.total_employees;
				return (
					<option key={i} value={item.id}>
						{item.name}
						{multigroup}
					</option>
				);
			}, this);

		const noGroups = detailed_groups.length == 0;
		const isEmpty = noGroups || totalEmployees == 0;

		return (
			<>
				<Layout className="page-plans">
					<div className="heading">
						<div className="heading-left">
							<h1 className="title">Planificación</h1>
						</div>

						{isAdmin() && (
							<div className="heading-actions">
								<button
									type="button"
									title="Planificación automática"
									// disabled={isLocked}
									onClick={this.openNewMagicPlan}
									className="btn btn-outline-primary btn-wand"
								>
									Planificación automática

									<FaMagic className="icon-wand" />
								</button>

								<Dropdown>
									<Dropdown.Toggle variant="action">
										<FiMoreHorizontal />
									</Dropdown.Toggle>
									<Dropdown.Menu alignRight>
										<Dropdown.Item
											as="button"
											onClick={() =>
												this.props.history.push("/plans/import")
											}
										>
											<FiDownload /> Importar planificación
										</Dropdown.Item>

										<Dropdown.Item
											as="button"
											onClick={() => this.openExportPlanModal()}
										>
											<FiUpload className="dropdown-icon" /> Exportar
											planificación
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						)}

					</div>



					{isEmpty ? (
						<div className="first-steps">
							<FiTrello className="icono-pasos" />
							<h3 className="title2">Primeros pasos</h3>
							{noGroups && (
								<p>
									Para poder planificar turnos primero debes crear un{" "}
									<Link className="alink" to="/groups">
										grupo
									</Link>{" "}
									para luego añadirle empleados.
								</p>
							)}
							{!noGroups && totalEmployees == 0 && (
								<p>
									Para poder planificar turnos primero debes añadir como mínimo un
									empleado dentro de{" "}
									<Link className="alink" to="/groups">
										grupo
									</Link>
									.
								</p>
							)}
						</div>
					) : (
						<>
							<section className="bloque-abrir">
								<form onSubmit={this.openPlan}>
									<div className="flex-group">
										<FormGroup controlId="group_id">
											<FormLabel>Grupo</FormLabel>
											<FormControl
												value={this.state.group_id}
												onChange={this.handleChange}
												isInvalid={
													this.state.submitted && this.state.group_id === ""
												}
												as="select"
											>
												<option value="">Seleccionar grupo</option>
												{optionGroupList}
											</FormControl>
											<FormControl.Feedback type="invalid">
												Selecciona un grupo para la planificación
											</FormControl.Feedback>
										</FormGroup>

										<FormGroup
											className={`form-group-periodo ${this.checkInvalidRange() ? "is-invalid" : ""
												}`}
										>
											<FormLabel>Periodo</FormLabel>
											<DateRangePicker
												startDateId="startDateId"
												endDateId="endDateId"
												startDate={
													this.state.start ? moment(this.state.start) : null
												}
												endDate={this.state.end ? moment(this.state.end) : null}
												startDatePlaceholderText="Inicio"
												endDatePlaceholderText="Fin"
												onDatesChange={this.onDatesChange}
												focusedInput={this.state.focusedInput}
												hideKeyboardShortcutsPanel={true}
												isOutsideRange={() => false}
												minimumNights={0}
												onFocusChange={(focusedInput) =>
													this.setState({ focusedInput })
												}
											/>
											<FormControl
												className="hidden"
												isInvalid={this.checkInvalidRange()}
											/>

											<FormControl.Feedback type="invalid">
												{error !== "" ? error : "Indica inicio y fin de periodo"}
											</FormControl.Feedback>
										</FormGroup>

										<SubmitButton
											type="submit"
											isLoading={this.state.isLoading}
											text="Abrir"
											loadingText="Abriendo..."
										/>
									</div>
								</form>
							</section>
							{plan_visits.length > 0 && (
								<section className="list-recientes">
									<h3>Recientes</h3>
									<div className="row">{this.getVisitList()}</div>
								</section>
							)}
						</>
					)}
				</Layout>

				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.groupsReducer,
		...reducers.plansReducer,
		...reducers.timesReducer,
		loading: reducers.groupsReducer.loading,
		user: reducers.authReducer.user,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	getAllGroups: () =>
		dispatch(
			groupsActions.getAllGroups({ sort: "+name", variant: "detailed", include_own_group: true })
		),
	getPlanVisits: () => dispatch(plansActions.getPlanVisits()),
	getAttendanceShifts: () => dispatch(shiftsActions.getAttendanceShifts()),

});

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
