import React from "react";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import ColumnList from "./EmployeeOrderColumnList";
import arrayMove from "array-move";
import Loading from "components/Loading";
import { employeesService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import "./Employees.scss";
import * as employeesActions from "actions/employeesActions";

class EmployeeOrderModal extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		this.state = {
			group_employees: [],
			loading: true,
			submitted: false,
			errors: {},
		};
	}

	async componentDidMount() {
		const { group } = this.props;
		const group_employees_response = await employeesService.getEmployeesByGroupOrdered(group.id);
		const group_employees = group_employees_response.ok ? group_employees_response.data : [];

		// console.log(group_employees);

		this.setState({
			group_employees,
			loading: false,
		});

	}

	checkValidField = (name) => {
		return (
			this.state.submitted &&
			this.state.errors[name] !== undefined &&
			this.state.errors[name] !== ""
		);
	};

	prepareColumnsSubmit = () => {
		const { group_employees } = this.state;
		const employee_list_ids = [];

		// guardar ids empleados por orden
		group_employees.map(function (employee) {
			employee_list_ids.push(employee.id);
			return true;
		});

		return employee_list_ids;
	};


	handleSubmit = async (event) => {
		event.preventDefault();
		const { group } = this.props;

		this.setState({ loading: true });
		const props = this.props;

		const employee_list_ids = this.prepareColumnsSubmit();

		const response = await employeesService.updateEmployeesOrder(employee_list_ids, group.id);

		// console.log(response);

		if (response.ok) {
			this.setState(
				{
					loading: false,
					errors: {},
				},
				() => {
					toastr.success("¡Bien!", "Cambios guardados correctamente");
					props.getPlanAssignments();
					props.getAccountAssignments();
					props.handleClose();
				}
			);
		} else {
			this.setState({
				loading: false,
				submitted: true,
				errors: errorFormating(response),
			});
		}
	};

	onSortEnd = ({ oldIndex, newIndex }) => {
		// console.log(oldIndex);
		// console.log(newIndex);
		this.setState(({ group_employees }) => ({
			group_employees: arrayMove(group_employees, oldIndex, newIndex),
		}));
	};


	render() {
		const { group_employees, errors } = this.state;
		const { group } = this.props;

		if (this.props.loading || this.state.loading) {
			return <Loading />;
		}

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="modal-body group-employees-order">
					<p className="text-info">Ordena los empleados del grupo <strong>{group.name}</strong> arrastrándolos arriba o abajo:</p>

					<div className="column-wrap">
						<ColumnList
							helperClass="column-item-active"
							onSortEnd={this.onSortEnd}
							handleColumn={this.handleColumn}
							items={group_employees}
							useDragHandle
						/>
					</div>

					{errors.details && (
						<p className="invalid-feedback d-block">{errors.details}</p>
					)}
				</div>

				<div className="modal-footer">
					<button
						type="button"
						onClick={this.props.handleClose}
						className="btn btn-outline-primary"
					>
						Cancelar
					</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.loading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state, myProps) => {
	return {
		...state.employeesReducer,
		employee: myProps.employee,
	};
};

export default connect(mapStateToProps, employeesActions)(EmployeeOrderModal);
