import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FiThumbsUp } from "react-icons/fi"
import { Link } from 'react-router-dom'
import moment from 'moment'
import Moment from 'react-moment'
import { timesService } from "services"
import { isAdmin } from "utils/utils"
import "components/Dashboard/Dashboard.scss"

class Dashboard_OpenTimeRegistrations extends Component {

  constructor(props) {
    super(props);
    this.state = {
      times: [],
    };
  }

  componentDidMount() {
    this.getTimes();
  }

  async getTimes() {
    const employee_id = !isAdmin() ? this.props.user.id : undefined;
    const today = new Date();

    const start_from = moment(today).add(-30, 'days').format('YYYY-MM-DD');
    const start_to = moment(today).add(-1, 'days').format('YYYY-MM-DD');

    const response = await timesService.getAll({ employee_id, start_from, start_to, not_ended: true });

    if (response.ok) {
      this.setState({
        times: response.data,
        loading: false,
      });
    }
    /*
    else{
      this.setState({
        error: true,
        loading: false
      });
    }*/
  }


  getTimeRows = () => this.state.times.map((time) => {
    return (
      <tr key={time.id}>
        {/* <td><Moment format="DD/MM/YY">{time.created}</Moment></td> */}

        {isAdmin() &&
          (<td>
            <Link to={`/times/${time.employee.id}/employee`}>
              {time.employee.name} {time.employee.surname}
            </Link>
          </td>)
        }

        <td>
          {/* <span className="d-none d-lg-inline-block tag-dia-setmana"><Moment format="dddd">{time.start}</Moment></span> */}
          {/* <Moment format="D MMMM">{time.start}</Moment> */}
          <Moment format="DD/MM/YY">{time.start}</Moment>
        </td>
        <td>{moment.utc(time.start).local().format('HH:mm')}</td>
      </tr>
    )
  });

  render() {
    const { times } = this.state;
    return (
      times.length > 0 ?
        (
          <table className="table table-times table-zebra">
            <thead>
              <tr>
                {/* <th>Creado</th> */}
                {isAdmin() && (<th>Empleado</th>)}
                <th>Día</th>
                <th>Inicio</th>
              </tr>
            </thead>
            <tbody>
              {this.getTimeRows()}
            </tbody>
          </table>
        )
        :
        <div className="empty">
          <FiThumbsUp />
          <p>¡Bien! No hay ningún fichaje abierto</p>
        </div>
    )
  }
}

const mapStateToProps = (reducers) => {
  return {
    ...reducers.timesReducer,
    user: reducers.authReducer.user,
  }
};

export default connect(mapStateToProps, null)(Dashboard_OpenTimeRegistrations);