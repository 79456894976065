import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Avatar from 'components/Avatar';

import "./Contracts.scss";

class ContractEmployees extends React.Component {

	getEmptyRow = () => {
		return (
			<tr>
				<td className="no-result">
					Todavía no hay ningún empleado asignado.
				</td>
			</tr>
		);
	}

	getRows = () => this.props.employees.map((employee) => {

		return (
			<tr key={employee.id}>
				<td>
					<Avatar userId={employee.id} className="avatar-sm" alt={employee.name} />

					<Link
						className="table-link"
						to={`/employees/${employee.id}`}
					>
						{employee.name} {employee.surname}
					</Link>
				</td>
			</tr>
		)
	});

	getContent = () => {
		const { employees } = this.props;
		if (employees) {
			return (
				<table className="table table-employees-in-contract table-zebra-reverse">
					<thead>
						<tr>
							<th>Empleado</th>
						</tr>
					</thead>
					<tbody>
						{employees.length === 0 ?
							this.getEmptyRow()
							:
							this.getRows()
						}
					</tbody>
				</table>
			);
		}
	}
	render() {
		return (
			<>
				{this.getContent()}
			</>
		)
	}
}


const mapStateToProps = state => {
	return {
		user: state.authReducer.user,
	}
}


export default connect(mapStateToProps, null)(ContractEmployees);