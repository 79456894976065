import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormGroup, FormControl } from "react-bootstrap";
import Moment from "react-moment";
import moment from "moment";
import "moment-timezone";
import { TabletOrDesktop, Mobile } from "utils/responsive";
import * as employeesActions from "actions/employeesActions";
import * as groupsActions from "actions/groupsActions";
import { plansService } from "services";
import Layout from "components/Layout";
import Loading from "components/Loading";
import Modal from "components/Modal";
import "../components/TimeRegistrations/TimeRegistrations.scss";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import { isAdmin, isSupervisor } from "utils/utils";

class TimesWithout extends Component {
	constructor(props) {
		super(props);
		// console.log(props);

		const today = new Date();
		const startDate = moment(today).startOf("month").format("YYYY-MM-DD");
		const endDate = moment(today).endOf("month").format("YYYY-MM-DD");

		this.state = {
			loading: true,
			error: false,
			plan_assignments: [],
			searchEmployee: "",
			startDate,
			endDate,
			filterGroupId: "",
			focusedInput: null,
			hasResults: false,
		};
	}

	componentDidMount() {
		this.props.getSelectableEmployees();
		this.getTimes();
		this.getGroups();
	}

	async getGroups() {
		await this.props.getAllGroups();
	}

	async getTimes() {
		const { searchEmployee, startDate, endDate, filterGroupId } = this.state;

		const response = await plansService.getPlanAssignments({ group_id: filterGroupId, employee_id: searchEmployee, start: startDate, end: endDate, without_time_registration: true });

		// console.log(response);

		if (response.ok) {
			const plan_assignments = response.data;

			var hasResults = false;
			plan_assignments.map((pa) => {
				pa.plans.map((item) => {
					if (item.assignments.length > 0)
						hasResults = true;
				})
			});

			this.setState({
				plan_assignments,
				loading: false,
				hasResults,
			});
		} else {
			this.setState({
				error: true,
				loading: false,
			});
		}
	}

	handleChangeSearch = (event) => {
		this.setState(
			{
				[event.target.id]: event.target.value,
			},
			() => {
				this.getTimes();
			}
		);
	};

	onDatesChange = (dates) => {
		var { startDate, endDate } = dates;

		if (startDate)
			startDate = startDate.format("YYYY-MM-DD");

		if (endDate) {
			endDate = endDate.format("YYYY-MM-DD");
		}

		this.setState(
			{
				startDate,
				endDate,
			},
			() => {
				this.getTimes();
			}
		);
	};

	getContent = () => {
		var { loading, error, hasResults } = this.state;

		if (loading) {
			return <Loading />;
		}

		if (error) {
			return "No se han podido cargar los fichajes no realizados";
		}

		return (
			<>
				<Mobile>
					<table className="table table-times table-mobile">
						<tbody>
							{hasResults
								? this.getRows()
								: this.getEmptyRow()}
						</tbody>
					</table>
				</Mobile>

				<TabletOrDesktop>
					<table className="table table-times table-zebra">
						<thead>
							<tr>
								<th>Día</th>
								<th>Empleado</th>
								<th>Turno</th>
								<th>Ubicación</th>
							</tr>
						</thead>
						<tbody>
							{hasResults
								? this.getRows()
								: this.getEmptyRow()}
						</tbody>
					</table>
				</TabletOrDesktop>
			</>
		);
	};

	getEmptyRow = () => {
		return (
			<tr>
				<td colSpan="4" className="no-result">
					Sin resultados
				</td>
			</tr>
		);
	};

	getRows = () => this.state.plan_assignments.map((pa) => {
		const employee = pa.employee;
		const plans = pa.plans;

		return this.getPlans(employee, plans);
	});

	getPlans = (employee, plans) => plans.map((plan) => {
		const assignments = plan.assignments;
		if (assignments.length > 0)
			return this.getAssignments(employee, assignments, plan);
	});

	getAssignments = (employee, assignments, plan) => assignments.map((a, i) => {
		return (
			<tr key={i}>
				<td>
					<Moment format="DD/MM/YY">{plan.day}</Moment>
				</td>
				<td>
					<Link to={`/times/${employee.id}/employee`}>
						{employee.name} {employee.surname}
					</Link>
				</td>
				<td>
					{a.shift.name}
				</td>
				<td>
					{a.ubication ? a.ubication.name : "-"}
				</td>
			</tr>
		)
	});

	render() {
		const { groups, select_employees } = this.props;

		const optionList = groups.length > 0 && groups.map((item, i) => {
			return (
				<option key={i} value={item.id}>
					{item.name}
				</option>
			);
		}, this);

		let employeeList =
			select_employees.length > 0 &&
			select_employees.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name} {item.surname}
					</option>
				);
			}, this);

		return (
			<>
				<Layout className="page-times page-times-without" context="times">
					<div className="heading mb10">
						<div className="heading-left">
							<h1 className="title">Fichajes no realizados</h1>
						</div>

						<div className="heading-filters">
							<FormGroup
								className="form-select-user"
								controlId="searchEmployee"
							>
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={this.state.searchEmployee}
									className={!isAdmin() && !isSupervisor() || select_employees.length < 1 ? 'hidden' : ''}
									onChange={this.handleChangeSearch}
									as="select"
								>
									<option value="">Todos los empleados</option>
									{employeeList}
								</FormControl>
							</FormGroup>

							<FormGroup className="form-search" controlId="filterGroupId">
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={this.state.filterGroupId}
									onChange={this.handleChangeSearch}
									as="select"
								>
									<option value="">Filtrar por grupo</option>
									{optionList}
								</FormControl>
							</FormGroup>

							<DateRangePicker
								startDateId="startDateId"
								endDateId="endDateId"
								startDate={
									this.state.startDate ? moment(this.state.startDate) : null
								}
								endDate={this.state.endDate ? moment(this.state.endDate) : null}
								startDatePlaceholderText="Inicio"
								endDatePlaceholderText="Fin"
								onDatesChange={this.onDatesChange}
								focusedInput={this.state.focusedInput}
								hideKeyboardShortcutsPanel={true}
								isOutsideRange={() => false}
								minimumNights={0}
								onFocusChange={(focusedInput) =>
									this.setState({ focusedInput })
								}
							/>
						</div>
					</div>

					{this.getContent()}

					<Modal hideModal={this.props.hideModal} />
				</Layout>
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.employeesReducer,
		...reducers.groupsReducer,
		user: reducers.authReducer.user,
		loading: reducers.plansReducer.loading,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getSelectableEmployees: (options) => dispatch(employeesActions.getSelectableEmployees(options)),
	getAllGroups: () => dispatch(groupsActions.getAllGroups({ sort: "+name" })),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimesWithout);
