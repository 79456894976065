import React, { Component } from "react";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as userActions from 'actions/userActions';
import { appService } from "services";
import SubmitButton from "components/SubmitButton";
import Loading from "components/Loading";
import "./styles/Auth.scss";
import logo from 'assets/logo.svg';

class Invitation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isFound: false, // if invitation is valid
			isLoading: true, // while validating invitation
			isSending: false, // sending new password
      		isChanged: false, // if new passord has been set
			name: '',
			surname: '',
			company: '',
			email: '',
			password_new: '',
			confirmPassword: '',
		};
	}

	async componentDidMount(){

		const response = await appService.getInvitation(this.props.match.params.token);
		// console.log(response);

		if (response.ok){
			const { name, surname, email, company } = response;

			this.setState({
				name,
				surname,
				email,
				company: company.name,
				isFound: true,
				isLoading: false
			});
		}
		else{
			this.setState({
				isLoading: false
			});					
		}
	}

	validateForm() {
		const { password_new, confirmPassword } = this.state;
		return (
			password_new.length > 5 &&
			password_new === confirmPassword
		);
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	}

	handleSubmit = async event => {
		event.preventDefault();
		this.setState({ isSending: true });

   	const response = await appService.postInvitationPassword(this.props.match.params.token, this.state.password_new);

		this.setState({
			password_new: '',
			confirmPassword: '',
			isChanged: response.ok,
		});

		this.setState({
			isSending: false
		});
	}

	getContent = () => {
		// console.log(this.props);
		if (this.state.isLoading){
			return <Loading />;
		}

		if (!this.state.isFound){
			return (
         		<div className="notfound">
			            <h3>:(</h3>
			          	<h2>La invitación que buscabas no existe o el usuario ya se ha activado previamente</h2>
			            <p className="login-register mt30">
							¿Ya estás activado? <Link to="/login">Inicia sesión</Link>
						</p>
					</div>
				);
		}

  		const { name, surname, email, company, password_new, confirmPassword } = this.state;

		return(
				<>
         		<p className="hello">¡Hola <strong>{name}</strong>! <span className="emoji" role="img" aria-label="hello">&#128075;</span><br/>
            			Indica una contraseña de al menos 6 carácteres para activar tu usuario y poder iniciar sesión en Plain</p>

					<form onSubmit={this.handleSubmit}>

            			<ul className="user-data">
							<li>{name} {surname}</li>
							<li><strong>{company}</strong></li>
							<li>{email}</li>
						</ul>
								
						<FormGroup controlId="password_new">
							<FormLabel>Contraseña <span className="label-required">*</span></FormLabel>
							<FormControl
							value={password_new}
							onChange={this.handleChange}
							type="password"
							/>				
						</FormGroup>
						<FormGroup controlId="confirmPassword">
							<FormLabel>Confirmar contraseña <span className="label-required">*</span></FormLabel>
							<FormControl
							value={confirmPassword}
							onChange={this.handleChange}
							type="password"
							/>				
						</FormGroup>
						<SubmitButton
							block
							disabled={!this.validateForm()}
							type="submit"
							isLoading={this.state.isSending}
							text="ENVIAR"
							loadingText="Activando..."
						/>
					</form>

	             { this.state.isChanged && 
	               <div className="alert alert-success alert-dismissible fade show" role="alert">
	                 Se ha guardado la contraseña correctamente. Ya puedes <Link to="/login">iniciar sesión</Link>
	               </div>
	             }					
				</>
		);
	}  

  render() {

	return (
		<div className="Auth page-invitation">
			<Link to="/">
		  		<img className="logo" src={logo} alt="Plain" />
		  	</Link>

		  	{ this.getContent() }
		</div>
	);
  }
}

const mapStateToProps = (reducers) => {
	return reducers.authReducer;
};

export default connect(mapStateToProps, userActions)(Invitation);