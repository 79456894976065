import React from "react";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import arrayMove from "array-move";
import { widgetsService } from "services";
import { errorFormating } from "utils/utils";
import ColumnList from "./TemplateColumnList";
import SubmitButton from "components/SubmitButton";
import * as dashboardActions from "actions/dashboardActions";
import "./WidgetTemplates.scss";

class WidgetTemplateEdit extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		let widgetTemplate = {
			name: "",
			widgets: [],
		};

		let isEdit = false;

		if (props.widgetTemplate) {
			isEdit = true;
			widgetTemplate = props.widgetTemplate;
		}

		var columns = props.widgets;

		if (widgetTemplate.widgets.length > 0) {
			columns = columns.map((column) => {
				widgetTemplate.widgets.forEach((item) => {
					if (item.name === column.name) {
						column.checked = true;
						column.width = item.width;
						column.height = item.height;
						column.order = item.position;
						column.label = item.name;
					}
				});

				return column;
			});
		}

		// console.log(widgetTemplate.widgets);
		// console.log(columns);

		columns = columns.sort(function (a, b) {
			if (!a.order) a.order = 99999;

			if (!b.order) b.order = 99999;

			if (a.order < b.order) {
				return -1;
			}
			if (a.order > b.order) {
				return 1;
			}
			return 0;
		});

		// console.log(columns);

		this.state = {
			isEdit,
			isLoading: false,
			submitted: false,
			errors: {},
			widgetTemplate,
			columns,
		};
	}

	handleChange = (event) => {
		const { widgetTemplate } = this.state;

		this.setState({
			widgetTemplate: {
				...widgetTemplate,
				[event.target.id]: event.target.value,
			},
		});
	};

	handleSwitch = (event) => {
		const { widgetTemplate } = this.state;

		this.setState({
			widgetTemplate: {
				...widgetTemplate,
				[event.target.id]: event.target.checked,
			},
		});
	};

	checkValidField = (name) => {
		return (
			this.state.submitted &&
			this.state.errors[name] !== undefined &&
			this.state.errors[name] !== ""
		);
	};

	prepareColumnsSubmit = () => {
		const { widgetTemplate, columns } = this.state;
		const widgets = [];
		var order = 1;
		columns.map(function (column) {

			if (column.checked) {

				const newObj = {
					"name": column.name,
					"position": order,
					"width": column.width,
					"height": column.height,
					"enabled": column.checked
				}

				widgets.push(newObj);
				order += 1;
			}
			return true;
		});
		widgetTemplate.widgets = widgets;
		return widgetTemplate;
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		const { isEdit } = this.state;

		this.setState({ isLoading: true });
		let response = "";
		const props = this.props;

		const widgetTemplate = this.prepareColumnsSubmit();

		// if edit => update; else => new
		if (isEdit) {
			response = await widgetsService.update(widgetTemplate);
		} else {
			response = await widgetsService.add(widgetTemplate);
		}

		// console.log(response);

		if (response.ok) {
			this.setState(
				{
					isLoading: false,
					errors: {},
				},
				() => {
					toastr.success("¡Bien!", "Cambios guardados correctamente");
					this.props.getWidgetTemplates();
					props.handleClose();
				}
			);
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response),
			});
		}
	};

	onSortEnd = ({ oldIndex, newIndex }) => {
		// console.log(oldIndex);
		// console.log(newIndex);
		this.setState(({ columns }) => ({
			columns: arrayMove(columns, oldIndex, newIndex),
		}));
	};

	// Handle columns:

	handleColumn = (item, event, data) => {
		if (data.action === "select") {
			this.handleColumnSelection(item, event);
		}
		if (data.action === "edit") {
			this.handleColumnEdit(item, event, data);
		}
	};

	handleColumnSelection = (item, event) => {
		const value = event.target.checked;
		this.setState({
			columns: this.state.columns.map((o) => (o.id === item.id) ? { ...o, checked: value } : o),
		});
	};

	handleColumnEdit = (item, event) => {
		const target_id = event.target.id;
		const key = target_id.replace("_" + item.id, "");
		const value = event.target.value;

		this.setState({
			columns: this.state.columns.map((o) => (o.id === item.id) ? { ...o, [key]: value } : o),
		});
	};


	render() {
		const { widgetTemplate, errors, columns } = this.state;

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="modal-body widget-template-edit">
					<FormGroup controlId="name">
						<FormLabel>
							Nombre <span className="label-required">*</span>
						</FormLabel>
						<FormControl
							type="text"
							value={widgetTemplate.name}
							onChange={this.handleChange}
							placeholder="Nombre"
							isInvalid={this.checkValidField("name")}
						/>
						<FormControl.Feedback type="invalid">
							{errors.name}
						</FormControl.Feedback>
					</FormGroup>

					<p className="text-info mt30">Selecciona los widgets de la plantilla:</p>

					<div className="column-wrap">
						<ColumnList
							helperClass="column-item-active"
							onSortEnd={this.onSortEnd}
							handleColumn={this.handleColumn}
							items={columns}
							useDragHandle
						/>
					</div>

					{errors.widgets && (
						<p className="invalid-feedback d-block">{errors.widgets}</p>
					)}
				</div>

				<div className="modal-footer">
					<button
						type="button"
						onClick={this.props.handleClose}
						className="btn btn-outline-primary"
					>
						Cancelar
					</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.dashboardReducer,
	};
};

export default connect(mapStateToProps, dashboardActions)(WidgetTemplateEdit);
