import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel, Row, Col } from "react-bootstrap";
import { timeTypesService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import Switch from "components/Switch";
import * as timesActions from 'actions/timesActions';
import "./TimeTypes.scss";

class TimeTypeEdit extends React.Component {

	constructor(props) {
		super(props);
		// console.log(props);

		let time_type = {
			name: '',
			work_time: false,
			enabled: true,
			counts_as_total_hours: false
		}

		let isEdit = false;

		if (props.time_type) {
			time_type = props.time_type;
			isEdit = true;
		}

		this.state = {
			isEdit,
			isLoading: false,
			submitted: false,
			errors: {},
			time_type
		};
	}

	handleChange = event => {
		const { time_type } = this.state;

		this.setState({
			time_type: {
				...time_type,
				[event.target.id]: event.target.value
			},
		});
	}
	handleSwitch = event => {
		const { time_type } = this.state;

		this.setState({
			time_type: {
				...time_type,
				[event.target.id]: event.target.checked
			},
		});

	}

	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	handleSubmit = async event => {
		event.preventDefault();

		this.setState({ isLoading: true });
		let response = '';
		const props = this.props;

		// if edit => update; else => new
		if (this.state.isEdit) {
			response = await timeTypesService.update(this.state.time_type);
		}
		else {
			response = await timeTypesService.add(this.state.time_type);
		}

		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
			}, () => {
				toastr.success('¡Bien!', 'Cambios guardados correctamente');
				this.props.getTimeTypes();
				props.handleClose();
			});
		}
		else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response)
			});
		}
	}

	render() {
		const { time_type } = this.state;
		var isActive = time_type.enabled;
		var isWorkTime = time_type.work_time;
		var isCounting = time_type.counts_as_total_hours;

		return (

			<form onSubmit={this.handleSubmit}>
				<div className="modal-body">

					<FormGroup controlId="name">
						<FormLabel>Nombre <span className="label-required">*</span></FormLabel>
						<FormControl
							type="text"
							value={time_type.name}
							onChange={this.handleChange}
							placeholder="Nombre del tipo de tiempo"
							isInvalid={this.checkValidField('name')}
						/>

						<FormControl.Feedback type="invalid">
							{this.state.errors.name}
						</FormControl.Feedback>
					</FormGroup>

					<Row>
						<Col sm={7}>
							<FormGroup className="form-group-flex" controlId="work_time">
								<FormLabel>Tiempo de trabajo</FormLabel>
								<Switch
									controlId="work_time"
									isOn={isWorkTime}
									handleToggle={this.handleSwitch}
								/>

								<FormControl.Feedback type="invalid">
									{this.state.errors.work_time}
								</FormControl.Feedback>
							</FormGroup>
						</Col>
						<Col sm={5}>
							<FormGroup className="form-group-flex" controlId="enabled">
								<FormLabel>Activo</FormLabel>
								<Switch
									controlId="enabled"
									isOn={isActive}
									handleToggle={this.handleSwitch}
								/>

								<FormControl.Feedback type="invalid">
									{this.state.errors.enabled}
								</FormControl.Feedback>
							</FormGroup>
						</Col>
					</Row>

					<FormGroup className="form-group-flex" controlId="counts_as_total_hours">
						<FormLabel>Considerar en necesidades de personal</FormLabel>
						<Switch
							controlId="counts_as_total_hours"
							isOn={isCounting}
							handleToggle={this.handleSwitch}
						/>

						<FormControl.Feedback type="invalid">
							{this.state.errors.counts_as_total_hours}
						</FormControl.Feedback>
					</FormGroup>

				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		)
	}
}

const mapStateToProps = (reducers) => {
	return reducers.timesReducer;
};


export default connect(mapStateToProps, timesActions)(TimeTypeEdit)