import React from "react";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { FiCalendar, FiTrash2, FiClock } from "react-icons/fi";
import { MdPlaylistAddCheck } from "react-icons/md";
import Dropzone from "react-dropzone";
import moment from "moment";
import {
	FormGroup,
	FormControl,
	FormLabel,
	FormCheck,
	Col,
	Row,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // datepicker locale
registerLocale("es", es);

import { workflowsService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import * as absencesActions from "actions/absencesActions";
import * as employeesActions from "actions/employeesActions";
import { isAdmin, isSupervisor } from "utils/utils";

import "./Absences.scss";

class AbsenceEdit extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);
		const requester_id = props.user.id;

		let absence = {
			type: "Ausencias",
			isSingleDay: true,
			shift_id: "",
			status: "Pendiente",
			start: "",
			end: "",
			requester_id,
			attachments: [],
			workflow_hourly_requested: false
		};

		let isEdit = false;

		if (props.absence) {
			absence = props.absence;
			isEdit = true;
			absence.isSingleDay =
				absence.end === null || absence.end === "" || absence.end === undefined;
			absence.shift_id = absence.shift.id;
		}

		this.state = {
			isEdit,
			isLoading: false,
			submitted: false,
			errors: {},
			absence,
			files: [],
			disabled: props.disabled ? true : false,
		};
	}

	handleChange = (event) => {
		const { absence } = this.state;

		let key = event.target.id;
		let value = event.target.value;

		this.setState({
			absence: {
				...absence,
				[key]: value,
			},
		});
	};

	handleTypeChange = (event) => {
		const { absence } = this.state;

		let key = event.target.id;
		let value = event.target.value;

		// console.log(key);
		// console.log(value);

		if (event.target.id === "single" || event.target.id === "multi") {
			key = "isSingleDay";
			value = event.target.id === "single";


			this.setState({
				absence: {
					...absence,
					[key]: value,
					workflow_hourly_requested: false,
				},
			});
		}
		else {
			this.setState({
				absence: {
					...absence,
					workflow_hourly_requested: true,
					isSingleDay: false,
				},
			});
		}
	};

	handleDateChange = (id, date) => {
		const { absence } = this.state;

		let key = id;
		let value = moment(date).format("YYYY-MM-DD");

		this.setState({
			absence: {
				...absence,
				[key]: value,
			},
		});
	};

	handleTimeChange = (event) => {
		let rawValue = event.target.value;

		const { absence } = this.state;
		const today = new Date();
		let value_temp = rawValue.split(":");
		let value = "";

		if (rawValue !== "" && value_temp.length == 2) {
			value = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				value_temp[0],
				value_temp[1]
			);

			const momentValue = moment.utc(value);
			value = momentValue.format();
		}
		this.setState({
			absence: {
				...absence,
				start_hour: value,
			},
		});
	};

	checkValidField = (name) => {
		// console.log(this.state.submitted &&
		// 	this.state.errors[name] !== undefined &&
		// 	this.state.errors[name] !== "");
		return (
			this.state.submitted &&
			this.state.errors[name] !== undefined &&
			this.state.errors[name] !== ""
		);
	};

	handleSubmit = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });
		let response = "";
		const props = this.props;
		const { absence, files } = this.state;
		var formData = new FormData();
		var hasErrors = false;
		var errorMsg = "";
		const hasFiles = files.length > 0;

		//console.log(files);
		//console.log(absence);

		// prepare workflow
		if (absence.isSingleDay) absence.end = null;

		if (absence.workflow_hourly_requested && absence.start && absence.start_hour) {
			const start_day = new Date(absence.start);
			const start_time = new Date(absence.start_hour);

			let start_frankenstein = new Date(
				start_day.getFullYear(),
				start_day.getMonth(),
				start_day.getDate(),
				start_time.getHours(),
				start_time.getMinutes()
			);

			const momentValue = moment.utc(start_frankenstein);
			start_frankenstein = momentValue.format();

			// console.log(start_frankenstein);
			absence.start_hour = start_frankenstein;
		}

		if (absence.duration)
			absence.duration = absence.duration.toString().replace(",", ".") * 1;




		if (hasFiles) {
			files.map((file) => formData.append("filename", file));
		}

		// update workflow
		if (this.state.isEdit) {
			response = await workflowsService.update(absence);

			// si hay attachments temporales
			if (response.ok && hasFiles) {
				const responseAttachments = await workflowsService.updateAttachments(
					absence.id,
					formData
				);
				//console.log(responseAttachments);

				if (!responseAttachments.ok) {
					hasErrors = true;
					errorMsg = errorFormating(responseAttachments);
				}
			}
		} else {

			if (hasFiles) {
				// new workflow (with attachments)
				var blob = new Blob([JSON.stringify(absence)], {
					type: "application/json",
				});
				formData.append("workflow", blob);
				response = await workflowsService.addWithAttachments(formData);
			} else {
				response = await workflowsService.add(absence);
			}
		}

		if (!response.ok) {
			hasErrors = true;
			errorMsg = errorFormating(response);
		}

		//console.log(response);

		if (!hasErrors) {
			this.setState(
				{
					isLoading: false,
					errors: {},
				},
				() => {
					toastr.success("¡Bien!", "Cambios guardados correctamente");
					props.getEmployeeAbsences(props.user.id);
					props.handleClose();

					if (isAdmin() || isSupervisor()) {
						this.props.getPendingAbsences();
						this.props.getManagedAbsences();
					}
				}
			);
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorMsg,
			});
		}
	};

	onDrop = (files) => {
		this.setState({ files });
	};

	removeAttachment = async (attachment) => {
		const { absence } = this.state;
		// console.log(attachment);
		const response = await workflowsService.removeAttachment(
			absence.id,
			attachment.url
		);
		// console.log(response);
		if (response.ok) {
			this.setState({
				absence: {
					...absence,
					attachments: absence.attachments.filter(
						(att) => att.url !== attachment.url
					), // remove attachment from the array
				},
			});
		}
	};
	removeFile = (file) => {
		const { files } = this.state;
		// console.log(files);

		this.setState({
			// files : files.splice(i, 1) // remove the file from the array
			files: files.filter((f) => f.lastModified !== file.lastModified), // remove the file from the array
		});
	};

	getAttachment = async (attachment) => {
		const { absence } = this.state;
		// console.log(attachment);
		const response = await workflowsService.getAttachment(
			absence.id,
			attachment.url
		);
		// console.log(response);
		var url = window.URL.createObjectURL(response);
		var a = document.createElement("a");
		document.body.appendChild(a);
		a.href = url;
		a.download = attachment.name;
		a.click();
	};

	getHourlyContent = () => {
		const { absence, disabled } = this.state;
		return (<>
			<Row>
				<Col sm={6}>
					<FormGroup controlId="start_hour" className="form-group-time">
						<FormLabel>Empezar a las <span className="label-required">*</span></FormLabel>
						<FormControl
							type="time"
							value={
								absence.start_hour === null || absence.start_hour === undefined
									? ""
									: moment.utc(absence.start_hour).local().format("HH:mm")
							}
							onChange={this.handleTimeChange}
							disabled={disabled}
						/>
						<FiClock />

						<FormControl.Feedback type="invalid">
							{this.state.errors.start_hour}
						</FormControl.Feedback>
					</FormGroup>
				</Col>

				<Col sm={6}>
					<FormGroup controlId="duration">
						<FormLabel>Cantidad de horas <span className="label-required">*</span></FormLabel>
						<FormControl
							type="text"
							value={absence.duration ? absence.duration
								.toString()
								.replace(".", ",") : 0}
							onChange={this.handleChange}
							placeholder="0"
							disabled={disabled}
							isInvalid={this.checkValidField("duration")}
						/>

						<FormControl.Feedback type="invalid">
							{this.state.errors.duration}
						</FormControl.Feedback>
					</FormGroup>
				</Col>
			</Row>
		</>
		);
	};

	getSingleDayContent = () => {
		const { absence, disabled } = this.state;
		return (
			<FormGroup
				controlId="start"
				className={`form-date flex-col ${absence.start === "" && "empty"} ${this.checkValidField('start') && "is-invalid"}`}
			>
				<FormLabel>Fecha solicitada <span className="label-required">*</span></FormLabel>

				<DatePicker
					selected={absence.start === "" ? absence.start : moment(absence.start).toDate()}
					onChange={(date) => this.handleDateChange("start", date)}
					disabled={disabled}
					isInvalid={this.checkValidField("start")}
					className="form-control"
					dateFormat="dd/MM/yyyy"
					locale='es'
					placeholderText="dd/mm/aaaa"
				/>
				<FiCalendar />
				<FormControl.Feedback type="invalid">
					{this.state.errors.start}
				</FormControl.Feedback>
			</FormGroup>
		);
	};

	getStartEndContent = () => {
		const { absence, disabled } = this.state;
		// console.log(absence.start);
		// console.log(absence.end);
		return (
			<Row>
				<Col sm={6}>
					<FormGroup
						controlId="start"
						className={`form-date ${absence.start === "" && "empty"}  ${this.checkValidField('start') && "is-invalid"}`}
					>
						<FormLabel>Empieza <span className="label-required">*</span></FormLabel>
						<DatePicker
							selected={absence.start === "" || absence.start === null ? absence.start : moment(absence.start).toDate()}
							onChange={(date) => this.handleDateChange("start", date)}
							disabled={disabled}
							isInvalid={this.checkValidField("start")}
							className="form-control"
							dateFormat="dd/MM/yyyy"
							locale='es'
							placeholderText="dd/mm/aaaa"
						/>
						<FiCalendar />
						<FormControl.Feedback type="invalid">
							{this.state.errors.start}
						</FormControl.Feedback>
					</FormGroup>
				</Col>

				<Col sm={6}>
					<FormGroup
						controlId="end"
						className={`form-date ${absence.end === "" && "empty"}  ${this.checkValidField('end') && "is-invalid"}`}
					>
						<FormLabel>Termina</FormLabel>
						<DatePicker
							selected={absence.end === "" || absence.end === null ? absence.end : moment(absence.end).toDate()}
							onChange={(date) => this.handleDateChange("end", date)}
							disabled={disabled}
							isInvalid={this.checkValidField("end")}
							className="form-control"
							dateFormat="dd/MM/yyyy"
							locale='es'
							placeholderText="dd/mm/aaaa"
						/>
						<FiCalendar />
						<FormControl.Feedback type="invalid">
							{this.state.errors.end}
						</FormControl.Feedback>
					</FormGroup>
				</Col>
			</Row>
		);
	};

	render() {
		const { absence, disabled, errors } = this.state;
		const { shifts } = this.props;

		const optionList =
			shifts.length > 0 &&
			shifts.map((item, i) => (
				<option key={item.id} value={item.id}>
					{item.name} - {item.description}
				</option>
			));

		// already saved attachments
		const attachments =
			absence.attachments &&
			absence.attachments.map((attachment) => {
				var size = attachment.size / 1000000;
				var sizeLetters = "Mb";

				if (size < 1) {
					size = attachment.size / 1000;
					sizeLetters = "Kb";
				}

				return (
					<li className="file-item" key={attachment.url}>
						<span
							className="file-info"
							title={attachment.name}
							onClick={() => this.getAttachment(attachment)}
						>
							<span className="file-name">{attachment.name}</span>
							<span className="file-size">
								({size.toFixed(2)}
								{sizeLetters})
							</span>
						</span>
						{!disabled && (
							<div className="file-action">
								<MdPlaylistAddCheck className="file-saved" />
								<button
									type="button"
									className="btn btn-icon"
									title="Eliminar"
									onClick={() => this.removeAttachment(attachment)}
								>
									<FiTrash2 />
								</button>
							</div>
						)}
					</li>
				);
			});

		// temporary dropzone files
		const files =
			this.state.files.length > 0 &&
			this.state.files.map((file, i) => {
				var size = file.size / 1000000;
				var sizeLetters = "Mb";

				if (size < 1) {
					size = file.size / 1000;
					sizeLetters = "Kb";
				}

				return (
					<li className="file-item-temp" key={i}>
						<span className="file-info" title={file.name}>
							<span className="file-name">{file.name}</span>
							<span className="file-size">
								({size.toFixed(2)}
								{sizeLetters})
							</span>
						</span>
						{!disabled && (
							<div className="file-action">
								<button
									type="button"
									className="btn btn-icon"
									title="Eliminar"
									onClick={() => this.removeFile(file)}
								>
									<FiTrash2 />
								</button>
							</div>
						)}
					</li>
				);
			});

		return (
			<form onSubmit={this.handleSubmit}>
				<div className={`modal-body absence-edit ${disabled ? "disabled" : ""}`}>
					<FormGroup controlId="shift_id">
						<FormLabel>Turno <span className="label-required">*</span></FormLabel>
						<FormControl
							type="text"
							value={absence.shift_id}
							disabled={disabled}
							onChange={this.handleChange}
							isInvalid={this.checkValidField("shift_id")}
							as="select"
						>
							<option value="">Seleccionar turno</option>
							{optionList}
						</FormControl>

						<FormControl.Feedback type="invalid">
							{errors.shift_id}
						</FormControl.Feedback>
					</FormGroup>

					<FormGroup controlId="comments">
						<FormLabel>Comentarios</FormLabel>
						<FormControl
							as="textarea"
							value={absence.comments}
							onChange={this.handleChange}
							placeholder="Comentarios"
							disabled={disabled}
							isInvalid={this.checkValidField("comments")}
						/>

						<FormControl.Feedback type="invalid">
							{errors.comments}
						</FormControl.Feedback>
					</FormGroup>

					<FormGroup controlId="absenceType">
						<FormLabel className="hidden">Duración</FormLabel>
						<div className="btn-choice-group">
							<FormCheck
								type="radio"
								id="workflow_hourly_requested"
								label="Horas"
								value="true"
								disabled={disabled}
								checked={absence.workflow_hourly_requested}
								bsPrefix={
									absence.workflow_hourly_requested ? "btn-choice active" : "btn-choice"
								}
								onChange={this.handleTypeChange}
							/>
							<FormCheck
								type="radio"
								id="single"
								label="Un día"
								value="false"
								disabled={disabled}
								checked={absence.isSingleDay}
								bsPrefix={
									absence.isSingleDay && !absence.workflow_hourly_requested ? "btn-choice active" : "btn-choice"
								}
								onChange={this.handleTypeChange}
							/>
							<FormCheck
								type="radio"
								id="multi"
								label="Varios días"
								value="false"
								disabled={disabled}
								checked={!absence.isSingleDay && !absence.workflow_hourly_requested}
								bsPrefix={
									!absence.isSingleDay && !absence.workflow_hourly_requested ? "btn-choice active" : "btn-choice"
								}
								onChange={this.handleTypeChange}
							/>
						</div>
					</FormGroup>


					{/* Single Day content */}
					{(absence.workflow_hourly_requested || absence.isSingleDay) && this.getSingleDayContent()}
					{/* Hourly content */}
					{absence.workflow_hourly_requested && this.getHourlyContent()}
					{/* Multiple days content */}
					{!absence.workflow_hourly_requested && !absence.isSingleDay && this.getStartEndContent()}


					{(!disabled || (disabled && attachments.length > 0)) && (
						<>
							<label className="form-label">{disabled ? "Documentos adjuntos" : "Adjuntar documentos"}</label>
							<Dropzone onDrop={this.onDrop}>
								{({ getRootProps, getInputProps }) => (
									<section className="dropzone-container">
										{!disabled && (
											<div {...getRootProps({ className: "dropzone" })}>
												<input {...getInputProps()} />
												<p>Arrastra tus archivos o haz clic para seleccionar</p>
											</div>
										)}
										<ul className="dropzone-files">
											{attachments}
											{files}
										</ul>
									</section>
								)}
							</Dropzone>
						</>
					)}


					{absence.answer_comments && (
						<FormGroup controlId="answer_comments" className="mt25 mb10">
							{/* <FormLabel>Comentarios aprobación</FormLabel> */}
							<FormControl
								as="textarea"
								value={absence.answer_comments}
								onChange={this.handleChange}
								placeholder="Comentarios"
								disabled={disabled}
								className="answer-comments"
								isInvalid={this.checkValidField("answer_comments")}
							/>
						</FormGroup>
					)}


					{errors.allow_hourly_absence_request && <p className="error-message">{errors.allow_hourly_absence_request}</p>}
				</div>

				<div className="modal-footer">
					<button
						type="button"
						onClick={this.props.handleClose}
						className="btn btn-outline-primary"
					>
						Cancelar
					</button>
					{!disabled && (
						<SubmitButton
							type="submit"
							isLoading={this.state.isLoading}
							text="Guardar"
							loadingText="Guardando..."
						/>
					)}
				</div>
			</form>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.shiftsReducer,
		...reducers.absencesReducer,
		...reducers.employeesReducer,
		user: reducers.authReducer.user,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getEmployeeAbsences: (employee_id) =>
		dispatch(employeesActions.getEmployeeAbsences(employee_id)),
	getPendingAbsences: (options) => dispatch(absencesActions.getPendingAbsences(options)),
	getManagedAbsences: (options) => dispatch(absencesActions.getManagedAbsences(options)),
	getAbsenceShifts: () => dispatch(shiftsActions.getAllShifts({ shift_type: "Ausencia,Vacaciones" })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AbsenceEdit);
