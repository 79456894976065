import React from "react";
import logo from "assets/logo.svg";
import { Link } from "react-router-dom";
import { authService } from "services";

class EndTrial extends React.Component {
	componentDidMount() {
		authService.cleanStorage();
	}
	render() {
		return (
			<React.Fragment>
				<div className="page-end-trial container-fluid">
					<Link to="/">
						<img className="main-logo" src={logo} alt="Logo Plain" />
					</Link>
					<h1>Periodo de prueba finalizado</h1>
					<p>
						El periodo de prueba ha caducado, por favor, para activar la
						suscripción, contacta con un administrador de Plain dentro de tu
						empresa.
					</p>
				</div>
			</React.Fragment>
		);
	}
}

export default EndTrial;
