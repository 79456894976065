import { groupsService, staffingLevelsService } from "services";

export const getAllGroups = (options) => async (dispatch) => {
	dispatch({
		type: "LOADING",
	});

	try {
		const list = await groupsService.getAll(options);
		const type =
			options && options.variant == "detailed"
				? "GET_DETAILED_GROUPS"
				: "GET_GROUPS";

		dispatch({
			type,
			payload: list.data,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getChildGroups = () => async (dispatch) => {
	dispatch({
		type: "LOADING",
	});

	try {
		const list = await groupsService.getAll({ is_multigroup: false });
		// console.log(list);
		dispatch({
			type: "GET_CHILD_GROUPS",
			payload: list.data,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

// export const getGroup = (groupId) => async (dispatch) => {

// 	dispatch({
// 		type: 'LOADING'
// 	});

// 	try{
// 		const g = await groupsService.getById(groupId);
// 		// console.log(g);
// 		dispatch({
// 			type: 'GET_GROUP',
// 			payload: g
// 		});
// 	}
// 	catch (error){
// 		console.log('Error: ' + error.message);
// 			dispatch({
// 				type: 'ERROR',
// 				payload: error.message
// 			});
// 	}
// }

export const getAllStaffingLevels = (query) => async (dispatch) => {
	dispatch({
		type: "LOADING",
	});

	try {
		const list = await staffingLevelsService.getAll(query);

		dispatch({
			type: "GET_STAFFING_LEVELS",
			payload: list.data,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getSelectableStaffingLevels = () => async (dispatch) => {
	dispatch({
		type: "LOADING",
	});

	try {
		const list = await staffingLevelsService.getSelectable();

		dispatch({
			type: "GET_SELECT_STAFFING_LEVELS",
			payload: list.data,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getGroupStaffingLevels = (groupId) => async (dispatch) => {
	dispatch({ type: "LOADING" });

	try {
		const list = await groupsService.getStaffingLevels(groupId);

		dispatch({
			type: "GET_GROUP_STAFFING_LEVELS",
			payload: list,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getStaffingLevelAssignments = (options) => async (dispatch) => {
	// dispatch({
	// 	type: 'LOADING'
	// });

	try {
		const response = await staffingLevelsService.getStaffingLevelAssignments(
			options
		);
		// console.log(response);

		dispatch({
			type: "GET_STAFFING_LEVEL_ASSIGNMENTS",
			payload: response,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getStaffingLevelCurrentAssignments = (options) => async (dispatch) => {
	// dispatch({
	// 	type: 'LOADING'
	// });

	try {
		const response = await staffingLevelsService.getStaffingLevelCurrentAssignments(
			options
		);
		// console.log(response);

		dispatch({
			type: "GET_STAFFING_LEVEL_CURRENT_ASSIGNMENTS",
			payload: response,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getStaffingLevelHourlyAssignments = (options) => async (dispatch) => {
	// dispatch({
	// 	type: 'LOADING'
	// });

	try {
		const response = await staffingLevelsService.getStaffingLevelHourlyAssignments(
			options
		);
		// console.log(response);

		dispatch({
			type: "GET_STAFFING_LEVEL_HOURLY_ASSIGNMENTS",
			payload: response,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getGroupSupervisors = (groupId) => async (dispatch) => {
	dispatch({ type: "LOADING" });

	try {
		const list = await groupsService.getSupervisors(groupId);
		// console.log(list);

		dispatch({
			type: "GET_GROUP_SUPERVISORS",
			payload: list,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};
