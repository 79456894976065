import React from "react";
import { toastr } from "react-redux-toastr";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import moment from "moment";
import {
	FormGroup,
	FormControl,
	FormLabel,
	InputGroup,
	Nav,
	Tab,
} from "react-bootstrap";
import { iPlanService } from "services";
import { errorFormating, renderErrorList } from "utils/utils";
import { FaMagic } from "react-icons/fa";
import { FiX, FiInfo } from "react-icons/fi";
import { DateRangePicker } from "react-dates";
import SubmitButton from "components/SubmitButton";
import * as groupsActions from "actions/groupsActions";
import * as calendarsActions from 'actions/calendarsActions';
import Switch from "components/Switch";
import { getFirstLastInterval } from "utils/datetime";
import "./Plans.scss";

class PlanMagic extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		const magic_plan = {
			start: this.props.start || "",
			end: this.props.end || "",
			group_id: this.props.group_id || "",
			max_working_hours_between_restdays: {
				active: false,
				value: 0
			},
			extra_hours_allowed: false,
			over_assign_needs: false,
			balance_working_hours: false,
			balance_shifts_by_employee: false,
			fraction_shifts: false,
			default_fractioned_shift_id: "",
			concatenate_shifts_without_rests_per_day: false,
			minimum_daily_hours: {
				active: false,
				value: 0
			},
			maximum_daily_hours: {
				active: false,
				value: 0
			},
			min_resthours_between_workdays: {
				active: false,
				value: 0
			},
			min_resthours_between_shifts: {
				active: false,
				value: 0
			},
			min_weekly_restdays: {
				active: false,
				value: 0
			},
			min_consecutive_restdays: {
				active: false,
				value: 0
			},
			max_consecutive_restdays: {
				active: false,
				value: 0
			},
			rest_day_types: {
				active: false,
				value: []
			},
			minimum_free_weekends: {
				active: false,
				value: 0
			},
			maximize_skills: false,
			maximize_function_priorities: false,
			minimize_shift_changes: false,
			ubiquitous_employees: false,
			timelimit: 0,
			separated_restdays: false,
			forbid_unassigned_shifts: false,
		};

		let isEdit = false;

		if (props.magic_plan) {
			magic_plan = props.magic_plan;
			isEdit = true;
		}

		this.state = {
			isEdit,
			isLoading: false,
			submitted: false,
			errors: {},
			error: "",
			error_footer: "",
			magic_plan,
			new_day_type: {},
			selected_day_types: [],
		};

	}

	componentDidMount() {
		this.props.getSelectableDayTypes();
		this.props.getAllGroups();
	}

	onDatesChange = (dates) => {
		var { startDate, endDate } = dates;
		const { magic_plan } = this.state;

		if (startDate)
			startDate = startDate.format("YYYY-MM-DD");

		if (endDate)
			endDate = endDate.format("YYYY-MM-DD");

		this.setState({
			magic_plan: {
				...magic_plan,
				start: startDate,
				end: endDate,
			},
		});
	};


	handleChange = (event) => {
		const { magic_plan } = this.state;

		const key = event.target.id;
		let value = event.target.value;
		value = (value === "on") ? event.target.checked : value * 1;

		// console.log(key);

		if (key.indexOf(".") > -1) {
			const key_split = key.split(".");
			const key0 = key_split[0];
			const key1 = key_split[1];
			magic_plan[key0][key1] = value;
		}
		// extra_hours_allowed y over_assign_needs son excluyentes
		else if (key === "extra_hours_allowed" && value) {
			magic_plan.extra_hours_allowed = value;
			magic_plan.over_assign_needs = false;
		}
		else if (key === "over_assign_needs" && value) {
			magic_plan.over_assign_needs = value;
			magic_plan.extra_hours_allowed = false;
		}
		else {
			magic_plan[key] = value;
		}

		this.setState({
			magic_plan,
		});
	};

	handleChangeFractionShifts = (event) => {
		const { magic_plan } = this.state;

		const key = event.target.id;
		let value = event.target.value;
		value = (value === "true");

		magic_plan[key] = value;

		// console.log(magic_plan);

		this.setState({
			magic_plan,
		});
	};

	checkValidField = (name) => {
		return (
			this.state.submitted &&
			this.state.errors[name] !== undefined &&
			this.state.errors[name] !== ""
		);
	};

	checkInvalidRange = () => {
		const { magic_plan, submitted } = this.state;
		return (
			submitted &&
			(magic_plan.start === "" ||
				magic_plan.end === "" ||
				magic_plan.start === null ||
				magic_plan.end === null)
		);
	};

	prepareDayTypesToSubmit = () => {
		const { selected_day_types } = this.state;

		return selected_day_types.map((day_type) => (day_type.name));
	}

	handleSubmit = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		const { magic_plan, selected_day_types } = this.state;
		const props = this.props;
		const magic_plan_to_send = { ...magic_plan };

		// prepara rest_day_types si hay tipos de día seleccionados
		if (selected_day_types.length > 0) {
			magic_plan_to_send.rest_day_types = {
				active: true,
				value: this.prepareDayTypesToSubmit(),
			}
		} else {
			magic_plan_to_send.rest_day_types = {
				active: false,
				value: [],
			}
		}

		if (magic_plan.timelimit <= 0)
			magic_plan_to_send.timelimit = null;

		// console.log(magic_plan_to_send);

		const response = await iPlanService.updateMagicPlan(magic_plan_to_send);
		// console.log(response);

		if (response.ok) {
			this.setState(
				{
					isLoading: false,
					errors: {},
				},
				() => {
					// Redirect a iplan view
					props.history.push({
						pathname: `/intelligent-plan/${magic_plan.group_id}/${magic_plan.start}@${magic_plan.end}`,
						state: { detail: response }
					})

					props.handleClose();
				}
			);
		} else {
			// console.log(errorFormating(response));

			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response),
				error_footer: renderErrorList(response),
			});
		}
	};


	// tipos de dia para festivos

	handleAddChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	}

	addDayType = () => {
		let { selected_day_types, new_day_type } = this.state;
		let isValid = true;
		let day_type;
		const errors = {};

		if (new_day_type === undefined) {
			isValid = false;
		}
		else {
			day_type = this.props.select_day_types.find(item => item.id === parseInt(new_day_type));
			if (day_type === undefined) {
				isValid = false;
				errors.day_type = false;
			}
		}

		if (isValid) {
			// check if day already exists
			const exists = selected_day_types.find(item => item.id === parseInt(new_day_type));

			if (!exists) {
				selected_day_types = selected_day_types.concat(day_type);
			}

			this.setState({
				selected_day_types,
				errors: {},
				new_day_type: {},
			});
		}
		else {
			this.setState({
				errors,
			});
		}
	}

	addBulkDayTypes = (start, end) => {
		const selected_day_types = [];
		const { select_day_types } = this.props;

		for (let i = start; i <= end; i++) {
			const day_type = select_day_types.find(item => item.weekday === i);
			selected_day_types.push(day_type)
		}

		this.setState({
			selected_day_types,
			errors: {},
			new_day_type: {},
		});
	}

	removeDayType = (idDayType) => {
		this.setState((prevState, props) => ({
			selected_day_types: prevState.selected_day_types.filter(item => item.id !== parseInt(idDayType))
		}));
	}

	getSelectedDayTypes = () => this.state.selected_day_types.map((item) => {
		return (
			<li key={item.id} aria-label={item.description} title={item.description} className="tag-default tag-delete" style={{ backgroundColor: item.background_color && item.background_color }}>
				{item.name}
				<button type="button" onClick={() => this.removeDayType(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});


	render() {
		const { magic_plan, error, new_day_type, errors, group_id, submitted, selected_day_types } = this.state;
		const { detailed_groups, select_day_types, attendance_shifts } = this.props;

		const max_working_hours_between_restdays_active = magic_plan.max_working_hours_between_restdays.active;
		const extra_hours_allowed = magic_plan.extra_hours_allowed;
		const balance_working_hours = magic_plan.balance_working_hours;
		const balance_shifts_by_employee = magic_plan.balance_shifts_by_employee;
		const over_assign_needs = magic_plan.over_assign_needs;
		const minimum_daily_hours_active = magic_plan.minimum_daily_hours.active;
		const maximum_daily_hours_active = magic_plan.maximum_daily_hours.active;
		const min_resthours_between_workdays_active = magic_plan.min_resthours_between_workdays.active;
		const min_resthours_between_shifts_active = magic_plan.min_resthours_between_shifts.active;
		const min_weekly_restdays_active = magic_plan.min_weekly_restdays.active;
		const min_consecutive_restdays_active = magic_plan.min_consecutive_restdays.active;
		const minimum_free_weekends_active = magic_plan.minimum_free_weekends.active;
		const concatenate_shifts_without_rests_per_day = magic_plan.concatenate_shifts_without_rests_per_day;
		const max_consecutive_restdays_active = magic_plan.max_consecutive_restdays.active;
		const maximize_skills = magic_plan.maximize_skills;
		const maximize_function_priorities = magic_plan.maximize_function_priorities;
		const minimize_shift_changes = magic_plan.minimize_shift_changes;
		const ubiquitous_employees = magic_plan.ubiquitous_employees;
		const separated_restdays = magic_plan.separated_restdays;
		const forbid_unassigned_shifts = magic_plan.forbid_unassigned_shifts;

		const optionShiftList =
			attendance_shifts.length > 0 &&
			attendance_shifts.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name}  {getFirstLastInterval(item.time_intervals)}
					</option>
				);
			}, this);

		const optionGroupList =
			detailed_groups.length > 0 &&
			detailed_groups.map((item, i) => {
				let multigroup = item.is_multigroup ? " (multigrupo)" : "";
				return (
					<option key={i} value={item.id}>
						{item.name}
						{multigroup}
					</option>
				);
			}, this);

		const optionDayTypeList = select_day_types.length > 0 && select_day_types.map((item, i) => {

			const label = item.description ? (item.name + " (" + item.description + ")") : item.name;
			return (
				<option key={i} value={item.id}>{label}</option>
			)
		}, this);

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="modal-body plan-magic-edit">

					<div className="flex-group">
						<FormGroup controlId="group_id">
							<FormLabel>Grupo</FormLabel>
							<FormControl
								value={magic_plan.group_id}
								onChange={this.handleChange}
								isInvalid={submitted && magic_plan.group_id === ""}
								as="select"
							>
								<option value="">Seleccionar grupo</option>
								{optionGroupList}
							</FormControl>
							<FormControl.Feedback type="invalid">
								Selecciona un grupo para la planificación
							</FormControl.Feedback>
						</FormGroup>

						<FormGroup
							controlId="fraction_shifts"
						>
							<FormLabel>
								Nivel de detalle de la planificación
							</FormLabel>
							<FormControl
								value={magic_plan.fraction_shifts}
								onChange={this.handleChangeFractionShifts}
								as="select"
							>
								<option value="false">Planificar turnos completos</option>
								<option value="true">Planificar fracciones de 30 minutos</option>
							</FormControl>
						</FormGroup>

						<FormGroup
							className={`form-group-periodo ${this.checkInvalidRange() ? "is-invalid" : ""
								}`}
						>
							<FormLabel>Periodo</FormLabel>
							<DateRangePicker
								startDateId="startDateId"
								endDateId="endDateId"
								startDate={
									magic_plan.start ? moment(magic_plan.start) : null
								}
								endDate={magic_plan.end ? moment(magic_plan.end) : null}
								startDatePlaceholderText="Inicio"
								endDatePlaceholderText="Fin"
								onDatesChange={this.onDatesChange}
								focusedInput={this.state.focusedInput}
								hideKeyboardShortcutsPanel={true}
								isOutsideRange={() => false}
								minimumNights={0}
								onFocusChange={(focusedInput) =>
									this.setState({ focusedInput })
								}
							/>
							<FormControl
								className="hidden"
								isInvalid={this.checkInvalidRange()}
							/>

							<FormControl.Feedback type="invalid">
								{error !== "" ? error : "Indica inicio y fin de periodo"}
							</FormControl.Feedback>
						</FormGroup>
					</div>

					<div className="info-note">
						<p className="info-intro"><FiInfo className="info-icon" /> Para poder planificar de forma automática ten en cuenta que:</p>
						<ul>
							<li>Todos los empleados tienen que tener funciones</li>
							<li>Tiene que haber al menos un turno de disponibilidad en la planificación</li>
							<li>Tiene que haber necesidades de personal</li>
						</ul>
					</div>

					<h3 className="subtitle">Configuración de restricciones</h3>


					<Tab.Container defaultActiveKey="horas">
						<Nav variant="tabs">
							<Nav.Item>
								<Nav.Link eventKey="horas">Horas de trabajo</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="descansos">Descansos</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="ubicaciones">Ubicaciones</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="funciones">Funciones</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="rendimiento">Rendimiento</Nav.Link>
							</Nav.Item>
						</Nav>
						<Tab.Content>
							<Tab.Pane eventKey="horas">
								<FormGroup
									className="form-group-flex form-row-switch form-group-main"
									controlId="extra_hours_allowed"
								>
									<FormLabel>
										Permitir hacer horas extra
									</FormLabel>
									<Switch
										controlId="extra_hours_allowed"
										isOn={extra_hours_allowed}
										handleToggle={this.handleChange}
									/>
								</FormGroup>

								<FormGroup
									className="form-group-flex form-row-switch form-group-main"
									controlId="over_assign_needs"
								>
									<FormLabel>
										Planificar turnos hasta completar horas objetivo
									</FormLabel>
									<Switch
										controlId="over_assign_needs"
										isOn={over_assign_needs}
										handleToggle={this.handleChange}
									/>
								</FormGroup>


								<FormGroup
									className="form-group-flex form-row-switch form-group-main"
									controlId="balance_working_hours"
								>
									<FormLabel>
										Maximizar el equilibrio de horas trabajadas de todos los empleados
									</FormLabel>
									<Switch
										controlId="balance_working_hours"
										isOn={balance_working_hours}
										handleToggle={this.handleChange}
									/>
								</FormGroup>


								<FormGroup
									className="form-group-flex form-row-switch form-group-main"
									controlId="balance_shifts_by_employee"
								>
									<FormLabel>
										Balancear asignación de turnos de trabajo
									</FormLabel>
									<Switch
										controlId="balance_shifts_by_employee"
										isOn={balance_shifts_by_employee}
										handleToggle={this.handleChange}
									/>
								</FormGroup>


								<FormGroup
									className="form-group-flex form-row-switch form-group-main"
									controlId="minimize_shift_changes"
								>
									<FormLabel>
										Minimizar el cambio de turno durante la semana
									</FormLabel>
									<Switch
										controlId="minimize_shift_changes"
										isOn={minimize_shift_changes}
										handleToggle={this.handleChange}
									/>
								</FormGroup>



								<div className="switch-input-row">
									<FormGroup
										className="form-group-flex form-row-switch form-group-main"
										controlId="max_working_hours_between_restdays.active"
									>
										<FormLabel>
											Horas máximas a trabajar entre días de descanso
										</FormLabel>
										<Switch
											controlId="max_working_hours_between_restdays.active"
											isOn={max_working_hours_between_restdays_active}
											handleToggle={this.handleChange}
										/>
									</FormGroup>
									<FormGroup className="form-group-input" controlId="max_working_hours_between_restdays.value">
										<InputGroup>
											<FormControl
												type="text"
												onChange={this.handleChange}
												placeholder="0"
												isInvalid={this.checkValidField("max_working_hours_between_restdays.value")}
												disabled={!max_working_hours_between_restdays_active}
											/>

											<InputGroup.Append>
												<InputGroup.Text>horas</InputGroup.Text>
											</InputGroup.Append>
										</InputGroup>
									</FormGroup>
								</div>

								<div className="switch-input-row">
									<FormGroup
										className="form-group-flex form-row-switch form-group-main"
										controlId="minimum_daily_hours.active"
									>
										<FormLabel>
											Horas mínimas a trabajar en una jornada de trabajo
										</FormLabel>
										<Switch
											controlId="minimum_daily_hours.active"
											isOn={minimum_daily_hours_active}
											handleToggle={this.handleChange}
										/>
									</FormGroup>

									<FormGroup className="form-group-input" controlId="minimum_daily_hours.value">
										<InputGroup>
											<FormControl
												type="text"
												onChange={this.handleChange}
												placeholder="0"
												isInvalid={this.checkValidField("minimum_daily_hours.value")}
												disabled={!minimum_daily_hours_active}
											/>

											<InputGroup.Append>
												<InputGroup.Text>horas</InputGroup.Text>
											</InputGroup.Append>
										</InputGroup>
									</FormGroup>
								</div>

								<div className="switch-input-row">
									<FormGroup
										className="form-group-flex form-row-switch form-group-main"
										controlId="maximum_daily_hours.active"
									>
										<FormLabel>
											Horas máximas a trabajar en una jornada de trabajo
										</FormLabel>
										<Switch
											controlId="maximum_daily_hours.active"
											isOn={maximum_daily_hours_active}
											handleToggle={this.handleChange}
										/>
									</FormGroup>

									<FormGroup className="form-group-input" controlId="maximum_daily_hours.value">
										<InputGroup>
											<FormControl
												type="text"
												onChange={this.handleChange}
												placeholder="0"
												isInvalid={this.checkValidField("maximum_daily_hours.value")}
												disabled={!maximum_daily_hours_active}
											/>

											<InputGroup.Append>
												<InputGroup.Text>horas</InputGroup.Text>
											</InputGroup.Append>
										</InputGroup>
									</FormGroup>
								</div>

								{magic_plan.fraction_shifts && (<>
									<FormGroup controlId="default_fractioned_shift_id" className="form-group-flex form-row-select form-group-main">
										<FormLabel>Turno por defecto</FormLabel>
										<FormControl
											value={magic_plan.default_fractioned_shift_id}
											onChange={this.handleChange}
											placeholder="Turno"
											isInvalid={this.checkValidField('default_fractioned_shift_id')}
											as="select"
										>
											<option value="">Selecciona turno</option>
											{optionShiftList}
										</FormControl>
										<FormControl.Feedback type="invalid">
											{errors.default_fractioned_shift_id}
										</FormControl.Feedback>
									</FormGroup>
								</>)}


							</Tab.Pane>
							<Tab.Pane eventKey="descansos" className="plan-rules">

								{magic_plan.fraction_shifts && (<>
									<FormGroup
										className="form-group-flex form-row-switch form-group-main"
										controlId="concatenate_shifts_without_rests_per_day"
									>
										<FormLabel>
											No permitir descansos dentro de un turno
										</FormLabel>
										<Switch
											controlId="concatenate_shifts_without_rests_per_day"
											isOn={concatenate_shifts_without_rests_per_day}
											handleToggle={this.handleChange}
										/>
									</FormGroup>
								</>)}


								<div className="switch-input-row">
									<FormGroup
										className="form-group-flex form-row-switch form-group-main"
										controlId="min_resthours_between_workdays.active"
									>
										<FormLabel>
											Horas mínimas de descanso entre jornadas de trabajo
										</FormLabel>
										<Switch
											controlId="min_resthours_between_workdays.active"
											isOn={min_resthours_between_workdays_active}
											handleToggle={this.handleChange}
										/>
									</FormGroup>
									<FormGroup className="form-group-input" controlId="min_resthours_between_workdays.value">
										<InputGroup>
											<FormControl
												type="text"
												onChange={this.handleChange}
												placeholder="0"
												isInvalid={this.checkValidField("min_resthours_between_workdays.value")}
												disabled={!min_resthours_between_workdays_active}
											/>

											<InputGroup.Append>
												<InputGroup.Text>horas</InputGroup.Text>
											</InputGroup.Append>
										</InputGroup>
									</FormGroup>
								</div>


								<div className="switch-input-row">
									<FormGroup
										className="form-group-flex form-row-switch form-group-main"
										controlId="min_resthours_between_shifts.active"
									>
										<FormLabel>
											Horas mínimas de descanso entre turnos de trabajo
										</FormLabel>
										<Switch
											controlId="min_resthours_between_shifts.active"
											isOn={min_resthours_between_shifts_active}
											handleToggle={this.handleChange}
										/>
									</FormGroup>

									<FormGroup className="form-group-input" controlId="min_resthours_between_shifts.value">
										<InputGroup>
											<FormControl
												type="text"
												onChange={this.handleChange}
												placeholder="0"
												isInvalid={this.checkValidField("min_resthours_between_shifts.value")}
												disabled={!min_resthours_between_shifts_active}
											/>

											<InputGroup.Append>
												<InputGroup.Text>horas</InputGroup.Text>
											</InputGroup.Append>
										</InputGroup>
									</FormGroup>
								</div>

								<FormGroup
									className="form-group-flex form-row-switch form-group-main"
									controlId="separated_restdays"
								>
									<FormLabel>
										Evitar solapamientos de descansos definidos en la ficha del empleado
									</FormLabel>
									<Switch
										controlId="separated_restdays"
										isOn={separated_restdays}
										handleToggle={this.handleChange}
									/>
								</FormGroup>


								<div className="switch-input-row">
									<FormGroup
										className="form-group-flex form-row-switch form-group-main"
										controlId="min_weekly_restdays.active"
									>
										<FormLabel>
											Días de descanso semanal mínimos
										</FormLabel>
										<Switch
											controlId="min_weekly_restdays.active"
											isOn={min_weekly_restdays_active}
											handleToggle={this.handleChange}
										/>
									</FormGroup>

									<FormGroup className="form-group-input" controlId="min_weekly_restdays.value">
										<InputGroup>
											<FormControl
												type="text"
												onChange={this.handleChange}
												placeholder="0"
												isInvalid={this.checkValidField("min_weekly_restdays.value")}
												disabled={!min_weekly_restdays_active}
											/>

											<InputGroup.Append>
												<InputGroup.Text>días</InputGroup.Text>
											</InputGroup.Append>
										</InputGroup>
									</FormGroup>
								</div>

								<div className="switch-input-row">
									<FormGroup
										className="form-group-flex form-row-switch form-group-main"
										controlId="minimum_free_weekends.active"
									>
										<FormLabel>
											Fines de semana libres mínimos
										</FormLabel>
										<Switch
											controlId="minimum_free_weekends.active"
											isOn={minimum_free_weekends_active}
											handleToggle={this.handleChange}
										/>
									</FormGroup>

									<FormGroup className="form-group-input" controlId="minimum_free_weekends.value">
										<InputGroup>
											<FormControl
												type="text"
												onChange={this.handleChange}
												placeholder="0"
												isInvalid={this.checkValidField("minimum_free_weekends.value")}
												disabled={!minimum_free_weekends_active}
											/>

											<InputGroup.Append>
												<InputGroup.Text>findes</InputGroup.Text>
											</InputGroup.Append>
										</InputGroup>
									</FormGroup>
								</div>

								<div className="switch-input-row">
									<FormGroup
										className="form-group-flex form-row-switch form-group-main"
										controlId="min_consecutive_restdays.active"
									>
										<FormLabel>
											Días de descanso consecutivos (mínimo)
										</FormLabel>
										<Switch
											controlId="min_consecutive_restdays.active"
											isOn={min_consecutive_restdays_active}
											handleToggle={this.handleChange}
										/>
									</FormGroup>

									<FormGroup className="form-group-input" controlId="min_consecutive_restdays.value">
										<InputGroup>
											<FormControl
												type="text"
												onChange={this.handleChange}
												placeholder="0"
												isInvalid={this.checkValidField("min_consecutive_restdays.value")}
												disabled={!min_consecutive_restdays_active}
											/>

											<InputGroup.Append>
												<InputGroup.Text>días</InputGroup.Text>
											</InputGroup.Append>
										</InputGroup>
									</FormGroup>
								</div>

								<div className="switch-input-row">
									<FormGroup
										className="form-group-flex form-row-switch form-group-main"
										controlId="max_consecutive_restdays.active"
									>
										<FormLabel>
											Días de descanso consecutivos (máximo)
										</FormLabel>
										<Switch
											controlId="max_consecutive_restdays.active"
											isOn={max_consecutive_restdays_active}
											handleToggle={this.handleChange}
										/>
									</FormGroup>

									<FormGroup className="form-group-input" controlId="max_consecutive_restdays.value">
										<InputGroup>
											<FormControl
												type="text"
												onChange={this.handleChange}
												placeholder="0"
												isInvalid={this.checkValidField("max_consecutive_restdays.value")}
												disabled={!max_consecutive_restdays_active}
											/>

											<InputGroup.Append>
												<InputGroup.Text>días</InputGroup.Text>
											</InputGroup.Append>
										</InputGroup>
									</FormGroup>
								</div>


								<div className="row-adding">
									<FormGroup className="form-group-flex" controlId="new_day_type">
										<FormLabel>Tipos de día permitidos para descanso</FormLabel>

										<div className="form-box-wrap">
											<div className="form-box">
												<FormControl
													onChange={this.handleAddChange}
													value={new_day_type}
													placeholder="Selecciona la función"
													isInvalid={this.checkValidField('day_type_ids')}
													as="select"
												>
													<option value="">Selecciona un tipo de día</option>
													{optionDayTypeList}
												</FormControl>
												<FormControl.Feedback type="invalid">
													{errors.day_type_ids}
												</FormControl.Feedback>

												{selected_day_types.length === 0 ? (
													<div className="row-adding-help">
														<button className="btn-link" onClick={() => this.addBulkDayTypes(1, 5)}>Añadir de lunes a viernes</button>
														<button className="btn-link" onClick={() => this.addBulkDayTypes(1, 7)}>Añadir de lunes a domingo</button>
													</div>) : (
													<ul className="list-tags">
														{this.getSelectedDayTypes()}
													</ul>)
												}

											</div>
											<button type="button" onClick={this.addDayType} className="btn btn-primary">Añadir</button>
										</div>
									</FormGroup>
								</div>





							</Tab.Pane>
							<Tab.Pane eventKey="ubicaciones" className="plan-ubications">
								<FormGroup
									className="form-group-flex form-row-switch form-group-main"
									controlId="maximize_skills"
								>
									<FormLabel>
										Maximizar la planificación con los trabajadores con mayor habilidad en la ubicación
									</FormLabel>
									<Switch
										controlId="maximize_skills"
										isOn={maximize_skills}
										handleToggle={this.handleChange}
									/>
								</FormGroup>

								<FormGroup
									className="form-group-flex form-row-switch form-group-main"
									controlId="ubiquitous_employees"
								>
									<FormLabel>
										Asignar múltiples ubicaciones en un turno
									</FormLabel>
									<Switch
										controlId="ubiquitous_employees"
										isOn={ubiquitous_employees}
										handleToggle={this.handleChange}
									/>
								</FormGroup>
							</Tab.Pane>

							<Tab.Pane eventKey="funciones" className="plan-functions">
								<FormGroup
									className="form-group-flex form-row-switch form-group-main"
									controlId="maximize_function_priorities"
								>
									<FormLabel>
										Maximizar la planificación con los trabajadores con mayor prioridad en la función
									</FormLabel>
									<Switch
										controlId="maximize_function_priorities"
										isOn={maximize_function_priorities}
										handleToggle={this.handleChange}
									/>
								</FormGroup>

								<FormGroup
									className="form-group-flex form-row-switch form-group-main"
									controlId="forbid_unassigned_shifts"
								>
									<FormLabel>
										Impedir que un turno se quede sin personas
									</FormLabel>
									<Switch
										controlId="forbid_unassigned_shifts"
										isOn={forbid_unassigned_shifts}
										handleToggle={this.handleChange}
									/>
								</FormGroup>

							</Tab.Pane>
							<Tab.Pane eventKey="rendimiento" className="plan-rendimiento">
								<div className="switch-input-row">
									<FormGroup
										className="form-group-flex form-row-switch form-group-main"
										controlId="timelimit"
									>
										<FormLabel>
											Tiempo máximo de ejecución (en segundos)
										</FormLabel>

									</FormGroup>

									<FormGroup className="form-group-input form-group-segundos" controlId="timelimit">
										<InputGroup>
											<FormControl
												type="text"
												onChange={this.handleChange}
												placeholder="0"
												isInvalid={this.checkValidField("timelimit")}
											/>

											<InputGroup.Append>
												<InputGroup.Text>segundos</InputGroup.Text>
											</InputGroup.Append>
										</InputGroup>
									</FormGroup>
								</div>
							</Tab.Pane>
						</Tab.Content>
					</Tab.Container>

					{this.state.error_footer && (
						<div
							className="alert alert-danger alert-dismissible fade show"
							role="alert"
						>
							{this.state.error_footer}
						</div>
					)}

					{/* {this.state.errors && this.state.errors.plan_id && (
						<div
							className="alert alert-danger alert-general-error fade show"
							role="alert"
						>
							{this.state.errors.plan_id}
						</div>
					)} */}
				</div>

				<div className="modal-footer">
					<button
						type="button"
						onClick={this.props.handleClose}
						className="btn btn-outline-primary"
					>
						Cancelar
					</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Buscar la mejor planificación"
						className="btn-wand"
						loadingText="Planificando..."
						iconAfter={<FaMagic className="icon-wand" />}
					/>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.groupsReducer,
		...reducers.calendarsReducer,
		...reducers.shiftsReducer,
		loading: reducers.groupsReducer.loading,
		user: reducers.authReducer.user,
	};
};


const mapDispatchToProps = (dispatch) => ({
	getAllGroups: () =>
		dispatch(
			groupsActions.getAllGroups({ sort: "+name", variant: "detailed" })
		),
	getSelectableDayTypes: () => dispatch(calendarsActions.getSelectableDayTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlanMagic));

