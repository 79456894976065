import React, { Component } from 'react';
import { Link, } from 'react-router-dom';
import Avatar from './';
import './Avatar.scss';

class Avatars extends Component {

	getAvatars = () => this.props.employees.map((employee, i) => {
		if (i > 2)
			return ""
		return (
			<li key={employee.id}>
				<Link to={`/employees/${employee.id}`}>
					<Avatar userId={employee.id} className="avatar-sm" alt={employee.name} />
				</Link>
			</li>
		)
	});

	render() {
		return (
			<ul className="avatar-collection">
				{this.getAvatars()}
			</ul>
		)
	}
}

export default Avatars;