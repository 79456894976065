import React from "react";
import { default as NextAbsences } from "components/Dashboard/Dashboard_NextAbsences"
import "components/Dashboard/Dashboard.scss";
import { WIDGET_NAMES } from "components/WidgetTemplates/utils";

const Card_NextAbsences = (props) => {
	// console.log(props);

	return (
		<article className="card card-upcoming-absences">
			<NextAbsences />
		</article>
	);
};

export default Card_NextAbsences;