import React, { Component } from "react";
import { connect } from "react-redux";
import { FiPlus, FiSearch, FiUsers, FiDownload, FiMoreHorizontal } from "react-icons/fi";
import { FormGroup, FormControl, Dropdown } from "react-bootstrap";
import Loading from "components/Loading";
import Layout from "components/Layout";
import StaffingLevelList from "components/StaffingLevels";
import StaffingLevelEditModal from "components/StaffingLevels/StaffingLevelEditModal";
import { showModal, hideModal } from "actions/modalActions";
import * as groupsActions from "actions/groupsActions";
import * as calendarsActions from "actions/calendarsActions";
import * as functionsActions from "actions/functionsActions";
import * as shiftsActions from "actions/shiftsActions";
import * as ubicationsActions from "actions/ubicationsActions";
import Modal from "components/Modal";

class StaffingLevels extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchStaffingLevel: "",
		};

		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openNewStaffingLevelModal = this.openNewStaffingLevelModal.bind(this);
	}

	componentDidMount() {
		this.getStaffingLevels();
		this.props.getSelectableDayTypes();
		this.props.getSelectableFunctions();
		this.props.getAttendanceShifts();
		this.props.getAllUbications();
	}

	handleChangeSearch = (event) => {
		this.setState(
			{
				[event.target.id]: event.target.value,
			},
			() => {
				this.getStaffingLevels();
			}
		);
	};

	async getStaffingLevels() {
		await this.props.getStaffingLevels(this.state.searchStaffingLevel);
	}

	closeModal() {
		this.props.hideModal();
	}

	openNewStaffingLevelModal() {
		this.props.showModal(
			{
				open: true,
				title: "Crear necesidades de personal",
				style: { width: "800px" },
				content: <StaffingLevelEditModal handleClose={this.closeModal} />,
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	getContent = () => {
		if (this.props.loading) {
			return <Loading />;
		}

		if (!this.props.staffing_levels) {
			return "Error cargando las necesidades de personal";
		}

		if (this.props.staffing_levels.length == 0) {
			return (
				<div className="first-steps">
					<FiUsers className="icono-pasos" />
					<h3 className="title2">Primeros pasos</h3>

					<p>
						Las <strong>necesidades de personal</strong> permiten establecer
						cuántos <strong>empleados</strong> necesitas con una determinada{" "}
						<a
							className="alink"
							target="_blank"
							href="https://help.plain.ninja/es/articles/3693857-que-es-una-funcion-y-para-que-sirve"
						>
							<strong>función</strong>
						</a>{" "}
						en cada{" "}
						<a
							className="alink"
							target="_blank"
							href="https://help.plain.ninja/es/articles/3693943-que-es-un-turno-y-para-que-sirve"
						>
							<strong>turno de Trabajo</strong>
						</a>
						. Aquí te explicamos cómo funcionan:{" "}
						<a
							className="alink"
							target="_blank"
							href="https://help.plain.ninja/es/articles/3723502-que-son-las-necesidades-de-personal"
						>
							https://help.plain.ninja/es/articles/3723502-que-son-las-necesidades-de-personal
						</a>
					</p>
				</div>
			);
		}

		return <StaffingLevelList staffing_levels={this.props.staffing_levels} />;
	};

	render() {
		return (
			<>
				<Layout className="page-staffing-levels" context="groups">
					<div className="heading">
						<h1 className="title">Necesidades de personal</h1>

						<div className="heading-filters">
							<FormGroup
								className="form-search"
								controlId="searchStaffingLevel"
							>
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={this.state.searchStaffingLevel}
									onChange={this.handleChangeSearch}
								/>
								<FiSearch />
							</FormGroup>
						</div>
						<div className="heading-actions">
							<button
								type="button"
								title="Crear necesidad operativa"
								onClick={this.openNewStaffingLevelModal}
								className="btn btn-new"
							>
								<FiPlus />
							</button>

							<Dropdown>
								<Dropdown.Toggle variant="action">
									<FiMoreHorizontal />
								</Dropdown.Toggle>
								<Dropdown.Menu alignRight>
									<Dropdown.Item
										as="button"
										onClick={() =>
											this.props.history.push("/staffing-levels/import")
										}
									>
										<FiDownload /> Importar necesidades de personal
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{this.getContent()}
				</Layout>

				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.groupsReducer,
		...reducers.calendarsReducer,
		...reducers.functionsReducer,
		...reducers.shiftsReducer,
		...reducers.ubicationsReducer,
		loading: reducers.groupsReducer.loading,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	getStaffingLevels: (q) => dispatch(groupsActions.getAllStaffingLevels(q)),
	getSelectableDayTypes: () =>
		dispatch(calendarsActions.getSelectableDayTypes()),
	getSelectableFunctions: () =>
		dispatch(functionsActions.getSelectableFunctions()),
	getAttendanceShifts: () => dispatch(shiftsActions.getAttendanceShifts()),
	getAllUbications: () => dispatch(ubicationsActions.getAllUbications({ enabled: true }))
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffingLevels);
