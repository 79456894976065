import React, { Component } from 'react';
import { connect } from 'react-redux';
import { functionsService } from "services";
import { FiPlus, FiMoreHorizontal, FiDownload } from "react-icons/fi";
import { Dropdown } from 'react-bootstrap';
import Layout from 'components/Layout';
import Loading from "components/Loading";
import FunctionEdit from "components/Functions/FunctionEditModal";
import Modal from 'components/Modal';
import { showModal, hideModal } from "actions/modalActions";
import * as functionsActions from 'actions/functionsActions';
import { errorFormating } from "utils/utils";

class Functions extends Component {
  constructor(props) {
    super(props);
    // console.log(props);

    this.closeModal = this.closeModal.bind(this);
    this.openNewFunctionModal = this.openNewFunctionModal.bind(this);
    this.openEditFunctionModal = this.openEditFunctionModal.bind(this);
    this.openAlertModal = this.openAlertModal.bind(this);
    this.getFunctions = this.getFunctions.bind(this);
  }

  componentDidMount() {
    this.getFunctions();
  }

  async getFunctions() {
    await this.props.getAllFunctions();
    //console.log(this.props);
  }

  closeModal() {
    this.props.hideModal()
  }

  openNewFunctionModal() {
    this.props.showModal({
      open: true,
      title: 'Nueva función',
      style: { width: '400px' },
      content: <FunctionEdit getFunctions={this.getFunctions} handleClose={this.closeModal} />,
      closeModal: this.closeModal
    }, 'edit')
  }

  openEditFunctionModal(func) {
    this.props.showModal({
      open: true,
      title: 'Editar función',
      style: { width: '400px' },
      content: <FunctionEdit function={func} getFunctions={this.getFunctions} handleClose={this.closeModal} />,
      closeModal: this.closeModal
    }, 'edit')
  }

  openDeleteFunctionModal(idFunction) {
    this.props.showModal({
      open: true,
      title: 'Eliminar función',
      style: { width: '400px' },
      message: '¿Desea eliminar la función de empleado? Esta acción no se puede deshacer',
      deleteAction: () => this.confirmDelete(idFunction),
      closeModal: this.closeModal
    }, 'delete')
  }

  openAlertModal(message) {
    this.props.showModal({
      open: true,
      title: "Error al eliminar",
      style: { width: '400px' },
      message: message,
      closeModal: this.closeModal
    }, 'alert')
  }

  confirmDelete = async (idFunction) => {
    const response = await functionsService.remove(idFunction);

    if (!response.ok) {
      let textError = errorFormating(response);
      this.openAlertModal(textError.id);
    }
    else {
      this.getFunctions();
      this.closeModal();
    }
  }

  getEmptyRow = () => {
    return (
      <tr>
        <td colSpan="3" className="no-result">
          Todavía no hay ninguna función creada.
        </td>
      </tr>
    );
  }

  getContent = () => {
    // console.log(this.state);
    if (this.props.loading) {
      return <Loading />;
    }

    if (this.props.error) {
      return "No se han podido cargar las funciones";
    }
    return (
      <table className="table table-functions table-zebra">
        <thead>
          <tr>
            <th>Función</th>
            <th>Estado</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.functions.length === 0 ?
            this.getEmptyRow()
            :
            this.getRows()
          }
        </tbody>
      </table>
    );
  }

  getRows = () => this.props.functions.map((func) => {

    return (
      <tr key={func.id}>
        <td>
          <button className="btn-transparent btn-editable" onClick={() => this.openEditFunctionModal(func)}>{func.name}</button>
        </td>
        <td>
          {func.enabled
            ? <span title='Activo' className='estado estado-si'>Activo</span>
            : <span title='Inactivo' className='estado estado-no'>Inactivo</span>
          }
        </td>
        <td className="td-actions">
          <Dropdown>
            <Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
            <Dropdown.Menu alignRight>
              <Dropdown.Item as="button" onClick={() => this.openEditFunctionModal(func)}>Editar</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => this.openDeleteFunctionModal(func.id)}>Eliminar</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    )
  });

  render() {

    return (

      <>
        <Layout className="page-functions" context="employees">
          <div className="heading">
            <h1 className="title">Funciones de empleado</h1>

            <div className="heading-actions">
              <button type="button" title="Crear función" onClick={this.openNewFunctionModal} className="btn btn-new"><FiPlus /></button>
              <Dropdown>
                <Dropdown.Toggle variant="action">
                  <FiMoreHorizontal />
                </Dropdown.Toggle>
                <Dropdown.Menu alignRight>
                  <Dropdown.Item
                    as="button"
                    onClick={() => this.props.history.push("/functions/import")}
                  >
                    <FiDownload className="dropdown-icon" /> Importar functiones
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          {this.getContent()}
        </Layout>

        <Modal hideModal={this.props.hideModal} />
      </>
    )
  }
}

const mapStateToProps = (reducers) => {
  return reducers.functionsReducer;
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getAllFunctions: (q) => dispatch(functionsActions.getAllFunctions(q))
})


export default connect(mapStateToProps, mapDispatchToProps)(Functions)