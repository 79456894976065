import React from 'react';
import { Link } from 'react-router-dom';
import { PLAN_GRID_VIEWS } from "components/Plans";

const getCounterHeader = (props) => props.account_assignments[0].counters.map((d, i) => {
	//console.log(d);
	return (
		<th className="th-counter" key={i}>
			{d.account && d.account.name}
			{d.transaction_rule && d.transaction_rule.name}
		</th>
	)
});

const getCounterRows = (props) => props.account_assignments.map((assignment, i) => {
	const counters = assignment.counters;
	const employee = assignment.employee;

	return (
		<tr key={i}>
			{getAccountRowContent(props, employee, counters, i)}
		</tr>
	)
});

const getAccountRowContent = (props, employee, counters, row) => counters.map((item, i) => {
	// var value = parseFloat(item.value).toFixed(2).toString().replace(".", ",").replace(",00", "");
	var value = parseFloat(item.value).toFixed(2);
	const { start, end } = props;
	const formattedValue = new Intl.NumberFormat("de-DE").format(value);

	if (item.account && value > 0) {
		return (
			<td key={i}>
				<Link to={`/transactions/${employee.id}/${item.account.id}/${start}@${end}`} target="_blank" className="counter-value" title="Ver transacción">{formattedValue} {item.account.type === "Euros" && "€"}</Link>
			</td>
		)
	}
	return (
		<td key={i}>
			{formattedValue}{item.account && item.account.type === "Euros" && "€"}
		</td>
	)
});


const PlanCounters = (props) => {
	// console.log(props);
	var tableClass = "";
	if (props.plan_grid_view === PLAN_GRID_VIEWS.combo)
		tableClass = "table-grid-combo";

	if (props.plan_grid_view === PLAN_GRID_VIEWS.ubications)
		tableClass = "table-ubications"

	if (props.account_assignments.length === 0) return "";

	return (
		<table className={`table table-bordered table-counter ${tableClass}`}>
			<thead>
				<tr>
					{getCounterHeader(props)}
				</tr>
			</thead>
			<tbody>
				{getCounterRows(props)}
			</tbody>
		</table>
	)

}

export default PlanCounters