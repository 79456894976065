import React from "react";
import { default as WeekShifts } from "components/Dashboard/Dashboard_WeekShifts"
import "components/Dashboard/Dashboard.scss";

const Card_WeekShifts = (props) => {
	// console.log(props);

	return (
		<article className="card card-week-plan">
			<WeekShifts />
		</article>
	);
};

export default Card_WeekShifts;