import React, { Component } from "react";
import moment from "moment";
import Moment from "react-moment";
import { FiCalendar } from "react-icons/fi";
import { calendarsService } from "services";
import "components/Dashboard/Dashboard.scss";

class Dashboard_NextHolidays extends Component {
	constructor(props) {
		super(props);
		// console.log(props);

		this.state = {
			upcommig_holidays: [],
			isHoliday: false,
		};
	}

	componentDidMount() {
		this.getUpcommingHolidays();
	}

	async getUpcommingHolidays() {
		const response = await calendarsService.getUpcomingHolidays();
		const upcommig_holidays = response.filter((day) => day.holidays);
		//console.log(upcommig_holidays);

		if (response.ok) {
			this.setState({
				upcommig_holidays,
			});
		}
	}

	getDayRows = () =>
		this.state.upcommig_holidays.map((holiday, index) => {
			const today = new Date();
			const isToday = moment(holiday.day).isSame(today, "day");

			return (
				<li key={index} className={`${isToday ? "active" : ""}  day`}>
					<Moment format="DD/MM/YY" className="day-date">
						{holiday.day}
					</Moment>
					<ul className="holidays">{this.getDayHolidays(holiday.holidays)}</ul>
					{isToday && <span className="hoy">HOY</span>}
				</li>
			);
		});

	getDayHolidays = (holidays) =>
		holidays.map((holiday, index) => {
			// console.log(holiday);
			return (
				<li key={index} className="holiday">
					<div className="item-main">
						<span className="item-name">{holiday.day.description}</span>
						<span className="item-info">{holiday.calendar.name}</span>
					</div>
				</li>
			);
		});

	render() {
		const { upcommig_holidays } = this.state;

		if (upcommig_holidays.length > 0) {
			return <ul className="list-days">{this.getDayRows()}</ul>;
		} else {
			return (
				<div className="empty">
					<FiCalendar />
					<p>No hay festivos en los próximos 30 días</p>
				</div>
			);
		}
	}
}

export default Dashboard_NextHolidays;
