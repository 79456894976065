import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { ProgressBar } from 'react-bootstrap';
import { FaMagic } from "react-icons/fa";
import imgPlanOK from "assets/plan-ok.png";
import { showModal, hideModal } from "actions/modalActions";
import * as shiftsActions from "actions/shiftsActions";
import Layout from "components/Layout";
import Loading from "components/Loading";
import SubmitButton from "components/SubmitButton";
import PlanMagicModal from 'components/Plans/PlanMagicModal';
import { groupsService, plansService } from "services";
import Modal from "components/Modal";
import "components/Plans/Plans.scss";

class PlanMagic extends Component {
	constructor(props) {
		super(props);
		// console.log(props);
		// console.log(props.location.state.detail);

		this.closeModal = this.closeModal.bind(this);
		this.openNewMagicPlan = this.openNewMagicPlan.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
		const group_id = parseInt(this.props.match.params.group);

		var magic_data, result_plans, total_pending_needs, total_required_needs;

		if (this.props.location && this.props.location.state) {
			magic_data = this.props.location.state.detail;
			result_plans = magic_data.result_plans;
			total_pending_needs = magic_data.total_pending_needs;
			total_required_needs = magic_data.total_required_needs;
		}

		console.log(result_plans);

		this.state = {
			loading: false,
			saving: false,
			group_id,
			result_plans: result_plans ? result_plans : [],
			total_pending_needs: total_pending_needs ? total_pending_needs : 0,
			total_required_needs: total_required_needs ? total_required_needs : 0,
			start: this.props.match.params.start,
			end: this.props.match.params.end,
		};
	}

	async componentDidMount() {
		this.getGroupData();

		// work shifts for magic modal
		this.props.getAttendanceShifts();
	}

	saveMagicPlan = async () => {
		const { result_plans, group_id, start, end } = this.state;
		const employee_ids = [];

		this.setState({ saving: true });

		result_plans.map((plan) => {
			if (!employee_ids.includes(plan.employee_id)) {
				employee_ids.push(plan.employee_id);
			}
		});

		const plans_to_save = {
			start,
			end,
			employee_ids,
			plans: result_plans
		}

		const response = await plansService.updateIntelligent(plans_to_save);
		// console.log(response);
		var hasErrors = false;
		var textError = "Se han encontrado los siguientes errores: \r\n";
		response.map((item) => {
			if (item.errors !== undefined) {
				hasErrors = true;
				item.errors.map((error) => {
					// console.log(error);
					textError += "- " + error.description + "\r\n";
				})
			}
		});

		// tornar a la planificació si OK
		// mostrar errors si KO
		this.setState({
			saving: false,
			// submitted: true,
		}, () => {
			if (!hasErrors) {
				this.props.history.push("/plans/" + group_id + "/" + start + "@" + end);
			} else {
				this.openAlertModal(
					textError,
					"Error al guardar"
				);
			}
		});
	}

	openAlertModal(message, title) {
		this.props.showModal(
			{
				open: true,
				title: title || "Ha habido un error",
				style: { width: "400px" },
				message: message,
				closeModal: this.closeModal,
			},
			"alert"
		);
	}

	async getGroupData() {
		const { group_id } = this.state;
		const group = await groupsService.getById(group_id);
		this.setState({
			group,
		});
	}



	closeModal() {
		this.props.hideModal();
	}

	openNewMagicPlan() {
		this.props.showModal({
			open: true,
			title: 'Planificación automática',
			style: { width: '860px' },
			content: <PlanMagicModal handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	getContent = () => {
		const { result_plans, total_required_needs, total_pending_needs } = this.state;
		if (result_plans.length === 0) {
			return <div className="no-results">No se han encontrado resultados</div>
		}

		const cobertura = (total_required_needs - total_pending_needs) / total_required_needs;
		const percentage = cobertura * 100;
		const roundedCobertura = parseFloat(percentage.toFixed(2));

		const getVariant = (cobertura) => {
			if (cobertura < 50) return 'danger';
			if (cobertura >= 50 && cobertura <= 70) return 'warning';
			return 'success';
		};

		return (
			<div className="plan-ok">
				<div className="plan-ok-container">
					<img className="img-plan" src={imgPlanOK} alt="Plan OK" />

					<div className="plan-ok-content">
						<h3>Planificación realizada correctamente</h3>

						<div className="cobertura">
							<p>Cobertura obtenida: <strong>{roundedCobertura} %</strong></p>
							<ProgressBar striped now={roundedCobertura} label={`${roundedCobertura}%`} variant={getVariant(roundedCobertura)} />
						</div>

						<p>Pulsa el botón para guardar los cambios.</p>
					</div>
				</div>
				<SubmitButton
					type="submit"
					isLoading={this.state.saving}
					text="Guardar planificación"
					onClick={this.saveMagicPlan}
					className="btn-guardar btn-guardar-footer"
					loadingText="Guardando..."
				/>
			</div>
		)
	};

	// ************* RENDER *********************

	render() {
		// const { user } = this.props;
		const {
			loading,
			start,
			end,
			group,
			result_plans,
		} = this.state;


		const start_month = moment(start).format("MMMM");
		const end_month = moment(end).format("MMMM");

		var start_format = moment(start).format("D");
		const end_format = moment(end).format("D") + " de " + end_month;

		const start_year = moment(start).format("YYYY");
		const end_year = moment(end).format("YYYY");

		if (start_month !== end_month)
			start_format = start_format + " de " + start_month;

		if (start_year !== end_year)
			start_format = start_format + " de " + start_year;

		return (
			<>
				<Layout className="page-plan page-magic-plan">
					<div className="heading">
						<div className="heading-left">
							<h1 className="title">Planificación automática {group && group.name}</h1>
						</div>
						<div className="heading-filters"></div>
						<div className="heading-actions">
							{result_plans.length > 0 &&
								<SubmitButton
									type="submit"
									isLoading={this.state.saving}
									onClick={this.saveMagicPlan}
									text="Guardar planificación"
									className="btn-guardar"
									loadingText="Guardando..."
								/>
							}
							<button
								type="button"
								title="Planificación automática"
								onClick={this.openNewMagicPlan}
								className="btn btn-outline-primary btn-wand"
							>
								Planificación automática

								<FaMagic className="icon-wand" />
							</button>
						</div>
					</div>
					<div className="heading">
						<div className="heading-left">
							<p className="plan-periodo">
								Periodo del {start_format} al {end_format} de {end_year}
							</p>
						</div>
					</div>
					{loading ?
						<div className="empty"><Loading /></div> :
						this.getContent()
					}
				</Layout>

				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		user: reducers.authReducer.user,
		loading: reducers.plansReducer.loading,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	getAttendanceShifts: () => dispatch(shiftsActions.getAttendanceShifts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanMagic);
