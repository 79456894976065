import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { IoMdColorFill } from "react-icons/io";
import { FiDelete, FiX } from "react-icons/fi";
import { groupsService } from "services";
import { errorFormating } from "utils/utils";
import { TwitterPicker } from 'react-color';
import SubmitButton from "components/SubmitButton";
import Switch from "components/Switch";
import "./Groups.scss";
import * as groupsActions from 'actions/groupsActions';

class GroupEdit extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		let group = {
			name: '',
			color: '',
			group_ids: [],
			is_multigroup: false,
		}

		let isEdit = false;
		let selected_groups = [];

		if (props.group) {
			group = props.group;
			if (props.group.calendar)
				group.calendar_id = props.group.calendar.id;


			selected_groups = group.groups;
			isEdit = true;
		}

		this.state = {
			isEdit,
			isPickerVisible: false,
			isLoading: false,
			submitted: false,
			errors: {},
			group,
			temp_group: {},
			selected_groups,
			new_item_error: false,
		};
	}

	handleChange = event => {
		const { group } = this.state;

		const target = event.target.id;
		let value = event.target.value;

		if (target === "is_multigroup") {
			value = event.target.checked;
		}

		if (target === "temp_group") {
			// add group to list
			this.setState({
				[event.target.id]: event.target.value
			});
		}
		else {
			// normal group change
			this.setState({
				group: {
					...group,
					[event.target.id]: value
				},
			});
		}
	}

	handleColorChange = ({ hex }) => {
		const { group } = this.state;

		if (hex === undefined)
			hex = '';

		this.setState({
			group: {
				...group,
				color: hex
			},
			isPickerVisible: false,
		});
	}

	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	handleSubmit = async event => {
		event.preventDefault();

		let response = '';
		const props = this.props;

		//console.log(this.state.group);
		const { selected_groups, group, new_item_error } = this.state;

		// validació si es multigrup i no hi ha grups
		if (group.is_multigroup && selected_groups.length == 0) {
			this.setState({
				submitted: true,
				new_item_error: true,
			});
		}
		else {
			this.setState({ isLoading: true });

			if (group.is_multigroup && selected_groups.length > 0)
				group.group_ids = selected_groups.map(group => group.id);
			else
				group.group_ids = [];


			// if edit => update; else => new
			if (this.state.isEdit) {
				response = await groupsService.update(group);
			}
			else {
				response = await groupsService.add(group);
			}

			//console.log(response);

			if (response.ok) {
				this.setState({
					isLoading: false,
					errors: {},
				}, () => {
					toastr.success('¡Bien!', 'Cambios guardados correctamente');
					this.props.getAllGroups({ variant: 'detailed' });
					this.props.getChildGroups();
					this.props.getGroups();
					props.handleClose();
				});
			}
			else {
				this.setState({
					isLoading: false,
					submitted: true,
					errors: errorFormating(response)
				});
			}

		}


	}

	// MULTIGROUPS
	addGroup = () => {
		let { selected_groups, temp_group, new_item_error } = this.state;
		let isValid = true;
		let group;

		if (temp_group === undefined) {
			isValid = false;
		}
		else {
			group = this.props.select_groups.find(item => item.id === parseInt(temp_group));
			if (group === undefined) {
				isValid = false;
				new_item_error = true;
			}
		}

		if (isValid) {
			// check if day already exists
			const exists = selected_groups.find(item => item.id === parseInt(temp_group));

			// if not : add new group
			if (!exists) {
				selected_groups = selected_groups.concat(group);
			}

			this.setState({
				selected_groups,
				new_item_error: false,
				temp_group: {}
			});
		}
		else {
			this.setState({
				new_item_error,
			});
		}
	}

	removeGroup = (idGroup) => {
		this.setState((prevState, props) => {
			return {
				selected_groups: prevState.selected_groups.filter(item => item.id !== parseInt(idGroup))
			};
		});
	}

	getSelectedGroups = () => this.state.selected_groups.map((item) => {
		return (
			<li key={item.id} className="tag-default tag-delete">
				{item.name}
				<button type="button" onClick={() => this.removeGroup(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});

	render() {
		const { group, errors, new_item_error, temp_group, isEdit } = this.state;
		const { select_calendars, select_groups } = this.props;

		const optionCalendarList = select_calendars.length > 0 && select_calendars.map((item, i) => {
			return (
				<option key={item.id} value={item.id}>{item.name}</option>
			)
		}, this);

		const optionGroupList = select_groups.length > 0 && select_groups.map((item, i) => {
			return (
				<option key={i} value={item.id}>{item.name}</option>
			)
		}, this);


		const onToggleColorPicker = () => this.setState({ isPickerVisible: !this.state.isPickerVisible })
		const isMultigroup = group.is_multigroup;

		return (

			<form onSubmit={this.handleSubmit}>
				<div className="modal-body">
					<FormGroup controlId="name">
						<FormLabel>Nombre <span className="label-required">*</span></FormLabel>
						<FormControl
							type="text"
							value={group.name}
							onChange={this.handleChange}
							placeholder="Nombre del grupo"
							isInvalid={this.checkValidField('name')}
						/>
						<FormControl.Feedback type="invalid">
							{errors.name}
						</FormControl.Feedback>
					</FormGroup>

					{select_groups.length > 0 &&
						<FormGroup className="form-group-flex form-group-main" controlId="is_multigroup">
							<FormLabel>Multigrupo</FormLabel>
							<Switch
								controlId="is_multigroup"
								isOn={isMultigroup}
								handleToggle={this.handleChange}
								disabled={isEdit}
							/>
							{isEdit && <span className='no-editable'>(No se puede editar)</span>}
						</FormGroup>
					}


					<FormGroup controlId="calendar_id">
						<FormLabel>Calendario <span className="label-required">*</span></FormLabel>
						<FormControl
							type="text"
							value={group.calendar_id}
							onChange={this.handleChange}
							isInvalid={this.checkValidField('calendar_id')}
							as="select">
							<option value="">Selecciona calendario</option>
							{optionCalendarList}
						</FormControl>

						<FormControl.Feedback type="invalid">
							{errors.calendar_id}
						</FormControl.Feedback>
					</FormGroup>

					<FormGroup controlId="color" className="form-color">
						<div className="flex-between">
							<FormLabel>Color</FormLabel>
							<FiDelete className={`icon-remove-color ${!group.color && 'hidden'} `} onClick={() => this.handleColorChange("")} />
						</div>
						<FormControl
							type="text"
							value={group.color}
							placeholder="Color"
							readOnly
							isInvalid={this.checkValidField('color')}
							style={{ backgroundColor: group.color && group.color }}
							onClick={onToggleColorPicker}
						/>
						<FormControl.Feedback type="invalid">
							{this.state.errors.color}
						</FormControl.Feedback>

						<IoMdColorFill onClick={onToggleColorPicker} />

						{this.state.isPickerVisible && (
							<div style={{ position: 'absolute' }}>
								<TwitterPicker
									color={group.color ? group.color : '#ffffff'}
									onChangeComplete={(hex) => this.handleColorChange(hex)}
								/>
							</div>
						)}
					</FormGroup>
					{isMultigroup &&

						(<div>
							<div className="flex-group mb15">
								<FormGroup controlId="temp_group">
									<FormLabel>Grupos</FormLabel>
									<FormControl
										type="text"
										onChange={this.handleChange}
										value={temp_group}
										placeholder="Selecciona grupo"
										isInvalid={new_item_error}
										as="select"
									>
										<option value="">Selecciona grupo</option>
										{optionGroupList}
									</FormControl>
									<FormControl.Feedback type="invalid">
										Debes agregar algún grupo
									</FormControl.Feedback>
								</FormGroup>
								<button type="button" onClick={this.addGroup} className="btn btn-primary">Añadir</button>
							</div>
							<ul className="list-tags">
								{this.getSelectedGroups()}
							</ul>
						</div>)

					}
				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		)
	}
}


const mapStateToProps = (reducers) => {
	return {
		...reducers.calendarsReducer,
		...reducers.groupsReducer,
	}
};

export default connect(mapStateToProps, groupsActions)(GroupEdit)
