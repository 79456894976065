import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { FiPlus, FiMoreHorizontal } from "react-icons/fi";
import { showModal, hideModal } from "actions/modalActions";
import * as employeesActions from 'actions/employeesActions';
import EmployeeDocumentsAdd from "./EmployeeDocumentsModal";
import { employeesService } from "services";
import { errorFormating } from "utils/utils";

import "./Employees.scss";

class EmployeeDocuments extends React.Component {
	constructor(props) {
		super(props);
		//console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openNewDocumentModal = this.openNewDocumentModal.bind(this);
   		this.openAlertModal = this.openAlertModal.bind(this);	
		   
	}

	componentDidMount(){
		this.getDocuments();
	}

   async getDocuments () {
   		const employeeId = this.props.employee.id;
  		await this.props.getEmployeeDocuments(employeeId);
  	}

	closeModal() {
		this.props.hideModal()
	}

	openNewDocumentModal() {
		const {documents, employee} = this.props;

		this.props.showModal({
			open: true,
			title: 'Añadir documentos',
			style: {width: '450px'},
			content: <EmployeeDocumentsAdd handleClose={this.closeModal} documents={documents} employee={employee} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openDeleteDocumentModal(idDocument) {
		this.props.showModal({
			open: true,
			title: 'Eliminar asignación',
			style: {width: '350px'},
			message:'¿Desea eliminar el documento? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmDelete(idDocument),
			closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
	 this.props.showModal({
	   open: true,
	   title: "Error al eliminar",
	   style: {width: '400px'},
	   message: message,
	   closeModal: this.closeModal
	 }, 'alert')
	}	

	confirmDelete= async(idDocument) =>  {
		const response = await employeesService.removeAttachment(this.props.employee.id, idDocument);	

		if (!response.ok){
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else{
			this.getDocuments();
			this.closeModal();
		}
	}


	getAttachment = async (attachment) => {
		const { employee } = this.props;
		// console.log(attachment);
		const response = await employeesService.getAttachment(employee.id, attachment.url);
		// console.log(response);	
		var url = window.URL.createObjectURL(response);
		var a = document.createElement("a");
		document.body.appendChild(a);
		a.href = url;
		a.download = attachment.name;
		a.click();
	}	

	// render functions:
	getEmptyRow = () => {
		return(
			<tr>
				<td colSpan="3" className="no-result">
					Todavía no hay ningún documento subido.
				</td>
			</tr>
		);
	}  

	getRows = () =>  this.props.employee_documents.map((employee_document)  => {
		var size = employee_document.size/1000000;
		var sizeLetters = "Mb";

		if (size < 1){
			size = employee_document.size/1000;
			sizeLetters = "Kb";
		}

		return (
			<tr key={ employee_document.url }>
				<td> 
					<button className="btn-transparent" onClick={()=> this.getAttachment(employee_document)}>{ employee_document.name }</button>
				</td>
				<td>
					{size.toFixed(2)}{sizeLetters}
				</td>
				<td className="td-actions">
					<Dropdown>
					<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
					<Dropdown.Menu alignRight>
						<Dropdown.Item as="button" onClick={()=> this.openDeleteDocumentModal(employee_document.url)}>Eliminar</Dropdown.Item>
					</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		)
	});	

	render() {
		const { employee_documents } = this.props;

		return(
			<table className="table table-groups table-zebra-reverse">
				<thead>
					<tr>
						<th>Documento</th>
						<th>Tamaño</th>
						<th className="td-actions"><button type="button" title="Añadir documentos" onClick={this.openNewDocumentModal} className="btn btn-new"><FiPlus /></button></th>
					</tr>
				</thead>
				<tbody>
					{ employee_documents.length === 0 ?
						this.getEmptyRow() 
					:
						this.getRows() 
					}
				</tbody>
			</table>
		)
	}
}


const mapStateToProps = state => {
  return{
  	employee_documents: state.employeesReducer.employee_documents,
    user:state.authReducer.user,
  }
}

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getEmployeeDocuments: (id) => dispatch(employeesActions.getEmployeeDocuments(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDocuments);