import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { FiCalendar } from "react-icons/fi";
import { employeesService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import "./Employees.scss";
import * as employeesActions from 'actions/employeesActions';

import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // datepicker locale
registerLocale("es", es);

class EmployeeContractEdit extends React.Component {
  constructor(props) {
    super(props);

    // console.log(props);

    let employee_contract = {
      contract_id: 0,
      start_day: '',
      end_day: '',
    }

    let isEdit = false;

    if (props.employee_contract) {
      employee_contract = {
        ...props.employee_contract,
        contract_id: props.employee_contract.contract.id
      };
      isEdit = true;
    }

    this.state = {
      isEdit,
      isLoading: false,
      submitted: false,
      errors: {},
      employee_contract
    };
  }

  handleChange = event => {
    const { employee_contract } = this.state;

    this.setState({
      employee_contract: {
        ...employee_contract,
        [event.target.id]: event.target.value
      },
    });
  }

  handleDateChange = (id, date) => {
    const { employee_contract } = this.state;

    let key = id;
    let value = (date !== null) ? moment(date).format("YYYY-MM-DD") : '';

    // console.log(employee_contract);
    // console.log(key);
    // console.log(value);

    this.setState({
      employee_contract: {
        ...employee_contract,
        [key]: value,
      },
    });
  };

  checkValidField = (name) => {
    return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    let response = '';

    const props = this.props;
    const { employee_contract } = this.state;

    // if edit => update; else => new    
    if (this.state.isEdit) {
      response = await employeesService.updateContract(props.employee.id, employee_contract);
    }
    else {
      response = await employeesService.addContract(props.employee.id, employee_contract);
    }

    if (response.ok) {
      this.setState({
        isLoading: false,
        errors: {},
      }, () => {

        toastr.success('¡Bien!', 'Cambios guardados correctamente');
        this.props.getEmployeeContracts(props.employee.id);
        props.handleClose();

      });
    }
    else {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(response)
      });
    }
  }

  render() {
    const { contracts } = this.props;
    const { employee_contract } = this.state;

    const optionList = contracts.length > 0 && contracts.map((item, i) => {
      return (
        <option key={i} value={item.id}>{item.name}</option>
      )
    }, this);

    return (

      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          <FormGroup controlId="contract_id">
            <FormLabel>Contrato</FormLabel>
            <FormControl
              onChange={this.handleChange}
              value={employee_contract.contract_id}
              isInvalid={this.checkValidField('contract_id')}
              as="select">
              <option value="-1">Seleccionar contrato</option>
              {optionList}
            </FormControl>

            <FormControl.Feedback type="invalid">
              {this.state.errors.contract_id}
            </FormControl.Feedback>
          </FormGroup>

          <FormGroup controlId="start_day" className={`form-date ${employee_contract.start_day === '' && 'empty'}   ${this.checkValidField('start_day') && "is-invalid"}`}>
            <FormLabel>Fecha inicio</FormLabel>

            <DatePicker
              selected={employee_contract.start_day === "" ? employee_contract.start_day : moment(employee_contract.start_day).toDate()}
              onChange={(date) => this.handleDateChange("start_day", date)}
              isInvalid={this.checkValidField("start_day")}
              className="form-control"
              dateFormat="dd/MM/yyyy"
              locale='es'
              placeholderText="dd/mm/aaaa"
            />

            <FiCalendar />
            <FormControl.Feedback type="invalid">
              {this.state.errors.start_day}
            </FormControl.Feedback>
          </FormGroup>

          <FormGroup controlId="end_day" className={`form-date ${this.checkValidField('end_day') && "is-invalid"}`}>
            <FormLabel>Fecha fin (opcional)</FormLabel>

            <DatePicker
              selected={!employee_contract.end_day || employee_contract.end_day === "" ? "" : moment(employee_contract.end_day).toDate()}
              onChange={(date) => this.handleDateChange("end_day", date)}
              isInvalid={this.checkValidField("end_day")}
              className="form-control"
              dateFormat="dd/MM/yyyy"
              locale='es'
              placeholderText="dd/mm/aaaa"
            />

            <FiCalendar />
            <FormControl.Feedback type="invalid">
              {this.state.errors.end_day}
            </FormControl.Feedback>
          </FormGroup>

        </div>

        <div className="modal-footer">
          <button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
          <SubmitButton
            type="submit"
            isLoading={this.state.isLoading}
            text="Guardar"
            loadingText="Guardando..."
          />
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state, myProps) => {
  return {
    ...state.employeesReducer,
    contracts: state.rulesReducer.contracts,
    employee: myProps.employee,
  }
};

export default connect(mapStateToProps, employeesActions)(EmployeeContractEdit);